import {
  NostroModel,
  NostroSummaryModel,
  NostroRequestModel,
  Pageable,
  TransactionSummaryModel,
  TransactionModel,
  TransactionRequestModel,
  TransactionCreateRequestModel,
  NostroCreateRequestModel,
  UpdateTransactionRemarkDto,
} from 'src/@types'
import axios from 'src/api/axios'

const baseURL = '/nostroes'

const nostros = {
  getNostros: (params?: NostroRequestModel) =>
    axios.get<Pageable<NostroModel>>(`${baseURL}`, { params }),
  getNostro: (id: string) => axios.get<NostroModel>(`${baseURL}/${id}`),
  getNostroSummary: () => axios.get<NostroSummaryModel>(`${baseURL}/summary`),
  createNostro: (params?: Partial<NostroCreateRequestModel>) =>
    axios.post<NostroModel>(`${baseURL}`, params),
  getTransactions: (params?: TransactionRequestModel) =>
    axios.get<Pageable<TransactionModel>>(`${baseURL}/transactions`, {
      params,
    }),

  getTransaction: (id: string) =>
    axios.get<TransactionModel>(`${baseURL}/transactions/${id}`),
  updateTransactionRemark: (request: UpdateTransactionRemarkDto) =>
    axios.put<TransactionModel>(`${baseURL}/transactions/remarks`, request),
  getTransactionSummary: (id: string) =>
    axios.get<TransactionSummaryModel>(`${baseURL}/summary/${id}`),
  createTransaction: (payload?: Partial<TransactionCreateRequestModel>) =>
    axios.post<TransactionModel>(`${baseURL}/transactions`, payload),
  getNostroSelectList: () =>
    axios.get<Array<NostroModel>>(`${baseURL}/:getAllNostroes`),
}

export default nostros
