import { IconButton } from '@transwap/ui/Core/Input'
import { Search } from '@transwap/ui/Core/Icons'
import { useMemo, useState } from 'react'

import useQueryDataListener from 'src/hooks/useQueryDataListener'
import { ProfileModel } from 'src/@types'
import { PROFILE } from 'src/constants/queryKeys'
import { GLOBAL_SEARCH } from 'src/constants/features'
import SearchDialog from './componets/SearchDialog'

const GlobalSearch = () => {
  const profiles = useQueryDataListener<ProfileModel>(PROFILE, false)

  const isEnableFeature = useMemo(() => {
    const globalSearchFeature = profiles?.featureToggle?.find(f => {
      return f.id === GLOBAL_SEARCH
    })
    return globalSearchFeature?.enabled
  }, [profiles?.featureToggle])

  const [open, setOpen] = useState(false)

  const handleOpenSearchDialog = () => {
    setOpen(true)
  }

  const handleCloseSearchDialog = () => {
    setOpen(false)
  }
  return isEnableFeature ? (
    <>
      <IconButton
        shape="circle"
        onClick={handleOpenSearchDialog}
        iconColor="black"
      >
        <Search />
      </IconButton>
      <SearchDialog open={open} onClose={handleCloseSearchDialog} />
    </>
  ) : null
}

export default GlobalSearch
