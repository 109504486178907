import { createContext, Dispatch, ReactNode, useState } from 'react'
import { BreadcrumbsMap } from 'src/@types/common'
import defaultBreadcrumbsMap from 'src/constants/breadcrumbs'

interface BreadcrumbsContextValue {
  breadcrumbsMap: BreadcrumbsMap
  setBreadcrumbsMap: Dispatch<React.SetStateAction<BreadcrumbsMap>>
}

export const BreadcrumbsContext = createContext<BreadcrumbsContextValue>({
  breadcrumbsMap: {},
  setBreadcrumbsMap: () => {},
})

interface BreadcrumbsProviderProps {
  children: ReactNode
}

export const BreadcrumbsProvider = ({ children }: BreadcrumbsProviderProps) => {
  const [breadcrumbsMap, setBreadcrumbsMap] = useState<BreadcrumbsMap>(
    defaultBreadcrumbsMap
  )
  return (
    <BreadcrumbsContext.Provider value={{ breadcrumbsMap, setBreadcrumbsMap }}>
      {children}
    </BreadcrumbsContext.Provider>
  )
}

export default BreadcrumbsProvider
