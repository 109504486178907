import axios from 'src/api/axios'
import { GetTransactionTrackingModel } from 'src/@types'

const baseURL = '/transactionTracking'

const transactionTracking = {
  export: (params: GetTransactionTrackingModel) =>
    axios.get<Promise<Blob>>(`${baseURL}/export`, {
      params,
      responseType: 'blob',
    }),
}

export default transactionTracking
