import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import TranswapThemeProvider from '@transwap/ui/Core/Theme/TranswapThemeProvider'
import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import { AuthProvider } from 'src/auth'
import history from 'src/utils/router/history'
import App from './App'
import * as serviceWorker from './serviceWorker'
import 'src/utils/customPrototypes'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <TranswapThemeProvider>
          <Router history={history}>
            <App />
          </Router>
          <ReactQueryDevtools initialIsOpen={false} />
        </TranswapThemeProvider>
      </AuthProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

serviceWorker.unregister()
