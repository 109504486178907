import React from 'react'
import { Caption } from '@transwap/ui/Core/DataDisplay'
import { makeStyles, Theme, StyledComponentProps } from '@material-ui/core'

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    fontSize: 11,
    lineHeight: '12px',
    textTransform: 'uppercase',
    color: theme.palette.common.white,
    marginLeft: 'auto',
  },
}))

export type AppNameClassKey = 'root'
export interface AppNameProps extends StyledComponentProps<AppNameClassKey> {
  children: React.ReactNode
}

const AppName = (props: AppNameProps) => {
  const { children } = props
  const classes = useStyles(props)
  return <Caption className={classes.root}>{children}</Caption>
}

export default AppName
