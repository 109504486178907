import {
  CollectionDetailModel,
  CollectionModel,
  CollectionRequestModel,
  CollectionSummary,
  HistoryModel,
  NoteCreateModel,
  NoteModel,
  NotesRequestModel,
  Pageable,
} from 'src/@types'
import axios from 'src/api/axios'

const baseUrl = '/globalAccounts/collections'

const globalAccountCollections = {
  getCollections: (params: CollectionRequestModel) =>
    axios.get<Pageable<CollectionModel>>(baseUrl, { params }),
  getCollectionDetails: (id: string) =>
    axios.get<CollectionDetailModel>(`${baseUrl}/${id}`),
  getCollectionSummary: () =>
    axios.get<CollectionSummary>(`${baseUrl}/:summary`),
  getCollectionHistory: (id: string) =>
    axios.get<Array<HistoryModel>>(`${baseUrl}/${id}:activities`),
  createCollectionNote: (payload: NoteCreateModel) =>
    axios.post(`${baseUrl}/attachment`, payload),
  getCollectionNotes: (params: NotesRequestModel) =>
    axios.get<Pageable<NoteModel>>(`${baseUrl}/attachment`, { params }),
}

export default globalAccountCollections
