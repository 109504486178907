/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from 'react'
import { useQueries } from 'react-query'
import { referenceEndpoints, walletsEndpoints } from 'src/api/endpoints'
import { useAuthContext } from 'src/auth'
import {
  REFERENCE_BANKS,
  REFERENCE_CLIENT_BANKS,
  REFERENCE_COUNTRIES,
  REFERENCE_COUNTRIES_LICENSES,
  REFERENCE_CURRENCIES,
  REFERENCE_CURRENCIES_PARTNERS,
  REFERENCE_LANGUAGES,
  REFERENCE_PARTNERS,
  VA_CURRENCIES,
} from 'src/constants/queryKeys'
import { useLoading } from 'src/contexts/LoadingContext'
import useProfile from 'src/hooks/useProfile'

interface MasterDataProps {
  children: React.ReactNode
}

const defaultConfig = {
  staleTime: Infinity,
  cacheTime: Infinity,
  enabled: false,
}
const MasterData = ({ children }: MasterDataProps) => {
  const { hasAuthenticated } = useAuthContext()
  const { data: profile } = useProfile()
  const { showLoading } = useLoading()

  const queries = useQueries([
    {
      queryKey: VA_CURRENCIES,
      queryFn: walletsEndpoints.getVaCurrencies,
      ...defaultConfig,
    },
    {
      queryFn: referenceEndpoints.getCurrenciesPartners,
      queryKey: REFERENCE_CURRENCIES_PARTNERS,
      ...defaultConfig,
    },
    {
      queryFn: referenceEndpoints.getBanks,
      queryKey: REFERENCE_BANKS,
      ...defaultConfig,
    },
    {
      queryFn: referenceEndpoints.getPartners,
      queryKey: REFERENCE_PARTNERS,
      ...defaultConfig,
    },
    {
      queryFn: referenceEndpoints.getClientBanks,
      queryKey: REFERENCE_CLIENT_BANKS,
      ...defaultConfig,
    },
    {
      queryFn: referenceEndpoints.getCountriesLicenses,
      queryKey: REFERENCE_COUNTRIES_LICENSES,
      ...defaultConfig,
    },
    {
      queryFn: referenceEndpoints.getCountries,
      queryKey: REFERENCE_COUNTRIES,
      ...defaultConfig,
    },
    {
      queryFn: referenceEndpoints.getCurrencies,
      queryKey: REFERENCE_CURRENCIES,
      ...defaultConfig,
    },
    {
      queryFn: referenceEndpoints.getLanguages,
      ...defaultConfig,
      queryKey: REFERENCE_LANGUAGES,
    },
  ])

  const { isFetching, isFetched } = useMemo(
    () => ({
      isFetching: queries.some(query => query.isLoading),
      isFetched: queries.every(query => query.isFetched),
    }),
    [queries]
  )

  useEffect(() => {
    if (hasAuthenticated && !!profile && !isFetched) {
      queries.forEach(query => {
        query.refetch()
      })
    }
  }, [hasAuthenticated, profile, isFetched])

  useEffect(() => {
    showLoading(isFetching)
  }, [isFetching])

  return isFetching ? null : <>{children}</>
}

export default MasterData
