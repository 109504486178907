import ProgressIndicator from '@transwap/ui/Core/DataDisplay/Progress/ProgressIndicator'
import NoData from '@transwap/ui/Core/DataDisplay/Table/NoData'
import { GlobalSearchItemModel } from 'src/@types/models/globalSearch'
import { makeStyles } from '@material-ui/core/styles'
import ItemGlobalSearch from './ItemGlobalSearch'

interface GlobalSearchContentProps {
  isLoading: boolean
  items: Array<GlobalSearchItemModel>
  searchText: string
  handleCloseDialog: () => void
}

const useStyles = makeStyles(() => ({
  loading: {
    height: 236,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}))

const GlobalSearchContent = ({
  isLoading,
  items,
  searchText,
  handleCloseDialog,
}: GlobalSearchContentProps) => {
  const classes = useStyles()
  if (isLoading) {
    return (
      <div className={classes.loading}>
        <ProgressIndicator />
      </div>
    )
  }
  if (items.length > 0) {
    return (
      <div>
        {items.map(item => (
          <ItemGlobalSearch
            key={item.id}
            data={item}
            searchText={searchText}
            handleCloseDialog={handleCloseDialog}
          />
        ))}
      </div>
    )
  }

  return <NoData />
}

export default GlobalSearchContent
