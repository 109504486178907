import { SvgProps } from '@transwap/ui/Core/Icons/Svg'

const MeyzerM = (props: Omit<SvgProps, 'children'>) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg {...props} viewBox="0 0 43 41" fill="none">
      <path d="M5.86898 0H0V40.5736H5.86898V0Z" fill="#FEBE02" />
      <path d="M11.738 0H5.86897V40.5736H11.738V0Z" fill="#A21522" />
      <path
        d="M34.4127 0L29.8499 0.034917L22.7793 27.2353L16.2137 0.034917L11.7379 0V11.8543L18.6867 40.5736H26.3146L34.4127 8.86892V40.5736H42.4586V0H34.4127Z"
        fill="#110F0E"
      />
    </svg>
  )
}

export default MeyzerM
