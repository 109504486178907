export const REFERENCE_CURRENCIES_PARTNERS = 'CurrenciesPartners'
export const REFERENCE_BANKS = 'Banks'
export const REFERENCE_PARTNERS = 'Partners'
export const REFERENCE_PARTNERS_V2 = 'PartnersV2'
export const REFERENCE_CURRENCIES = 'Currencies'
export const REFERENCE_COUNTRIES = 'Countries'
export const REFERENCE_COUNTRY_DETAILS = 'CountryDetails'
export const REFERENCE_COUNTRIES_LICENSES = 'CountriesLicenses'
export const REFERENCE_LANGUAGES = 'Languages'
export const REFERENCE_CLIENT_BANKS = 'ClientBanks'
export const REFERENCE_RESIDENTIAL_STATUS = 'ResidentialStatus'
export const REFERENCE_INDUSTRIES = 'Industries'
export const REFERENCE_OCCUPATIONS = 'Occupations'
export const REFERENCE_SOURCE_OF_WEALTH = 'SourceOfWealth'
export const REFERENCE_LEGAL_OF_ENTITY = 'LegalEntity'
export const REFERENCE_AMOUNT_CONFIG = 'AmountConfig'
export const REFERENCE_TRANSFER_PURPOSE = 'TransferPurpose'
export const REFERENCE_FUND_SOURCE = 'FundSource'
export const REFERENCE_IBAN_NUMBER = 'IbanNumber'
export const REFERENCE_RECIPIENT_RELATIONSHIPS = 'RecipientRelationships'
export const REFERENCE_REJECTION_ERROR_CODES = 'RejectionErrorCodes'
export const REFERENCE_COMPANY_LEGAL_TYPES = 'CompanyLegalTypes'
export const REFERENCE_FEE_SCHEDULES = 'FeeSchedules'
export const REFERENCE_EXPORT_FEE_SCHEDULES = 'ExportFeeSchedules'
export const REFERENCE_MANAGERS = 'Managers'
export const REFERENCE_PAIR_CURRENCIES = 'PairCurrencies'
export const REFERENCE_ONBOARDING_CURRENCIES = 'OnboardingCurrencies'
export const REFERENCE_ONBOARDING_COUNTRIES = 'OnboardingCountries'
export const REFERENCE_GET_ACCOUNT_USAGE = 'GetAccountUsage'

export const PROFILE = 'Profiles'

// Customer groups
export const REFERENCE_CUSTOMER_GROUPS = 'CustomerGroups'
export const REFERENCE_CUSTOMER_GROUP_DETAILS = 'CustomerGroupDetails'

// Withdrawals
export const WITHDRAWALS = 'Withdrawals'
export const WITHDRAWAL_SUMMARY = 'WithdrawalSummary'
export const WITHDRAWAL_DETAILS = 'WithdrawalDetails'
export const WITHDRAWAL_COMPLIANCE_DETAILS = 'WithdrawalComplianceDetail'

export const WITHDRAWAL_HISTORY = 'WithdrawalHistory'
export const WITHDRAWAL_VALUES = 'WithdrawalValues'

// Deposits
export const DEPOSITS = 'Deposits'
export const DEPOSIT_SUMMARY = 'DepositSummary'
export const DEPOSIT_DETAIL = 'DepositDetail'
export const DEPOSIT_HISTORY = 'DepositHistory'
export const DEPOSIT_VALUES = 'DepositValues'
export const DEPOSIT_COMPLIANCE_DETAIL = 'DepositComplianceDetail'
export const DEPOSIT_FEE_CHARGE_TRANSACTIONS = 'DepositFeeChargeTransactions'

// Clients
export const CLIENTS = 'Clients'
export const CLIENT_DETAILS = 'ClientDetails'
export const CLIENT_MYINFO_DETAILS = 'ClientMyInfoDetails'
export const CLIENTS_SUMMARY = 'ClientsSummary'
export const CLIENTS_HISTORIES = 'ClientHistories'
export const CLIENTS_GENERATE_BORAD_RESOLUTION = 'ClientGenerateBoardResolution'

export const ACCOUNTS = 'Accounts'
export const ACCOUNT_DETAILS = 'AccountDetail'
export const ACCOUNT_SELECTLIST = 'AccountSelectList'
export const WALLET_ACCOUNT = 'WalletAccount'
export const AGENCY_ACCOUNT = 'Agency_Account'

export const CLIENT_WALLETS = 'ClientWallets'
export const CLIENT_TRANSACTIONS = 'ClientTransactions'
export const CLIENT_TRANSACTION_DETAILS = 'ClientTransactionDetails'
export const CLIENT_RECIPIENTS = 'ClientRecipients'
export const CLIENT_RECIPIENT_DETAILS = 'ClientRecipientDetails'
export const CLIENT_ACCOUNT_USERS = 'ClientAccountUsers'
export const CLIENT_DOCUMENTS = 'ClientDocuments'
export const CLIENT_NOTES = 'ClientNotes'
export const CLIENT_GLOBAL_ACCOUNTS = 'ClientGlobalAccounts'
export const CLIENT_BUSINESS_ACCOUNT_USAGE = 'ClientBusinessAccountUsage'

// Marketing
export const COUPON_SUMMARY = 'CouponSummary'
export const COUPONS = 'Coupons'
export const COUPON_DETAILS = 'CouponDetails'
export const REFERRALS_SUMMARY = 'ReferralsSummary'
export const REFERRALS = 'Referrals'

// Nostros
export const NOSTRO_LIST = 'Nostro'
export const NOSTRO_SUMMARY = 'NostroSummary'
export const NOSTRO_DETAILS = 'NostroDetails'
export const NOSTRO_TRANSACTION_LIST = 'NostroTransactions'
export const NOSTRO_TRANSACTION_DETAIL = 'NostroTransactionDetail'
export const NOSTRO_TRANSACTION_SUMMARY = 'NostroTransactionSummary'
export const NOSTRO_SELECT_LIST = 'NostroSelectList'

// House account transfers
export const HOUSE_TRANSFER_TRANSACTIONS = 'HouseTransferTransactions'
export const HOUSE_TRANSFER_BANK_ACCOUNTS = 'HouseTransferBankAccounts'
export const HOUSE_TRANSFER_BALANCE_ACCOUNTS = 'HouseTransferAccountBalances'
export const HOUSE_TRANSFER_CREATED_BY_LIST = 'HouseTransferCreatedByList'

// Bankfeed
export const BANKFEEDS = 'BankFeeds'
export const BANK_FEED_DETAILS = 'BankFeedDetails'
export const BANKFEED_HISTORY = 'BankFeedHistory'
export const BANKFEEDS_SUMMARY = 'BankFeedsSummary'

// Partnerfeed
export const PARTNERFEEDS = 'PartnerFeeds'
export const PARTNERFEEDS_SUMMARY = 'PartnerFeedsSummary'
export const PARTNER_FEED_DETAILS = 'PartnerFeedDetails'

// Creditfeed
export const CREDITFEEDS = 'CreditFeeds'
export const CREDITFEEDS_SUMMARY = 'CreditFeedsSummary'
export const CREDIT_FEED_DETAILS = 'CreditFeedDetails'

// Transfers
export const TRANSFERS = 'Transfers'
export const TRANSFER_DETAILS = 'TransferDetails'
export const TRANSFER_HISTORY = 'TransferHistory'
export const TRANSFER_SUMMARY = 'TransferSummary'
export const TRANSFER_COMPLIANCE_HISTORY = 'TransferComplianceHistory'
export const TRANSFER_COMPLIANCE_DETAIL = 'TransferComplianceDetail'
export const TRANSFER_BULK_COMPLIANCE_DETAIL = 'TransferBulkComplianceDetail'
export const TRANSFER_BULKS = 'TransferBulks'
export const TRANSFER_BULK_DETAILS = 'TransferBulksDetails'
export const TRANSFER_VALIDATE_COMPLIANCE = 'TransferValidateComplaince'
export const TRANSFER_BULK_UPLOAD_DETAIL = 'TransferBulkUploadDetail'
export const TRANSFER_DOWNLOAD_RECEIPT = 'TransferDownloadReceipt'

// Conversions
export const CONVERSION_LIST = 'Conversions'
export const CONVERSION_DETAILS = 'ConversionDetails'
export const CONVERSION_HISTORY = 'ConversionHistory'

// Refunds
export const REFUNDS = 'Refunds'
export const REFUND_SUMMARY = 'RefundSummary'
export const REFUND_DETAILS = 'RefundDetails'
export const RELATED_TRANSACTION = 'RelatedTransaction'
export const CUMULATIVE_TRANSACTION = 'CumulativeTransaction'

// Debit Credit Notes
export const DEBIT_CREDIT_NOTES = 'DebitCreditNotes'

// Rules & Policies
export const RULES_POLICIES_COMPLIANCE = 'RulesPoliciesCompliance'
export const RULES_POLICIES_COMPLIANCE_DETAILS =
  'RulesPoliciesComplianceDetails'

export const RULES_POLICIES_AUTO_PAYOUT_NOSTROS =
  'RulesPoliciesAutoPayoutNostros'

export const RULES_POLICIES_AUTO_PAYOUT_NOSTRO_DETAILS =
  'RulesPoliciesAutoPayoutNostroDetails'

export const RULES_POLICIES_AUTO_PAYOUT_NOSTRO_HISTORY =
  'RulesPoliciesAutoPayoutNostroHistory'

export const RULES_POLICIES_FEE_CHARGES = 'RulesPoliciesFeeCharges'
export const RULES_POLICIES_FEE_CHARGE_DETAIL = 'RulesPoliciesFeeChargeDetail'

// Common
export const HISTORY = 'History'
export const NOTES = 'Notes'

// Settlements
export const SETTLEMENTS = 'Settlements'
export const SETTLEMENT_TRANSACTIONS = 'SettlementTransactions'
export const SETTLEMENT_TRANSACTION_OVERVIEW = 'SettlementTransactionsOverview'
export const SETTLEMENT_DETAILS = 'SettlementDetails'
export const SETTLEMENT_SUMMARY = 'SettlementSummary'

// card management
export const CARD_MANAGEMENT_CARD = 'CardManagementCards'
export const CARD_MANAGEMENT_CARD_DETAIL = 'CardManagementCardDetail'
export const CARD_MANAGEMENT_SUMMARY = 'CardManagementSummary'
export const CARD_MANAGEMENT_TRANSACTIONS = 'CardManagementTransactions'
export const CARD_MANAGEMENT_BALANCE = 'CardManagementBalances'
export const CARD_MANAGEMENT_BALANCE_TRANSACTIONS =
  'CardManagementBalanceTransactions'

// compliances
export const COMPLIANCES = 'Compliances'
export const COMPLIANCES_SUMMARY = 'ComplianceSummary'
export const COMPLIANCE_DETAILS = 'ComplianceDetails'

// external compliances
export const EXTERNAL_COMPLIANCES = 'ExternalCompliance'
export const EXTERNAL_COMPLIANCES_SUMMARY = 'ExternalComplianceSummary'

// global accounts
export const GLOBAL_ACCOUNTS = 'GlobalAccounts'
export const GLOBAL_ACCOUNT_DETAILS = 'GlobalAccountDetails'
export const GLOBAL_ACCOUNT_SUMMARY = 'GlobalAccountSummary'
export const GLOBAL_ACCOUNT_TRACSACTION = 'GlobalAccountTransaction'
export const GLOBAL_ACCOUNT_COUNTRY_CURRENCY_PAIR =
  'GlobalAcountCountryCurrencyPaird'

// global account feeds
export const GLOBAL_ACCOUNTS_FEEDS = 'GlobalAccountFeeds'
export const GLOBAL_ACCOUNT_FEED_DETAILS = 'GlobalAccountFeedDetails'
export const GLOBAL_ACCOUNT_FEED_SUMMARY = 'GlobalAccountFeedSummary'

// global account collections
export const GLOBAL_ACCOUNTS_COLLECTIONS = 'GlobalAccountCollections'
export const GLOBAL_ACCOUNT_COLLECTION_DETAILS =
  'GlobalAccountCollectionDetails'
export const GLOBAL_ACCOUNT_COLLECTION_SUMMARY =
  'GlobalAccountCollectionSummary'

// Global Search
export const GLOBAL_SEARCH = 'GlobalSearch'

// Reconciliation
export const RECONCILIATION_TRANSACTIONS = 'ReconciliationTransactions'
export const RECONCILIATION_SUMMARY = 'ReconciliationSummary'
// Tag
export const TAGS = 'Tags'

// Wallets
export const VA_CURRENCIES = 'VaCurrencies'

// Files
export const FILE = 'File'

// Amendments
export const AMENDMENTS = 'Amendments'

export const PAYMENT_TRANSFER_METHODS = 'PaymentTransferMethods'
