import Svg, { SvgProps } from '@transwap/ui/Core/Icons/Svg'

const DefinisBrand = ({
  width = 408,
  height = 170,
  fontSize = 'medium',
  className,
}: SvgProps) => {
  return (
    <Svg
      width={width}
      height={height}
      fontSize={fontSize}
      viewBox="0 0 408 170"
      className={className}
    >
      <g clipPath="url(#clip0_10_10)">
        <rect width="408" height="170" fill="inherit" />
        <path
          d="M35.187 38.8814L17.5 49.5L1 39.4068L70.9281 0L145 43.6102V62L70.9281 17.8644L49.6621 30.5L79.7338 47.8136L64.7122 56.2203L35.187 38.8814Z"
          fill="url(#paint0_linear_10_10)"
        />
        <path
          d="M139.5 65L124.5 56L72 87L0 43.5V128.5L50.5 158L66 149L15.5 119.5V71.5L72 103.5V140.5L130.5 106.5V119.5L57.5 161.5L72 170L144 128.5V81.5L88 113V95L139.5 65Z"
          fill="url(#paint1_linear_10_10)"
        />
        <path
          d="M255.5 70.5V72.5H205C202.239 72.5 200 74.7386 200 77.5V109C200 111.761 202.239 114 205 114H255.5V116C255.5 122.627 250.127 128 243.5 128H198C191.373 128 186 122.627 186 116V70.5C186 63.8726 191.373 58.5 198 58.5H243.5C250.127 58.5 255.5 63.8726 255.5 70.5Z"
          fill="white"
          style={{
            marginRight: -20,
          }}
        />
        <path
          d="M263.5 70V128H278V101H311.5C318.127 101 323.5 95.6274 323.5 89V86.5H278V78C278 75.2386 280.239 73 283 73H332.5V70C332.5 63.3726 327.127 58 320.5 58H275.5C268.873 58 263.5 63.3726 263.5 70Z"
          fill="white"
        />
        <path
          d="M407.5 70.5V73H358C355.239 73 353 75.2386 353 78V82C353 84.7614 355.239 87 358 87H395.5C402.127 87 407.5 92.3726 407.5 99V116.5C407.5 123.127 402.127 128.5 395.5 128.5H350.5C343.873 128.5 338.5 123.127 338.5 116.5V114.5H389C391.761 114.5 394 112.261 394 109.5V105C394 102.239 391.761 100 389 100H350.5C343.873 100 338.5 94.6274 338.5 88V70.5C338.5 63.8726 343.873 58.5 350.5 58.5H395.5C402.127 58.5 407.5 63.8726 407.5 70.5Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_10_10"
          x1="18.0935"
          y1="35.2034"
          x2="144.584"
          y2="56.6682"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00494761" stopColor="#2C2BAE" />
          <stop offset="0.542579" stopColor="#7D61F8" />
          <stop offset="0.911458" stopColor="#4FA6FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_10_10"
          x1="17.0935"
          y1="115.758"
          x2="146.319"
          y2="126.592"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00494761" stopColor="#2C2BAE" />
          <stop offset="0.542579" stopColor="#7D61F8" />
          <stop offset="0.911458" stopColor="#4FA6FF" />
        </linearGradient>
        <clipPath id="clip0_10_10">
          <rect width="408" height="170" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  )
}

export default DefinisBrand
