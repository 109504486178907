import { CardStatus } from '@transwap/ui/App/Shared'

const renderCreditFeedStatus = (value: string) => {
  let status: CardStatus = 'draft'
  switch (value) {
    case 'Capture':
      status = 'active'
      break
    case 'Reconciled':
      status = 'redeemed'
      break
    default:
      break
  }
  return {
    label: value,
    status,
  }
}

export default renderCreditFeedStatus
