import { CardStatus } from '@transwap/ui/App/Shared'

const renderDepositStatus = (value: string) => {
  let label = ''
  let status: CardStatus = 'draft'
  switch (value) {
    case 'Completed':
      label = 'Completed'
      status = 'active'
      break
    case 'Rejected':
      label = 'Rejected'
      status = 'expired'
      break
    case 'Pending_Approval':
      label = 'Pending Approval'
      status = 'draft'
      break
    case 'Prefund':
      label = 'Prefund'
      status = 'info'
      break
    case 'Awaiting_Fund':
      label = 'Awaiting Fund'
      status = 'draft'
      break
    case 'Expired':
      label = 'Expired'
      status = 'expired'
      break
    case 'Cancelled':
      label = 'Cancelled'
      status = 'cancelled'
      break
    default:
      break
  }
  return { label, status }
}

export default renderDepositStatus
