import {
  CustomResponseModel,
  FeeScheduleModel,
  FeeScheduleQueryModel,
  ImportFeeScheduleModel,
  ImportRequestModel,
  Pageable,
} from 'src/@types'
import axios from 'src/api/axios'

const baseURL = '/feeSchedules'

const feeSchedules = {
  getFeeSchedules: (params?: FeeScheduleQueryModel) =>
    axios.get<Pageable<FeeScheduleModel>>(
      `${baseURL}?&timeStamp=${new Date().getTime()}`,
      { params }
    ),
  importFeeSchedule: (payload: ImportRequestModel) => {
    const { file, options } = payload
    const formData = new FormData()
    formData.append('formFiles', file)
    return axios.post<CustomResponseModel<Array<ImportFeeScheduleModel>>>(
      `${baseURL}/import`,
      formData,
      { ...options }
    )
  },
  exportFeeSchedule: (params: FeeScheduleQueryModel) =>
    axios.get<Blob>(`${baseURL}/export`, { params, responseType: 'blob' }),

  deleteRange: (ids: Array<string>) => {
    return axios.request<boolean>({
      method: 'delete',
      url: `${baseURL}/range`,
      data: ids,
    })
  },
}

export default feeSchedules
