import Svg, { SvgProps } from '@transwap/ui/Core/Icons/Svg'

const ExportDocument = ({
  classes,
  fontSize,
  border,
  borderProps,
  color,
}: SvgProps) => {
  return (
    <Svg
      fontSize={fontSize}
      classes={classes}
      border={border}
      borderProps={borderProps}
      color={color}
      viewBox="0 0 18 19"
      fill="none"
    >
      <path
        d="M11 1.875H5C3.89543 1.875 3 2.77043 3 3.875V14.125C3 15.2296 3.89543 16.125 5 16.125H9.375M11 1.875V3.625C11 4.72957 11.8954 5.625 13 5.625H15M11 1.875H11.5466C12.077 1.875 12.5857 2.08571 12.9608 2.46079L14.4142 3.91421C14.7893 4.28929 15 4.79799 15 5.32843V5.625M15 5.625V9.75"
        stroke="#16171C"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.30442 12C7.29038 11.9918 7.27984 11.9781 7.2658 11.9726C6.78486 11.8108 6.63039 11.501 6.84805 11.1282C7.164 10.588 7.48346 10.0479 7.80642 9.51057C7.86961 9.40638 7.87313 9.32139 7.80642 9.21447C7.47292 8.66888 7.14293 8.12329 6.82347 7.57495C6.67252 7.31724 6.76028 7.04033 7.0306 6.87583C7.2974 6.71407 7.67302 6.7031 7.93631 6.87309C8.04163 6.94163 8.12588 7.03759 8.18907 7.13354C8.45939 7.56947 8.71916 8.00814 8.9965 8.46873C9.03511 8.41664 9.0632 8.38374 9.08426 8.3481C9.34053 7.92314 9.59329 7.49819 9.85658 7.07597C10.004 6.84293 10.2884 6.73052 10.6149 6.75519C10.9062 6.77987 11.1801 6.94985 11.2292 7.18838C11.2573 7.32546 11.2292 7.48996 11.1555 7.61608C10.8536 8.1507 10.5271 8.6771 10.2006 9.2035C10.1339 9.31043 10.1269 9.4009 10.1936 9.51057C10.5025 10.0123 10.7834 10.525 11.1028 11.0212C11.345 11.3941 11.338 11.7752 10.6991 11.9973C10.5762 11.9973 10.4534 11.9973 10.3305 11.9973C10.0286 11.9068 9.8636 11.7258 9.74073 11.501C9.5301 11.1227 9.30191 10.7525 9.07724 10.3797C9.05618 10.344 9.02809 10.3139 8.99299 10.2618C8.74023 10.6867 8.49449 11.0815 8.26982 11.4873C8.14344 11.7149 7.97844 11.904 7.67302 11.9973C7.55016 12 7.42729 12 7.30442 12Z"
        fill="#16171C"
      />
      <path
        d="M12 15.375L13.875 17.25L15.75 15.375"
        stroke="#16171C"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.875 17.25L13.875 12.375"
        stroke="#16171C"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default ExportDocument
