import { Pageable } from 'src/@types'
import {
  SettlementCancelModel,
  SettlementCreateModel,
  SettlementOverviewModel,
  SettlementRequestModel,
  SettlementSummaryModel,
  SettlementCompletedModel,
  SettlementModel,
  SettlementCreateSuccessModel,
  SettlementTransactionModel,
} from 'src/@types/models'
import axios from 'src/api/axios'
import { v4 as uuidv4 } from 'uuid'
import { IDEMPOTENCY_KEY } from 'src/constants/common'

const baseURL = '/settlements'

const settlements = {
  getSettlements: (params: SettlementRequestModel) =>
    axios.get<Pageable<SettlementModel>>(baseURL, { params }),
  getSettlementTransactions: (params: SettlementRequestModel) =>
    axios.get<Pageable<SettlementTransactionModel>>(`${baseURL}/transactions`, {
      params,
    }),
  getSettlementSummary: () =>
    axios.get<SettlementSummaryModel>(`${baseURL}/:getSummary`),
  getSettlementOverview: (id: string, currency: string) =>
    axios.get<SettlementOverviewModel>(
      `${baseURL}/${id}/overview?currency=${currency}`
    ),
  createSettlement: (payload: SettlementCreateModel) =>
    axios.post<SettlementCreateSuccessModel>(baseURL, payload, {
      headers: {
        [IDEMPOTENCY_KEY]: uuidv4(),
      },
    }),
  cancelSettlement: (payload: SettlementCancelModel) =>
    axios.put(`${baseURL}/${payload.id}:cancel`, { reason: payload.reason }),
  completeSettlement: (payload: SettlementCompletedModel) =>
    axios.put(`${baseURL}/${payload.id}:complete`, { reason: payload.reason }),
}

export default settlements
