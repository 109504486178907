import { useEffect } from 'react'
import { Route, RouteProps } from 'react-router-dom'
import { useAuthContext } from 'src/auth/AuthContext'
import Loading from 'src/components/Loading'
import useRoles from 'src/hooks/useRoles'
import PageNotFound from 'src/pages/StatusPage/PageNotFound'
import PermissionDenied from 'src/pages/StatusPage/PermissionDenied'

export type ProtectedRouteProps = RouteProps & {
  isEnabledFeature: boolean
  isCreate?: boolean
}

const ProtectedRoute = ({
  children,
  component,
  exact,
  path,
  render,
  isEnabledFeature,
  isCreate,
}: ProtectedRouteProps) => {
  const { signin, hasAuthenticated, isProfileLoading } = useAuthContext()
  const { isGenericContributor } = useRoles()

  useEffect(() => {
    const handleSigin = async () => {
      if (hasAuthenticated === false) {
        signin()
      }
    }
    handleSigin()
  }, [hasAuthenticated, signin])

  if (!hasAuthenticated || isProfileLoading) {
    return <Loading />
  }

  if (!isEnabledFeature) {
    return <PageNotFound />
  }
  if (isCreate && !isGenericContributor && !isProfileLoading) {
    return <PermissionDenied />
  }

  return (
    <Route component={component} exact={exact} path={path} render={render}>
      {children}
    </Route>
  )
}

export default ProtectedRoute
