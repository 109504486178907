import { ComplianceDetailsModel, Pageable } from 'src/@types'
import {
  ExternalComplianceModel,
  ExternalCompliancesRequestModel,
  ExternalComplianceSummaryModel,
  RespondExternalComplianceRequestModel,
} from 'src/@types/models/compliances'
import axios from 'src/api/axios'

const baseURL = '/externalCompliance'

const externalCompliances = {
  getExternalCompliances: (params?: ExternalCompliancesRequestModel) =>
    axios.get<Pageable<ExternalComplianceModel>>(baseURL, { params }),
  getExternalComplianceSumary: () =>
    axios.get<ExternalComplianceSummaryModel>(`${baseURL}/:getSummary`),
  respondExternalCompliance: (
    id: string,
    payload: RespondExternalComplianceRequestModel
  ) => axios.put<ComplianceDetailsModel>(`${baseURL}/${id}:response`, payload),
}

export default externalCompliances
