import Svg, { SvgProps } from '@transwap/ui/Core/Icons/Svg'

const InfoFill = ({
  classes,
  fontSize,
  border,
  borderProps,
  color,
}: SvgProps) => {
  return (
    <Svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fontSize={fontSize}
      classes={classes}
      border={border}
      borderProps={borderProps}
      color={color}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="8.99982"
        cy="8.99982"
        r="6.75018"
        transform="rotate(180 8.99982 8.99982)"
        fill="#888B94"
      />
      <path
        d="M9 11.8125L9 9"
        stroke="white"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="9" cy="6" r="1" transform="rotate(180 9 6)" fill="white" />
    </Svg>
  )
}

export default InfoFill
