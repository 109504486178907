import Svg, { SvgProps } from '@transwap/ui/Core/Icons/Svg'

const Refund = ({
  classes,
  fontSize,
  border,
  borderProps,
  color,
}: SvgProps) => {
  return (
    <Svg
      fontSize={fontSize}
      classes={classes}
      border={border}
      borderProps={borderProps}
      color={color}
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M6.96885 25.3399C12.1009 29.4326 19.6893 28.7239 23.9181 23.757C28.1469 18.7901 27.4147 11.4459 22.2826 7.35324C17.1506 3.26056 9.56212 3.96923 5.33334 8.93611M5.33334 8.93611L6.05887 4M5.33334 8.93611L10.412 8.91523"
        stroke="#16171C"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3333 22.666V21.166"
        stroke="#16171C"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3333 12.166V10.666"
        stroke="#16171C"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.6667 14.0649C18.3833 13.1342 17.4667 12.4136 16.3 12.2185C15.1333 12.0233 13.9167 12.3836 13.2333 13.1192C13.15 13.2093 12.3833 14.0499 12.75 14.9507C12.9833 15.5211 13.5333 15.8364 14.3 16.2567C15.1333 16.7221 15.3667 16.647 16.6333 17.2325C17.9167 17.833 18.1333 18.1332 18.2333 18.2833C18.6 18.9139 18.35 19.5444 18.3 19.6645C17.95 20.4752 16.9833 20.8054 16.6333 20.9405C16.4833 21.0006 15.0833 21.4509 13.7 20.8805C12.9333 20.5652 12.5333 20.0848 12.4 19.9347C12.1833 19.6495 12.0667 19.3943 12 19.1991"
        stroke="#16171C"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default Refund
