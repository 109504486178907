import Svg, { SvgProps } from '@transwap/ui/Core/Icons/Svg'

const TickedIcon = ({
  fontSize = 'default',
  width = 24,
  height = 24,
  className,
}: SvgProps) => {
  return (
    <Svg
      fontSize={fontSize}
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="9" stroke="#16171C" strokeWidth="1.6" />
      <path
        d="M6 10L9 13L14.5 7.5"
        stroke="#16171C"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default TickedIcon
