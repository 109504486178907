import { TransEvent } from '@transwap/ui'
import { useFormik, FormikConfig, FormikValues } from 'formik'
import _debounce from 'lodash/debounce'
import { DEBOUNCE_WAIT_MS } from 'src/constants/common'

function useTransFormik<T extends FormikValues>(
  config: FormikConfig<T>,
  useDebounce = true
) {
  const formik = useFormik<T>(config)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleChange = _debounce((event: TransEvent) => {
    formik.handleChange(event)
  }, DEBOUNCE_WAIT_MS)

  const handleChange = (event: TransEvent) => {
    formik.handleChange(event)
  }

  return {
    ...formik,
    handleChange: useDebounce ? debouncedHandleChange : handleChange,
  }
}

export default useTransFormik
