import { useQueryClient } from 'react-query'
import {
  REFERENCE_CURRENCIES_PARTNERS,
  REFERENCE_BANKS,
  REFERENCE_CLIENT_BANKS,
  REFERENCE_COUNTRIES_LICENSES,
  REFERENCE_CURRENCIES,
  REFERENCE_LANGUAGES,
  PROFILE,
  REFERENCE_PARTNERS,
  VA_CURRENCIES,
  REFERENCE_COUNTRIES,
} from 'src/constants/queryKeys'
import {
  BankModel,
  ClientBankModel,
  CountryModel,
  CurrenciesPartnersModel,
  CurrencyModel,
  LanguageModel,
  PartnerModel,
} from 'src/@types/models/references'
import { ProfileModel } from 'src/@types'
import {
  OTHER_COUNTRY_CODE,
  OTHER_COUNTRY_ID,
  OTHER_COUNTRY_NAME,
} from 'src/constants/common'

const useMasterData = () => {
  const queryClient = useQueryClient()

  const vaCurrencies = queryClient.getQueryData<Array<string>>(VA_CURRENCIES)

  const currenciesPartners = queryClient.getQueryData<
    Array<CurrenciesPartnersModel>
  >(REFERENCE_CURRENCIES_PARTNERS)

  const banks =
    queryClient.getQueryData<Array<BankModel>>(REFERENCE_BANKS) || []

  const getPartners = () => {
    return (
      queryClient.getQueryData<Array<PartnerModel>>(REFERENCE_PARTNERS) || []
    )
  }

  const getBanks = () => {
    return queryClient.getQueryData<Array<BankModel>>(REFERENCE_BANKS) || []
  }

  const clientBanks =
    queryClient.getQueryData<Array<ClientBankModel>>(REFERENCE_CLIENT_BANKS) ||
    []

  const countriesLicenses =
    queryClient.getQueryData<Array<CountryModel>>(
      REFERENCE_COUNTRIES_LICENSES
    ) || []

  const otherCountry: CountryModel = {
    code: OTHER_COUNTRY_CODE,
    name: OTHER_COUNTRY_NAME,
    countryId: OTHER_COUNTRY_ID,
    phonePrefix: '',
  }

  const countriesLicenseWithOther = [...countriesLicenses, ...[otherCountry]]

  const countries =
    queryClient.getQueryData<Array<CountryModel>>(REFERENCE_COUNTRIES) || []

  const currencies =
    queryClient.getQueryData<Array<CurrencyModel>>(REFERENCE_CURRENCIES) || []

  const languages =
    queryClient.getQueryData<Array<LanguageModel>>(REFERENCE_LANGUAGES) || []

  const getProfiles = () =>
    queryClient.getQueryData<ProfileModel>(PROFILE) || {}

  const profiles = getProfiles()

  const getCountryLicenses = (code: string) => {
    const country = countriesLicenses.find(item =>
      !item ? false : item.code?.toLowerCase() === code?.toLowerCase()
    )

    return country
  }

  const getLanguage = (code: string) => {
    const language = languages.find(item =>
      !item ? false : item.code?.toLowerCase() === code?.toLowerCase()
    )

    return language
  }

  const getNameCountryLicenses = (code: string) => {
    const country = getCountryLicenses(code)

    return country?.name
  }

  const getCurrencies = () => {
    return (
      queryClient.getQueryData<Array<CurrencyModel>>(REFERENCE_CURRENCIES) || []
    )
  }

  const getCurrency = (code: string) => {
    const refCurrencies = getCurrencies()
    const currency = refCurrencies.find(item =>
      !item ? false : item.code?.toLowerCase() === code?.toLowerCase()
    )

    return currency
  }
  const getCurrenciesPartners = () => {
    return (
      queryClient.getQueryData<Array<CurrenciesPartnersModel>>(
        REFERENCE_CURRENCIES_PARTNERS
      ) || []
    )
  }

  const getClientBankByCode = (code?: string) => {
    return clientBanks.find(c => c.value === code)
  }

  return {
    vaCurrencies,
    currenciesPartners,
    banks,
    clientBanks,
    countriesLicenses,
    countriesLicenseWithOther,
    currencies,
    countries,
    languages,
    profiles,
    getPartners,
    getProfiles,
    getCountryLicenses,
    getCurrency,
    getLanguage,
    getNameCountryLicenses,
    getCurrencies,
    getBanks,
    getCurrenciesPartners,
    getClientBankByCode,
  }
}

export default useMasterData
