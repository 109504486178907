/* eslint-disable react/jsx-props-no-spreading */
import Svg, { SvgProps } from '@transwap/ui/Core/Icons/Svg'

export default (props: Omit<SvgProps, 'children'>) => {
  return (
    <Svg {...props} viewBox="0 0 32 32">
      <path
        d="M30.5332 11.9356L27.6189 21.0022C27.432 21.5835 27.3798 21.7124 27.3083 21.7992C27.2296 21.8948 27.128 21.9689 27.0129 22.0146L27.2343 22.5722L27.0129 22.0146C26.9084 22.0561 26.7697 22.0663 26.1591 22.0663L4.92648 22.0663C4.42051 22.0663 4.08124 22.0658 3.82616 22.0429C3.57434 22.0203 3.4811 21.9807 3.43667 21.9526C3.27936 21.8529 3.16584 21.6972 3.11913 21.517C3.10594 21.466 3.09678 21.3652 3.15233 21.1185C3.20859 20.8687 3.31187 20.5455 3.4667 20.0638L6.38098 10.9971C6.56783 10.4158 6.62005 10.2869 6.69152 10.2001C6.77024 10.1046 6.87189 10.0305 6.98699 9.98476C7.09148 9.94328 7.23017 9.93301 7.84076 9.93301L29.0734 9.93301C29.5794 9.93301 29.9186 9.93358 30.1737 9.95647C30.4255 9.97906 30.5188 10.0187 30.5632 10.0468C30.7205 10.1464 30.834 10.3021 30.8807 10.4824C30.8939 10.5333 30.9031 10.6342 30.8475 10.8808C30.7913 11.1307 30.688 11.4539 30.5332 11.9356Z"
        stroke="#16171C"
        strokeWidth="1.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6668 20C16.3723 20 16.1335 19.7761 16.1335 19.5V19.1923H15.0668C14.7723 19.1923 14.5335 18.9684 14.5335 18.6923C14.5335 18.4162 14.7723 18.1923 15.0668 18.1923H16.1335V16.5H15.9694C14.8818 16.5 14.0002 15.6734 14.0002 14.6538C14.0002 13.6342 14.8818 12.8077 15.9694 12.8077H16.1335V12.5C16.1335 12.2239 16.3723 12 16.6668 12C16.9614 12 17.2002 12.2239 17.2002 12.5V12.8077H18.2668C18.5614 12.8077 18.8002 13.0315 18.8002 13.3077C18.8002 13.5838 18.5614 13.8077 18.2668 13.8077H17.2002V15.5H17.3643C18.4518 15.5 19.3335 16.3266 19.3335 17.3462C19.3335 18.3658 18.4518 19.1923 17.3643 19.1923H17.2002V19.5C17.2002 19.7761 16.9614 20 16.6668 20ZM17.2002 18.1923H17.3643C17.8627 18.1923 18.2668 17.8135 18.2668 17.3462C18.2668 16.8788 17.8627 16.5 17.3643 16.5H17.2002V18.1923ZM16.1335 15.5V13.8077H15.9694C15.4709 13.8077 15.0668 14.1865 15.0668 14.6538C15.0668 15.1212 15.4709 15.5 15.9694 15.5H16.1335Z"
        fill="#16171C"
      />
      <path
        d="M24.4 26.933L26 25.333M26 25.333L24.4 23.733M26 25.333H18"
        stroke="#16171C"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M14.4 8.267L16 6.667M16 6.667L14.4 5.067M16 6.667H8.00001"
        stroke="#16171C"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M4.6665 12.667H8.33317"
        stroke="#16171C"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M3.3335 15.333H7.00016"
        stroke="#16171C"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M2 18H5.66667"
        stroke="#16171C"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M1.3335 12.667H2.66683"
        stroke="#16171C"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
    </Svg>
  )
}
