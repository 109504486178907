import {
  Pageable,
  CardBalancesRequestModel,
  CardBalancesModel,
  CardBalanceTransactionsRequestModel,
  CardBalanceTransactionsModel,
} from 'src/@types'
import axios from 'src/api/axios'

const baseURL = '/cardBalances'

const cardBalances = {
  getCardBalances: (params?: CardBalancesRequestModel) =>
    axios.get<Pageable<CardBalancesModel>>(baseURL, { params }),
  getCardBalanceTransactions: (
    accountId: string,
    params?: CardBalanceTransactionsRequestModel
  ) =>
    axios.get<Pageable<CardBalanceTransactionsModel>>(
      `${baseURL}/${accountId}/transactions`,
      { params }
    ),
}

export default cardBalances
