import { PropsWithChildren, useRef, useCallback } from 'react'
import { SnackbarProvider, SnackbarKey } from 'notistack'
import { CloseIconButton } from '@transwap/ui/App/Shared'

const TransSnackbarProvider = ({ children }: PropsWithChildren<unknown>) => {
  const notistackRef = useRef<SnackbarProvider>(null)
  const onClickDismiss = useCallback(
    (key: SnackbarKey) => () => {
      notistackRef.current?.closeSnackbar(key)
    },
    [notistackRef]
  )
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      hideIconVariant
      ref={notistackRef}
      action={key => (
        <CloseIconButton iconColor="white" onClick={onClickDismiss(key)} />
      )}
    >
      {children}
    </SnackbarProvider>
  )
}

export default TransSnackbarProvider
