import StatusPageTemplate from 'src/pages/StatusPage/StatusPageTemplate'
import permissionDenied from 'src/assets/images/StatusPage/PermissionDenied.svg'

const PermissionDenied = () => {
  return (
    <StatusPageTemplate
      logo={permissionDenied}
      logoName="permission-denied"
      heading="Permission denied"
      bodyOne="You don't have permissions to access this page."
      bodyTwo="Contact an administrator to get permissions or go to the home
      page and browse other pages"
    />
  )
}

export default PermissionDenied
