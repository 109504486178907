import { SelectField, SelectFieldProps } from '@transwap/ui/Core/Input'
import { useQuery } from 'react-query'
import { REFERENCE_REJECTION_ERROR_CODES } from 'src/constants/queryKeys'
import {
  RejectionErrorTransactionType,
  RejectionErrorModel,
  RejectionErrorCategory,
} from 'src/@types'
import { referenceEndpoints } from 'src/api/endpoints'
import { makeStyles } from '@material-ui/core'

export interface SelectionRejectProps
  extends Pick<
    SelectFieldProps<RejectionErrorModel, false, false>,
    'onChange' | 'onBlur' | 'name' | 'helperText' | 'error'
  > {
  transactionType?: RejectionErrorTransactionType
  category?: RejectionErrorCategory
}

const useStyles = makeStyles(() => ({
  input: {
    maxWidth: 475,
  },
}))

const SelectionReject = ({
  transactionType = 'SendFund',
  category = 'Compliance',
  onChange,
  error,
  helperText,
  onBlur,
  name = 'reason',
}: SelectionRejectProps) => {
  const { data } = useQuery(
    [REFERENCE_REJECTION_ERROR_CODES, transactionType, category],
    () =>
      referenceEndpoints.getRejectionErrorCodes({
        transactionType,
        errorCategory: category,
      })
  )

  const classes = useStyles()

  return (
    <SelectField
      classes={{
        input: classes.input,
      }}
      placeholder="Select reason"
      label="Reason"
      labelField="message"
      valueField="code"
      onChange={onChange}
      name={name}
      error={error}
      helperText={helperText}
      onBlur={onBlur}
      disableClearable
      searchable={false}
      fullWidth
      options={data || []}
    />
  )
}

export default SelectionReject
