import { useEffect } from 'react'
import { useAuthContext } from 'src/auth/AuthContext'
import Loading from 'src/components/Loading'

const SignoutCallback = () => {
  const { signoutRedirectCallback } = useAuthContext()
  useEffect(() => {
    signoutRedirectCallback()
  }, [signoutRedirectCallback])

  return <Loading />
}

export default SignoutCallback
