import { Tooltip } from '@material-ui/core'
import Svg, { SvgProps } from '@transwap/ui/Core/Icons/Svg'

const GlobalTransferBulks = ({
  classes,
  fontSize,
  border,
  borderProps,
  color,
}: SvgProps) => {
  return (
    <Tooltip title="GA Bulk payment">
      <span>
        <Svg
          fontSize={fontSize}
          classes={classes}
          border={border}
          borderProps={borderProps}
          color={color}
          viewBox="0 0 33 32"
          fill="none"
          xmlns="http://www.w3.org/2000/Svg"
        >
          <circle
            cx="13.0333"
            cy="15.5033"
            r="12"
            transform="rotate(-90 13.0333 15.5033)"
            fill="#5954E9"
            stroke="white"
            strokeWidth="1.06667"
          />
          <path
            d="M7.45387 8.72982L5.14526 6.42121L7.09388 5.03828L9.16806 4.09547L12.185 3.52979L15.3326 3.66038L17.8419 4.47259L14.5009 7.25682L14.3887 9.99997L12.984 11.4046L10.2409 11.5168L8.9744 12.7833L6.18738 12.8056L6.18738 9.99631L7.45387 8.72982Z"
            stroke="white"
            strokeWidth="2.13333"
            strokeLinejoin="round"
          />
          <path
            d="M5.47656 24.1644L8.05029 21.5906L8.04554 18.6058L10.7773 15.874L12.2721 17.3688L15.0766 17.6899L16.3675 18.9808L16.5074 21.6041L19.04 21.6533L20.2129 19.7558L20.1635 17.1326L21.9709 16.1406L25.4778 17.3898"
            stroke="white"
            strokeWidth="2.13333"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.786 20.1005C22.7502 20.9976 23.0747 21.9065 23.7596 22.5914C25.0614 23.8932 27.1719 23.8932 28.4737 22.5914L31.7909 19.2742C32.416 18.6491 32.7672 17.8012 32.7672 16.9172C32.7672 16.0331 32.416 15.1853 31.7909 14.5601L28.4737 11.2429C27.1719 9.94116 25.0614 9.94116 23.7596 11.2429C23.151 11.8515 22.8269 12.637 22.7874 13.4339L18.1995 13.4339C16.3585 13.4339 14.8661 14.9262 14.8661 16.7672C14.8661 18.6081 16.3585 20.1005 18.1995 20.1005L22.786 20.1005Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.7513 11.8509C17.7203 12.7082 18.032 13.5755 18.6865 14.23C19.9361 15.4797 21.9623 15.4797 23.2119 14.23L26.5292 10.9128C27.1293 10.3127 27.4664 9.49873 27.4664 8.65003C27.4664 7.80134 27.1293 6.98741 26.5292 6.38729L23.2119 3.07007C21.9623 1.82039 19.9361 1.82039 18.6865 3.07007C18.0316 3.72494 17.7199 4.59302 17.7514 5.45085L16.7664 5.45085C14.9991 5.45085 13.5664 6.88354 13.5664 8.65085C13.5664 10.4182 14.9991 11.8509 16.7664 11.8509L17.7513 11.8509Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.0882 12.7259C28.6061 12.2437 27.8243 12.2437 27.3422 12.7259C26.86 13.2081 26.86 13.9898 27.3422 14.472L28.5525 15.6823L18.6964 15.6823C18.0146 15.6823 17.4618 16.2351 17.4618 16.917C17.4618 17.5989 18.0146 18.1516 18.6964 18.1516L28.5509 18.1516L27.3422 19.3603C26.86 19.8425 26.86 20.6242 27.3422 21.1064C27.8243 21.5886 28.6061 21.5886 29.0882 21.1064L32.4055 17.7892C32.637 17.5576 32.7671 17.2436 32.7671 16.9161C32.7671 16.5887 32.637 16.2747 32.4055 16.0431L29.0882 12.7259Z"
            fill="#76B5FF"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.2201 27.8509C17.189 28.7082 17.5008 29.5755 18.1552 30.23C19.4049 31.4797 21.431 31.4797 22.6807 30.23L25.9979 26.9128C26.598 26.3127 26.9352 25.4987 26.9352 24.65C26.9352 23.8013 26.598 22.9874 25.9979 22.3873L22.6807 19.0701C21.431 17.8204 19.4049 17.8204 18.1552 19.0701C17.5003 19.7249 17.1887 20.593 17.2201 21.4509L16.2351 21.4509C14.4678 21.4509 13.0351 22.8835 13.0351 24.6509C13.0351 26.4182 14.4678 27.8509 16.2351 27.8509L17.2201 27.8509Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.6207 4.36114C21.1385 3.87897 20.3568 3.87897 19.8746 4.36114C19.3925 4.8433 19.3925 5.62505 19.8746 6.10722L21.085 7.31755L16.5633 7.31755C15.8814 7.31755 15.3286 7.87033 15.3286 8.55222C15.3286 9.23411 15.8814 9.78688 16.5633 9.78688L21.0833 9.78688L19.8746 10.9956C19.3925 11.4777 19.3925 12.2595 19.8746 12.7417C20.3568 13.2238 21.1385 13.2238 21.6207 12.7417L24.9379 9.42444C25.1695 9.1929 25.2996 8.87886 25.2996 8.5514C25.2996 8.22395 25.1695 7.9099 24.9379 7.67836L21.6207 4.36114Z"
            fill="#76B5FF"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.2903 20.3611C20.8081 19.879 20.0263 19.879 19.5442 20.3611C19.062 20.8433 19.062 21.6251 19.5442 22.1072L20.7545 23.3176L16.2328 23.3176C15.551 23.3176 14.9982 23.8703 14.9982 24.5522C14.9982 25.2341 15.551 25.7869 16.2328 25.7869L20.7529 25.7869L19.5442 26.9956C19.062 27.4777 19.062 28.2595 19.5442 28.7417C20.0263 29.2238 20.8081 29.2238 21.2903 28.7417L24.6075 25.4244C24.839 25.1929 24.9691 24.8789 24.9691 24.5514C24.9691 24.2239 24.839 23.9099 24.6075 23.6784L21.2903 20.3611Z"
            fill="#76B5FF"
          />
        </Svg>
      </span>
    </Tooltip>
  )
}

export default GlobalTransferBulks
