import { CardStatus } from '@transwap/ui/App/Shared'
import { AccountType } from 'src/@types'

const renderClientStatus = (
  value = 'PendingConfirmation',
  accountType?: AccountType,
  isSubmitted = true
) => {
  let status: CardStatus = 'draft'
  let label = value.toString()

  switch (value) {
    case 'Approved':
      status = 'active'
      break
    case 'PendingConfirmation':
      label =
        isSubmitted || accountType === 'Personal'
          ? 'Pending Confirmation'
          : 'Draft'
      break
    case 'PendingApproval':
      status = 'draft'
      label = 'Pending Approval'
      break
    case 'Rejected':
      status = 'expired'
      break
    case 'Suspended':
      status = 'cancelled'
      break
    case 'PreApproval':
      status = 'info'
      label = 'Pre-Approved'
      break
    default:
      break
  }

  return { label, status }
}

export default renderClientStatus
