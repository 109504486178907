/* eslint-disable promise/prefer-await-to-then */
import {
  HistoryModel,
  NoteCreateModel,
  NoteModel,
  NotesRequestModel,
  Pageable,
} from 'src/@types/common'
import {
  ApproveRefundModel,
  CancelRefundModel,
  PaidRefundModel,
  RefundCreateModel,
  RefundDetailModel,
  RefundModel,
  RefundRequestModel,
  RefundSummary,
  RejectRefundModel,
  RelatedTransactionModel,
  RelatedTransactionRequestModel,
  SubmitRefundModel,
  TransactionCummulativeModel,
} from 'src/@types/models'
import axios from 'src/api/axios'

const baseUrl = '/refunds'

const refunds = {
  getRefunds: (params: RefundRequestModel) =>
    axios.get<Pageable<RefundModel>>(baseUrl, { params }),
  getRefundDetails: (id: string) =>
    axios.get<RefundDetailModel>(`${baseUrl}/${id}`),
  getRefundSummary: () => axios.get<RefundSummary>(`${baseUrl}/:getSummary`),
  createRefund: (payload: RefundCreateModel) =>
    axios.post<RefundDetailModel>(baseUrl, payload),
  approveRefund: (id: string, payload: ApproveRefundModel) =>
    axios.put<RefundDetailModel>(`${baseUrl}/${id}:approve`, payload),
  rejectRefund: (id: string, payload: RejectRefundModel) =>
    axios.put<RefundDetailModel>(`${baseUrl}/${id}:reject`, payload),
  submitRefund: (id: string, payload: SubmitRefundModel) =>
    axios.put(`${baseUrl}/${id}:submit`, payload),
  cancelRefund: (id: string, payload: CancelRefundModel) =>
    axios.put<RefundDetailModel>(`${baseUrl}/${id}:cancel`, payload),
  paidRefund: (id: string, payload: PaidRefundModel) =>
    axios.put<RefundDetailModel>(`${baseUrl}/${id}:paid`, payload),
  searchRelatedTransaction: (params: RelatedTransactionRequestModel) =>
    axios.get<Pageable<RelatedTransactionModel>>(`${baseUrl}/search`, {
      params,
    }),
  getCummulative: (id: string) =>
    axios.get<TransactionCummulativeModel>(`${baseUrl}/${id}:cummulative`),
  getRefundHistories: (id: string) =>
    axios.get<Array<HistoryModel>>(`${baseUrl}/${id}:activities`),
  createNotes: (params: NoteCreateModel) =>
    axios.post(`${baseUrl}/attachment`, params),
  getNotes: (params: NotesRequestModel) =>
    axios.get<Pageable<NoteModel>>(`${baseUrl}/attachment`, { params }),
}

export default refunds
