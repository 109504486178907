import { CardStatus } from '@transwap/ui/App/Shared'

const renderRefundStatus = (value: string) => {
  let label
  let status: CardStatus = 'draft'
  switch (value) {
    case 'Paid':
      label = 'Paid'
      status = 'active'
      break
    case 'Pending_Approval':
      label = 'Pending Approval'
      break
    case 'Pending_Payout':
      label = 'Pending Payout'
      break
    case 'Payout_Inprogess':
      label = 'Payout in Progress'
      status = 'redeemed'
      break
    case 'Pending_Reconcile':
      label = 'Pending Reconcile'
      break
    case 'Cancelled':
    case 'Rejected':
      label = value
      status = 'expired'
      break
    default:
      label = value
      break
  }

  return {
    label,
    status,
  }
}

export default renderRefundStatus
