import Svg, { SvgProps } from '@transwap/ui/Core/Icons/Svg'

const GenerateLinkIcon = ({
  fontSize = 'default',
  width = 24,
  height = 24,
  className,
}: SvgProps) => {
  return (
    <Svg
      fontSize={fontSize}
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.50745 11.1371L4.38031 15.4529C4.2598 15.579 4.16415 15.7291 4.09888 15.8945C4.03361 16.0599 4 16.2373 4 16.4164C4 16.5956 4.03361 16.773 4.09888 16.9384C4.16415 17.1037 4.2598 17.2538 4.38031 17.38L7.10603 20.2571C7.22555 20.3843 7.36775 20.4853 7.52443 20.5542C7.6811 20.6231 7.84915 20.6586 8.01888 20.6586C8.18861 20.6586 8.35666 20.6231 8.51334 20.5542C8.67002 20.4853 8.81222 20.3843 8.93174 20.2571L13.0203 15.9007"
        stroke="#16171C"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.2218 12.5214L20.3104 8.20573C20.4309 8.07956 20.5266 7.92946 20.5918 7.76408C20.6571 7.5987 20.6907 7.42131 20.6907 7.24215C20.6907 7.063 20.6571 6.88561 20.5918 6.72023C20.5266 6.55485 20.4309 6.40475 20.3104 6.27858L17.6233 3.40144C17.5037 3.27424 17.3615 3.17327 17.2049 3.10437C17.0482 3.03547 16.8801 3 16.7104 3C16.5407 3 16.3726 3.03547 16.216 3.10437C16.0593 3.17327 15.9171 3.27424 15.7976 3.40144L11.709 7.75787"
        stroke="#16171C"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9361 9.11499L9.79321 14.5436"
        stroke="#16171C"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default GenerateLinkIcon
