import { useHistory, useLocation } from 'react-router-dom'
import _debounce from 'lodash/debounce'
import Organizations, { ORGANIZATION_QUERY } from 'src/constants/organizations'
import getOrganizationQuery from 'src/utils/getOrganizationQuery'
import queryString from 'query-string'
import useRoles from './useRoles'

const useOrganizationQuery = () => {
  const { isBizWhale } = useRoles()
  const { push } = useHistory()

  const { pathname } = useLocation()
  const organization = isBizWhale
    ? Organizations.Definis
    : getOrganizationQuery()

  const handleSetOrg = _debounce((org: Organizations) => {
    const temOrg = isBizWhale ? Organizations.Definis : org
    const arr = pathname.split('/')
    if (arr[1] === 'clients' && arr.length > 3) {
      const newPath = `/${arr[1]}/${arr[2]}`
      push(`${newPath}?${ORGANIZATION_QUERY}=${temOrg}`)
    } else {
      push(`${pathname}?${ORGANIZATION_QUERY}=${temOrg}`)
    }
  })

  const appendOrganizationToUrl = (path: string) => {
    const { url, query } = queryString.parseUrl(path)
    if (query[ORGANIZATION_QUERY] !== organization) {
      return queryString.stringifyUrl({
        url,
        query: { ...query, [ORGANIZATION_QUERY]: organization },
      })
    }
    return path
  }

  return {
    isDefinisOrganization: organization === Organizations.Definis,
    isTranswapOrganization:
      organization === Organizations.TranSwap ||
      organization === Organizations.UK,
    isUKOrganization: organization === Organizations.UK,
    currentOrganization: organization as Organizations,
    setOrganization: handleSetOrg,
    appendOrganizationToUrl,
  }
}

export default useOrganizationQuery
