/* eslint-disable */

import { makeStyles } from '@material-ui/core'
import { StatusCard, StatusCardProps } from '@transwap/ui/App/Shared'

interface TransactionStatusCardProps extends StatusCardProps {
  isBlackBox?: boolean
}

const useStyles = makeStyles(theme => ({
  blackBox: {
    background: theme.palette.grey[900],
  },
}))

const TransactionStatusCard = ({
  isBlackBox = false,
  ...rest
}: TransactionStatusCardProps) => {
  const classes = useStyles()
  if (isBlackBox) {
    return (
      <StatusCard
        {...rest}
        label="Black box"
        classes={{ root: classes.blackBox }}
      />
    )
  }
  return <StatusCard {...rest} />
}

export default TransactionStatusCard
