/* eslint-disable react/jsx-props-no-spreading */
import Svg, { SvgProps } from '@transwap/ui/Core/Icons/Svg'

export default (props: Omit<SvgProps, 'children'>) => {
  return (
    <Svg {...props} viewBox="0 0 32 32">
      <path
        d="M27.615 15.9178C27.615 22.038 22.6537 26.9993 16.5335 26.9993C10.4134 26.9993 5.45205 22.038 5.45205 15.9178C5.45205 9.79768 10.4134 4.83633 16.5335 4.83633C22.6537 4.83633 27.615 9.79768 27.615 15.9178Z"
        stroke="#16171C"
        strokeWidth="1.2"
      />
      <path
        d="M13.4593 24.2177C13.4593 27.4434 10.8443 30.0584 7.61857 30.0584C4.39282 30.0584 1.77783 27.4434 1.77783 24.2177C1.77783 20.9919 4.39282 18.377 7.61857 18.377C10.8443 18.377 13.4593 20.9919 13.4593 24.2177Z"
        fill="#F2F3FA"
        stroke="#16171C"
        strokeWidth="1.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.61872 20.8369C7.92431 20.8369 8.17205 21.0434 8.17205 21.298V21.5818H9.27871C9.58431 21.5818 9.83205 21.7882 9.83205 22.0429C9.83205 22.2976 9.58431 22.504 9.27871 22.504H8.17205V24.0647H8.3423C9.47066 24.0647 10.3854 24.827 10.3854 25.7673C10.3854 26.7076 9.47066 27.4698 8.3423 27.4698H8.17205V27.7536C8.17205 28.0082 7.92431 28.2147 7.61872 28.2147C7.31312 28.2147 7.06538 28.0082 7.06538 27.7536V27.4698H5.95872C5.65312 27.4698 5.40538 27.2634 5.40538 27.0087C5.40538 26.754 5.65312 26.5476 5.95872 26.5476H7.06538V24.9869H6.89513C5.76677 24.9869 4.85205 24.2247 4.85205 23.2844C4.85205 22.3441 5.76677 21.5818 6.89513 21.5818H7.06538V21.298C7.06538 21.0434 7.31312 20.8369 7.61872 20.8369ZM7.06538 22.504H6.89513C6.37796 22.504 5.95872 22.8534 5.95872 23.2844C5.95872 23.7153 6.37796 24.0647 6.89513 24.0647H7.06538V22.504ZM8.17205 24.9869V26.5476H8.3423C8.85947 26.5476 9.27871 26.1982 9.27871 25.7673C9.27871 25.3363 8.85947 24.9869 8.3423 24.9869H8.17205Z"
        fill="#16171C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.26713 16.6451C8.86584 16.6451 8.54053 16.3198 8.54053 15.9185C8.54053 11.5043 12.1189 7.92594 16.5331 7.92594C16.9344 7.92594 17.2597 8.25125 17.2597 8.65254C17.2597 9.05383 16.9344 9.37914 16.5331 9.37914C12.9215 9.37914 9.99372 12.3069 9.99372 15.9185C9.99372 16.3198 9.66842 16.6451 9.26713 16.6451ZM15.8065 23.1845C15.8065 22.7832 16.1318 22.4579 16.5331 22.4579C20.1447 22.4579 23.0725 19.5301 23.0725 15.9185C23.0725 15.5172 23.3978 15.1919 23.7991 15.1919C24.2004 15.1919 24.5257 15.5172 24.5257 15.9185C24.5257 20.3327 20.9473 23.9111 16.5331 23.9111C16.1318 23.9111 15.8065 23.5858 15.8065 23.1845Z"
        fill="#16171C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5535 20.9237C17.8236 21.1938 17.8236 21.6317 17.5535 21.9018L16.8199 22.6354L17.5535 23.3691C17.8236 23.6392 17.8236 24.0771 17.5535 24.3472C17.2834 24.6173 16.8455 24.6173 16.5754 24.3472L15.3527 23.1245C15.0826 22.8544 15.0826 22.4165 15.3527 22.1464L16.5754 20.9237C16.8455 20.6535 17.2834 20.6535 17.5535 20.9237Z"
        fill="#16171C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1794 10.5012C14.9093 10.231 14.9093 9.7931 15.1794 9.52299L15.913 8.78936L15.1794 8.05574C14.9093 7.78563 14.9093 7.34769 15.1794 7.07758C15.4495 6.80746 15.8874 6.80746 16.1575 7.07758L17.3802 8.30028C17.6504 8.57039 17.6504 9.00833 17.3802 9.27845L16.1575 10.5012C15.8874 10.7713 15.4495 10.7713 15.1794 10.5012Z"
        fill="#16171C"
      />
      <path
        d="M30.6889 7.61809C30.6889 10.5125 28.3425 12.8588 25.4482 12.8588C22.5538 12.8588 20.2074 10.5125 20.2074 7.61809C20.2074 4.7237 22.5538 2.37734 25.4482 2.37734C28.3425 2.37734 30.6889 4.7237 30.6889 7.61809Z"
        fill="#F2F3FA"
        stroke="#16171C"
        strokeWidth="1.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.8953 4.33811C23.1365 4.17431 23.4841 4.21225 23.6718 4.42284L25.4483 6.41693L27.2248 4.42284C27.4125 4.21225 27.7601 4.17431 28.0013 4.33811C28.2425 4.5019 28.286 4.8054 28.0984 5.01599L26.0016 7.36951V7.54881H26.7763C27.0819 7.54881 27.3296 7.76509 27.3296 8.03188C27.3296 8.29867 27.0819 8.51495 26.7763 8.51495H26.0016V8.79099H26.7763C27.0819 8.79099 27.3296 9.00726 27.3296 9.27405C27.3296 9.54084 27.0819 9.75712 26.7763 9.75712H26.0016V10.5162C26.0016 10.783 25.7539 10.9993 25.4483 10.9993C25.1427 10.9993 24.895 10.783 24.895 10.5162V9.75712H24.1203C23.8147 9.75712 23.567 9.54084 23.567 9.27405C23.567 9.00726 23.8147 8.79099 24.1203 8.79099H24.895V8.51495H24.1203C23.8147 8.51495 23.567 8.29867 23.567 8.03188C23.567 7.76509 23.8147 7.54881 24.1203 7.54881H24.895V7.36951L22.7982 5.01599C22.6106 4.8054 22.6541 4.5019 22.8953 4.33811Z"
        fill="#16171C"
      />
    </Svg>
  )
}
