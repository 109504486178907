import {
  ConversionCreateRequestModel,
  ConversionDetailModel,
  ConversionListModel,
  ConversionRequestModel,
  HistoryModel,
  Pageable,
} from 'src/@types'

import axios from 'src/api/axios'

const baseURL = '/conversions'

const conversions = {
  createConversion: (payload: ConversionCreateRequestModel) =>
    axios.post<ConversionDetailModel>(baseURL, payload),
  getConversions: (params?: ConversionRequestModel) =>
    axios.get<Pageable<ConversionListModel>>(baseURL, { params }),
  getConversionById: (id: string) =>
    axios.get<ConversionDetailModel>(`${baseURL}/${id}`),
  getConversionsHistories: (id: string) =>
    axios.get<Array<HistoryModel>>(`${baseURL}/${id}/histories`),
}

export default conversions
