/* eslint-disable promise/prefer-await-to-then */
import {
  ClientModel,
  ClientRequestModel,
  Pageable,
  AccountType,
  ClientSummary,
  AccountInfo,
  WalletModel,
  ClientStatus,
  MemberShipTier,
  RiskRating,
  ClientUpdateRequestModel,
  TransactionSearchRequestModel,
  ClientTransactionModel,
  ClientRecipientRequestModel,
  RecipientModel,
  ClientRecipientDetailsModel,
  ClientRecipientComplianceUpdateModel,
  BusinessAccountSearchModel,
  BusinessAccountUser,
  DocumentRequestModel,
  DocumentModel,
  DocumentCreateForm,
  PersonnelCreateForm,
  TransactionDetailsSearchRequestModel,
  ClientTransactionDetailsModel,
  ClientRecipientCreateForm,
  ClientNoteModel,
  ClientGlobalAccountRequestModel,
  GlobalAccountModel,
  ClientNoteRequestModel,
  UpdateAccountBalanceType,
  ClientMyInfoModel,
  ClientHistoriesModel,
  ClientHistoriesRequestModel,
  DepositBankModel,
  ClientBusinessAccountUsage,
  DocumentEditForm,
  PersonnelUpdateStatusForm,
  ClientBusinessAccountUsageEditModel,
  ClientUpdateStatusModel,
  PersonnelVerificationEmailModel,
  ClientSenderRequestModel,
  SenderModel,
  CreatePartnerBalanceModel,
  PersonnelVerificationLinkModel,
} from 'src/@types'
import _upperFirst from 'lodash/upperFirst'
import axios from 'src/api/axios'

const baseURL = '/clients'

// ToDo: All Suspended condition have to remove after backend updated the enum
const clients = {
  getClients: (params?: ClientRequestModel) =>
    axios.get<Pageable<ClientModel>>(baseURL, { params }).then(res => {
      const items = res.items.map(i => {
        let { status } = i
        if (status === 'Subspended') {
          status = 'Suspended'
        }
        return { ...i, status: status as ClientStatus }
      })
      return { ...res, items }
    }),
  getClientsSummary: (accountType: AccountType = 'Personal') =>
    axios.get<ClientSummary>(
      `${baseURL}/:getSummary?accountType=${accountType}`
    ),
  approveClient: (id: string) => axios.put(`${baseURL}/${id}/status/Approved`),
  rejectClient: (id: string) => axios.put(`${baseURL}/${id}/status/Rejected`),
  getClient: (id: string) =>
    axios
      .get<AccountInfo>(`${baseURL}/${id}?&timeStamp=${new Date().getTime()}`)
      .then(res => {
        let { status } = res
        if (status === 'Subspended') {
          status = 'Suspended'
        }
        return { ...res, status } as AccountInfo
      }),
  getWallets: (accountId: string) =>
    axios.get<WalletModel>(`${baseURL}/wallets/${accountId}`),
  updateClientStatus: (id: string, model: ClientUpdateStatusModel) => {
    let clientStatus = model.accountStatus.toString()
    if (clientStatus === 'Suspended') {
      clientStatus = 'Subspended'
    }
    return axios.put(`${baseURL}/${id}/status`, {
      ...model,
      accountStatus: clientStatus,
    })
  },
  updateClientMembershipTier: (id: string, status: MemberShipTier) =>
    axios.put(`${baseURL}/${id}/membership-tier/${_upperFirst(status)}`),
  updateClientRiskRating: (id: string, riskRating: RiskRating) =>
    axios.put(`${baseURL}/${id}/compliance`, {
      complianceRiskRating: riskRating,
    }),
  updateClient: (model: ClientUpdateRequestModel) =>
    axios.put(`${baseURL}/${model.id}`, model),
  getTransactions: (params: TransactionSearchRequestModel) =>
    axios.get<Pageable<ClientTransactionModel>>(
      `${baseURL}/wallets/${params.accountId}/transactions`,
      {
        params,
      }
    ),
  getTransactionDetails: (params: TransactionDetailsSearchRequestModel) => {
    const defaultParams: TransactionDetailsSearchRequestModel = {
      ...params,
    }
    delete defaultParams.accountId
    return axios.get<ClientTransactionDetailsModel>(
      `${baseURL}/wallets/${params.accountId}/transactions-detail`,
      {
        params: defaultParams,
      }
    )
  },
  getClientRecipients: (params: ClientRecipientRequestModel) =>
    axios.get<Pageable<RecipientModel>>(`${baseURL}/recipient`, { params }),
  getClientSenders: (params: ClientSenderRequestModel) =>
    axios.get<Pageable<SenderModel>>(`${baseURL}/sender`, { params }),
  getClientRecipientById: (accountId: string, recipientId: string) =>
    axios.get<ClientRecipientDetailsModel>(
      `${baseURL}/recipient/${accountId}/${recipientId}`
    ),
  updateClientRecipientRisk: (
    accountId: string,
    recipientId: string,
    params: ClientRecipientComplianceUpdateModel
  ) =>
    axios.put(`${baseURL}/${accountId}/recipient/${recipientId}/compliance`, {
      ...params,
    }),
  createClientRecipient: (
    accountId: string,
    payload: ClientRecipientCreateForm
  ) => axios.post(`${baseURL}/recipient/${accountId}`, payload),
  updateClientRecipient: (
    accountId: string,
    recipientId: string,
    payload: ClientRecipientCreateForm
  ) => axios.put(`${baseURL}/recipient/${accountId}/${recipientId}`, payload),
  deleteClientRecipient: (accountId: string, recipientId: string) =>
    axios.delete(`${baseURL}/recipient/${accountId}/${recipientId}`),
  getAccountUsers: (params: BusinessAccountSearchModel) =>
    axios.get<Pageable<BusinessAccountUser>>(`${baseURL}/accountUsers`, {
      params,
    }),
  getClientDocuments: (accountId: string, params: DocumentRequestModel) =>
    axios.get<Pageable<DocumentModel>>(`${baseURL}/document/${accountId}`, {
      params,
    }),
  deleteClientDocuments: (id: string, documentId: string) =>
    axios.delete(`${baseURL}/document/${id}`, { params: { documentId } }),
  createDocument: (accountId: string, payload: DocumentCreateForm) =>
    axios.post<DocumentCreateForm>(`${baseURL}/document/${accountId}`, payload),
  updateDocument: (accountId: string, payload: DocumentEditForm) =>
    axios.put<DocumentModel>(
      `${baseURL}/document/${payload.id}/${accountId}`,
      payload
    ),
  createPersonnel: (accountId: string, payload: PersonnelCreateForm) =>
    axios.post(`${baseURL}/${accountId}/personnel`, payload),
  deletePersonnel: (accountId: string, personnelId: string) =>
    axios.delete(`${baseURL}/${accountId}/personnel/${personnelId}`),
  sendPersonnelVerificationEmail: (
    accountId: string,
    personnelId: string,
    payload: PersonnelVerificationEmailModel
  ) =>
    axios.post(
      `${baseURL}/${accountId}/personnel/${personnelId}/:sendEmail`,
      payload
    ),
  updatePersonnel: (
    accountId: string,
    personnelId: string,
    payload: PersonnelCreateForm
  ) => axios.put(`${baseURL}/${accountId}/personnel/${personnelId}`, payload),
  updatePersonnelStatus: (
    accountId: string,
    personnelId: string,
    payload: PersonnelUpdateStatusForm
  ) =>
    axios.put(
      `${baseURL}/${accountId}/personnel/${personnelId}/:updateStatus`,
      payload
    ),
  generateVerificationLink: (accountId: string, personnelId: string) =>
    axios.post<PersonnelVerificationLinkModel>(
      `${baseURL}/${accountId}/personnel/${personnelId}/:generatePersonnelVerificationLink`
    ),
  markAsVerificationCompleted: (accountId: string, personnelId: string) =>
    axios.put<string>(
      `${baseURL}/${accountId}/personnel/${personnelId}/:markComplianceVerifyStatusComplete`
    ),
  createNote: (accountId: string, note: string) =>
    axios.post<ClientNoteModel>(`${baseURL}/${accountId}/notes`, {
      note,
    }),
  getClientNotes: (id: string, params: ClientNoteRequestModel) =>
    axios.get<Pageable<ClientNoteModel>>(`${baseURL}/${id}/notes`, {
      params,
    }),
  getClientGlobalAccount: (params: ClientGlobalAccountRequestModel) =>
    axios.get<Pageable<GlobalAccountModel>>(
      `${baseURL}/${params.accountId}/global-accounts`,
      {
        params,
      }
    ),
  createGlobaAaccountNote: (accountId: string, note: string) =>
    axios.post(`${baseURL}/global-account/note/${accountId}`, {
      note,
    }),
  switchBalanceType: (payload: UpdateAccountBalanceType) =>
    axios.put(`${baseURL}/:updateBalanceType`, payload),
  getClientMyInfo: (id: string) =>
    axios.get<ClientMyInfoModel>(`${baseURL}/${id}/myInfo`),
  getClientHistoryChange: ({ id, ...params }: ClientHistoriesRequestModel) =>
    axios.get<Pageable<ClientHistoriesModel>>(
      `${baseURL}/${id}:histories?timeStamp=${new Date().getTime()}`,
      {
        params,
      }
    ),
  createPartnerBalance: (payload: CreatePartnerBalanceModel) =>
    axios.post(`${baseURL}/${payload.accountId}:createPremiumBalance`, {
      ...payload,
    }),
  createDepositBank: (accountId: string, payload: DepositBankModel) =>
    axios.post(`${baseURL}/${accountId}/depositBanks`, payload),
  getAccountUsage: (accountId: string) =>
    axios.get<ClientBusinessAccountUsage>(
      `${baseURL}/${accountId}:accountUsage`
    ),
  updateAccountUsage: (
    accountId: string,
    payload: ClientBusinessAccountUsageEditModel
  ) =>
    axios.put<ClientBusinessAccountUsageEditModel>(
      `${baseURL}/${accountId}:updateAccountUsage`,
      payload
    ),
  generateBoardResolution: (clientId: string) =>
    axios.get<Blob>(`${baseURL}/${clientId}:generateBoardResolutionPdf`),
}

export default clients
