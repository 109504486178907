/* eslint-disable */

import {
  Pageable,
  TransferModel,
  TransferRequestModel,
  UpdateTransferModel,
  UpdatePayoutInformation,
  PayoutConfirmationModel,
  TransferDetailsModel,
  TransferRejectModel,
  TransferBulkDetailsModel,
  TransferSendBlackBoxModel,
  TransactionComplianceDetailsModel,
  NoteCreateModel,
  NotesRequestModel,
  NoteModel,
  HistoryModel,
  NoteRemoveModel,
} from 'src/@types'
import axios from 'src/api/axios'

const baseURL = '/transferBulks'

const transferBulks = {
  getTransferBulks: (params?: TransferRequestModel) =>
    axios.get<Pageable<TransferModel>>(`${baseURL}`, { params }),
  getTransferBulkDetails: async (id: string) => {
    const response = await axios.get<TransferBulkDetailsModel>(
      `${baseURL}/${id}`
    )

    const { transferBulkNumber, transferView } = response || {}

    const newResponse = {
      ...transferView,
      ...response,
      transferNumber: transferBulkNumber,
    }
    return newResponse as TransferDetailsModel
  },
  getComplianceDetails: (id: string) =>
    axios.get<TransactionComplianceDetailsModel>(
      `${baseURL}/${id}:getComplianceDetail`
    ),
  updateTransferBulk: (id: string, payload: UpdateTransferModel) =>
    axios.put(`${baseURL}/${id}`, payload),
  approveCompliance: (id: string, remark?: string) =>
    axios.put(`${baseURL}/${id}:approveCompliance`, { remark }),
  rejectTransferBulk: (payload: TransferRejectModel) =>
    axios.put<TransferDetailsModel>(`${baseURL}/${payload.id}:reject`, {
      reason: payload.reason,
      rejectionCode: payload.rejectionCode,
    }),
  sendToBlackBox: (payload: TransferSendBlackBoxModel) =>
    axios.put(`${baseURL}/${payload.id}:sendToBlackBox`, payload),
  updatePayoutInformation: (id: string, payload: UpdatePayoutInformation) =>
    axios.put(`${baseURL}/${id}:updatePayoutInformation`, {
      models: payload.models,
    }),
  submitPayout: (id: string, payload: PayoutConfirmationModel) =>
    axios.put(`${baseURL}/${id}:submitPayout`, { models: payload.models }),
  cancelManualPayout: (id: string, reason?: string) =>
    axios.put(`${baseURL}/${id}:cancelManual`, {
      reason,
    }),
  completePayout: (id: string, reason?: string) =>
    axios.put(`${baseURL}/${id}:complete`, { reason }),
  createNotes: (payload: NoteCreateModel) =>
    axios.post(`${baseURL}/attachments`, {
      ...payload,
      transactionId: undefined,
      transferBulkId: payload.transactionId,
    }),
  removeNotes: (payload: NoteRemoveModel) =>
    axios.post(`${baseURL}/attachments:remove`, {
      ...payload,
      transactionId: undefined,
      transferBulkId: payload.transactionId,
    }),
  getNotes: (params: NotesRequestModel) =>
    axios.get<Pageable<NoteModel>>(`${baseURL}/attachments`, {
      params: {
        pageSize: params.pageSize,
        pageIndex: params.pageIndex,
        transferBulkId: params.transactionId,
      },
    }),
  getHistories: (id: string) =>
    axios.get<Array<HistoryModel>>(`${baseURL}/${id}:activities`),
}

export default transferBulks
