import { CustomerType, SelectOptionType } from 'src/@types'

export const ORDER_TYPE = {
  ASCENDING: 'Asc',
  DESCENDING: 'Desc',
}

export const CREATING_SOURCE = {
  Manual: 'Manual',
  Customer: 'Customer',
}

export const EURO_COUNTRIES = [
  'AT',
  'BE',
  'BG',
  'CH',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FR',
  'GB',
  'GR',
  'HR',
  'HU',
  'IE',
  'IS',
  'IT',
  'LI',
  'LT',
  'LU',
  'LV',
  'MC',
  'MT',
  'NL',
  'NO',
  'PL',
  'PT',
  'RO',
  'SE',
  'SI',
  'SK',
  'SM',
]

export const CNH_COUNTRIES = ['CN', 'HK', 'SG']

export const PREMIUM_WALLET_CURRENCIES = [
  'SGD',
  'USD',
  'AUD',
  'CAD',
  'CHF',
  'EUR',
  'GBP',
  'HKD',
  'JPY',
  'NZD',
  'SEK',
  'THB',
]

export const NA = '_'

export const DEBOUNCE_WAIT_MS = 500

export const DEBOUNCE_QUOTATION_WAIT_MS = 1000

export const MAX_32BIT_INT = 2147483647

export const IDEMPOTENCY_KEY = 'idempotency-key'

export const UK_VIRTUAL_ACCOUNT_CURRENCY = 'GBP'

export const UK_COUNTRY_CODES = ['UK', 'GB']

export const NOTES_MAX_LENGTH = 250

export const BASE_UK_COUNTRY_CODE = 'GB'

export const INDONESIA_COUNTRY_CODE = 'ID'
export const SINGAPORE_COUNTRY_CODE = 'SG'

export const BASE_NIUM_COUNTRY_CODE = 'SG'

export const BASE_HK_COUNTRY_CODE = 'HK'

export const BASE_NIUM_CURRENCY_CODE = 'SGD'

export const OTHER_COUNTRY_CODE = 'OTHERS'
export const OTHER_COUNTRY_NAME = 'Others'
export const OTHER_COUNTRY_ID = 'OT'

export const BASE_PREMIUM_COUNTRY_CURRENCY_CODES = {
  NIUM: {
    COUNTRY: 'SG',
    CURRENCY: 'SGD',
  },
  DBS: {
    COUNTRY: 'HK',
    CURRENCY: 'HKD',
  },
  BCUK: {
    COUNTRY: 'GB',
    CURRENCY: 'GBP',
  },
}

export const UK_SALUTATION_OPTIONS = [
  {
    label: 'Mr',
    value: 'Mr',
  },
  {
    label: 'Mrs',
    value: 'Mrs',
  },
  {
    label: 'Miss',
    value: 'Miss',
  },
  {
    label: 'Ms',
    value: 'Ms',
  },
  {
    label: 'Dr',
    value: 'Dr',
  },
  {
    label: 'Prof',
    value: 'Prof',
  },
  {
    label: 'Rev',
    value: 'Rev',
  },
  {
    label: 'Sir',
    value: 'Sir',
  },
  {
    label: 'Lord',
    value: 'Lord',
  },
  {
    label: 'Lady',
    value: 'Lady',
  },
]

export const UK_POSTCODE_REGEX_VALIDATION =
  /^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i

export const MAX_LENGTH_ADDRESS_CITY_CHARACTERS = 150

export const ALPHANUMERIC_REGEX = /^[a-z0-9\s]+$/i

export const MAX_TRANSACTION_TAGS = 4
export const THUNES_PARTNER = 'SG-SGD-THUNES'

export const WEBSITE_REGEX =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/

export const ADDRESS_LINE_REGEX = /^[^!$%*=<>;{}^"]*$/
export const ADDRESS_MAX_LENGTH = 100

export const CUSTOMER_TYPE_OPTIONS: Array<SelectOptionType<CustomerType>> = [
  {
    label: 'Individual',
    value: 'Personal',
  },
  {
    label: 'Business',
    value: 'Business',
  },
  {
    label: 'MTO',
    value: 'MTO',
  },
]
