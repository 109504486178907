import { CardStatus } from '@transwap/ui/App/Shared'

const renderComplianceStatus = (value: string) => {
  const label = value.toUpperCase()
  let status: CardStatus = 'draft'
  switch (value) {
    case 'Pending':
      break
    case 'Approved':
      status = 'active'
      break
    case 'Rejected':
      status = 'expired'
      break
    default:
      break
  }
  return {
    label,
    status,
  }
}

export default renderComplianceStatus
