import { CardStatus } from '@transwap/ui/App/Shared'

const renderPaymentStatus = (value: string) => {
  let label = value
  let status: CardStatus = 'draft'
  switch (value) {
    case 'Awaiting_Fund':
      label = 'Awaiting fund'
      break
    case 'Pending_Payout':
      label = 'Pending Payout'
      break
    case 'Payout_Inprogess':
      label = 'Payout In Progress'
      status = 'info'
      break
    case 'Pending_Compliance':
      label = 'Pending Compliance'
      break
    case 'Refund':
      status = 'info'
      break
    case 'Paid':
      status = 'active'
      break
    case 'Rejected':
      status = 'expired'
      break
    case 'Cancelled':
    case 'Expired':
      status = 'cancelled'
      break
    case 'Pending_Reconcile':
      label = 'Pending Reconcile'
      break
    default:
      break
  }
  return {
    label,
    status,
  }
}

export default renderPaymentStatus
