import Svg, { SvgProps } from '@transwap/ui/Core/Icons/Svg'

const GlobalConvert = ({
  classes,
  fontSize,
  border,
  borderProps,
  color,
}: SvgProps) => {
  return (
    <Svg
      fontSize={fontSize}
      classes={classes}
      border={border}
      borderProps={borderProps}
      color={color}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/Svg"
    >
      <circle
        cx="16"
        cy="16.0001"
        r="9.2"
        stroke="currentColor"
        strokeWidth="1.6"
      />
      <path
        d="M21.7142 9.70369C21.7142 9.70369 20.7323 11.0257 19.8095 11.5026C18.6559 12.0988 17.4455 11.1132 16.4761 11.9524C15.8631 12.4831 15.5237 12.9659 15.5237 13.7513C15.5237 14.5368 16.2744 14.7882 16.4761 15.5502C16.7016 16.4022 15.8186 17.178 16.4761 17.7989C16.892 18.1917 17.9047 18.2487 17.9047 18.2487C17.9047 18.2487 19.4224 18.1695 20.2856 17.7989C20.896 17.5369 21.0581 17.0299 21.7142 16.8995C22.6942 16.7046 24.2498 18.141 24.2498 18.141M14.726 7.1886L14.0952 9.70369L11.7142 11.0529L9.48789 10.5219M7.90469 13.3016L11.238 14.6508C11.238 14.6508 11.0113 15.7806 11.238 16.4497C11.4424 17.0528 12.0565 17.1784 12.1904 17.7989C12.3436 18.5085 11.8065 18.879 11.7142 19.5979C11.5338 21.0036 12.1904 23.6455 12.1904 23.6455M19.0117 23.8553L17.9047 22.2963C17.5872 22.2963 16.7618 22.2963 15.9999 22.2963C15.238 22.2963 15.8371 24.208 16.1546 24.8076"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.49994 19.0001C2.02095 22.4653 4.48715 27.2817 8.65354 28.5665L13 29.8968M13 29.8968L11.5665 27.4632M13 29.8968L10.0871 31.0001"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.9273 12.9996C30.4487 9.30134 28.1118 5.1345 24.6313 3.64515L21 2.10207M21 2.10207L22.1306 4.74759M21 2.10207L23.5006 0.999634"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default GlobalConvert
