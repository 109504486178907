import { AxiosError } from 'axios'
import { QueryFunction, QueryKey, useQuery, UseQueryOptions } from 'react-query'
import _isEmpty from 'lodash/isEmpty'
import useTransformQueryKey from './useTransformQueryKey'

const getErrorMessages = (error: AxiosError) => {
  const { status, data } = error.response || {}
  let errorMessages = 'Something went wrong! Please try again.'
  if (status === 400) {
    if (data.errorMessage) {
      errorMessages = data.errorMessage
    }
    if (!_isEmpty(data.errorDetails)) {
      errorMessages = ''
      const messages = Object.values(data.errorDetails)
      messages.forEach(mess => {
        errorMessages += `${mess} `
      })
    }
  }
  return errorMessages
}

export type UseTransQueryOptions<
  TQueryFnData = unknown,
  TError extends AxiosError = AxiosError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
> = UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>

function useTransQuery<
  TQueryFnData = unknown,
  TError extends AxiosError = AxiosError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options: UseTransQueryOptions<TQueryFnData, TError, TData, TQueryKey> = {}
) {
  const key = useTransformQueryKey(queryKey)

  const query = useQuery<TQueryFnData, TError, TData, TQueryKey>(
    key as TQueryKey,
    queryFn,
    {
      onError: error => {
        // TODO: will use toast notification to inform later
        console.log('###########', getErrorMessages(error))
      },
      ...options,
    }
  )

  return query
}

export default useTransQuery
