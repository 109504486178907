import Svg, { SvgProps } from '@transwap/ui/Core/Icons/Svg'

const WarningTriangle = ({
  classes,
  fontSize,
  border,
  borderProps,
  color,
}: SvgProps) => {
  return (
    <Svg
      fontSize={fontSize}
      classes={classes}
      border={border}
      borderProps={borderProps}
      color={color}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
    >
      <g>
        <path
          d="M3.8418 26L28.3592 26C29.9772 26 30.9255 24.1786 29.9976 22.8531L17.7389 5.34066C16.9427 4.20323 15.2582 4.20323 14.462 5.34066L2.20333 22.8531C1.27545 24.1786 2.22375 26 3.8418 26Z"
          fill="#E5B302"
        />
        <path
          d="M16.0005 11.999L16.0005 16.999"
          stroke="white"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <ellipse cx="16.0005" cy="21" rx="1" ry="1" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  )
}

export default WarningTriangle
