import { useEffect } from 'react'
import { useAuthContext } from 'src/auth/AuthContext'
import Loading from 'src/components/Loading'

const SilentRenew = () => {
  const { signinSilentCallback } = useAuthContext()
  useEffect(() => {
    signinSilentCallback().catch(e => {
      console.log('sigin silent callback got issues', e)
    })
  }, [signinSilentCallback])

  return <Loading />
}

export default SilentRenew
