import { CountryModel, Group, RoleTypes } from 'src/@types'
import useMasterData from 'src/hooks/useMasterData'
import useCountries from './useCountries'

export const checkRole = (groups: Group[], role: RoleTypes) =>
  groups?.some(g => g.roles.includes(role))

const useRoles = (countryCode?: string) => {
  const { profiles } = useMasterData()

  const { countriesLicenses = [] } = useCountries()

  const countries = [
    ...countriesLicenses,
    { code: 'GB', name: 'United Kingdom', countryId: 'UK', phonePrefix: '+44' },
  ]

  let isGLB = false
  let countryCodes: string[] = []
  let countriesWithContributor: string[] = []
  let isGLBApprover = false
  let isGLBContributor = false
  let isCountryApprover = false
  let isCountryContributor = false
  let isOtherContributor = false
  let countryOption: { value: string; label: string }[] = []

  profiles.roles?.forEach(r => {
    if (
      r.countryCode === 'GLB' &&
      !!r.groups.find(g => g.name.includes('RADIOMIR.')) // to ignore old group
    ) {
      isGLB = true
      isGLBApprover = checkRole(r.groups, 'APPROVERS')
      isGLBContributor = checkRole(r.groups, 'CONTRIBUTORS')
      if (isGLBContributor) {
        countriesWithContributor = [
          ...countriesWithContributor,
          ...countries.map(c => c.code),
        ]
      }
    } else if (
      r.countryCode === countryCode ||
      (countryCode === 'GB' && r.countryCode === 'UK')
    ) {
      isCountryApprover = checkRole(r.groups, 'APPROVERS')
      isCountryContributor = checkRole(r.groups, 'CONTRIBUTORS')
      if (isCountryContributor) {
        countriesWithContributor = [...countriesWithContributor, r.countryCode]
      }
    } else {
      isOtherContributor = checkRole(r.groups, 'CONTRIBUTORS')
      if (isOtherContributor) {
        countriesWithContributor = [...countriesWithContributor, r.countryCode]
      }
    }
    countryCodes = [...countryCodes, r.countryCode]
  })

  const isApprover = isGLBApprover || isCountryApprover
  const isContributor = isGLBContributor || isCountryContributor
  const isGenericContributor = isContributor || isOtherContributor
  const isShowFilter = countryCodes.length > 1 || isGLB

  const isBizWhale = profiles?.organizationName === 'BIZWHALE'

  if (isGLB) {
    countryOption = countries.map((ite: CountryModel) => ({
      value: ite.code,
      label: ite.name,
    }))
  } else {
    countryOption = countries
      ?.filter(c =>
        countryCodes.some(country => {
          if (c.code === 'GB') {
            return country === c.countryId
          }
          return country === c.code
        })
      )
      .map((ite: CountryModel) => ({
        value: ite.code,
        label: ite.name,
      }))
  }

  return {
    isGLB,
    isShowFilter,
    isApprover,
    isContributor,
    isGenericContributor,
    countryOption,
    countriesWithContributor: Array.from(new Set(countriesWithContributor)),
    isBizWhale,
  }
}

export default useRoles
