import { makeStyles, Grid } from '@material-ui/core'
import { Transwap } from '@transwap/ui/Core/Icons'
import { Button } from '@transwap/ui/Core/Input'
import { H2, Body } from '@transwap/ui/Core/DataDisplay/Typography'

const useStyles = makeStyles({
  mainContainer: {
    height: '100%',
  },
  transwapLogoContainer: {
    marginTop: 24,
    marginLeft: 24,
  },
})

export interface StatusPageProps {
  logo: string
  logoName: string
  heading: string
  bodyOne?: string
  bodyTwo?: string
}

const StatusPageTemplate = (props: StatusPageProps) => {
  const { logo, logoName, heading, bodyOne, bodyTwo } = props
  const classes = useStyles()

  return (
    <Grid
      container
      spacing={6}
      direction="row"
      justify="center"
      className={classes.mainContainer}
    >
      <Grid item xs={12}>
        <Grid container className={classes.transwapLogoContainer}>
          <Transwap variant="iconOnly" />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid alignItems="center" container direction="column" spacing={6}>
          <Grid item>
            <img src={logo} alt={logoName} />
          </Grid>

          <Grid item>
            <Grid container spacing={2} item>
              <Grid item container justify="center">
                <H2>{heading}</H2>
              </Grid>

              <Grid item container direction="column" alignItems="center">
                <Grid item>{bodyOne && <Body type="M">{bodyOne}</Body>}</Grid>
                <Grid item>{bodyTwo && <Body type="M">{bodyTwo}</Body>}</Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Button href="/">Go to dashboard</Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default StatusPageTemplate
