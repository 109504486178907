import axios from 'src/api/axios'
import {
  Pageable,
  ReferralsSummary,
  ReferralsRequestModel,
  ReferralsModel,
  UpdateReferralModel,
} from 'src/@types'

const baseURL = '/ReferralRewards'

const referrals = {
  getReferralsSummary: () =>
    axios.get<ReferralsSummary>(`${baseURL}/:getSummary`),
  getReferrals: (params?: ReferralsRequestModel) =>
    axios.get<Pageable<ReferralsModel>>(baseURL, { params }),
  getReferralDetails: (id: string) =>
    axios.get<ReferralsModel>(`${baseURL}/${id}`),
  approveReferral: (id: string, payload: UpdateReferralModel) =>
    axios.put(`${baseURL}/${id}:approve`, payload),
  rejectReferral: (id: string, payload: UpdateReferralModel) =>
    axios.put(`${baseURL}/${id}:reject`, payload),
}

export default referrals
