/* eslint-disable react/jsx-props-no-spreading */
import Svg, { SvgProps } from '@transwap/ui/Core/Icons/Svg'

export default (props: Omit<SvgProps, 'children'>) => {
  return (
    <Svg
      {...props}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.94971 14.5598V12.0684C8.94971 11.7219 9.09019 11.408 9.31744 11.1812C9.54427 10.954 9.85819 10.8135 10.2046 10.8135C10.8979 10.8135 11.4596 11.3755 11.4596 12.0684V12.6766"
        stroke="#494C57"
        strokeWidth="1.6"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4595 14.5598V12.6766C11.4595 12.3301 11.6 12.0166 11.8272 11.7894C12.054 11.5621 12.368 11.4216 12.7144 11.4216C13.4077 11.4216 13.9693 11.9836 13.9693 12.6766V13.6268"
        stroke="#494C57"
        strokeWidth="1.6"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.08256 17.5305L1.25818 15.0968C0.821307 14.5146 0.952557 13.6863 1.54834 13.2672C1.77381 13.1088 2.03256 13.0324 2.2899 13.0324C2.67428 13.0324 3.05349 13.2035 3.30709 13.5279L5.72209 16.6155C5.9574 16.916 6.43974 16.7496 6.43974 16.368V6.2552C6.43974 5.90879 6.58037 5.5952 6.80724 5.36786C7.04068 5.13395 7.36646 4.99192 7.72506 5.00036C8.41037 5.01676 8.94943 5.59473 8.94943 6.28051V12.0682"
        stroke="#494C57"
        strokeWidth="1.6"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.06641 18.8435L5.93625 21.3382C6.77578 22.458 8.09437 23.1175 9.49406 23.1175H12.2034C12.9581 23.1175 13.687 22.9258 14.3297 22.5761C14.9723 22.226 15.5292 21.7174 15.9384 21.0836C16.2919 20.5366 16.4798 19.8991 16.4798 19.248V17.5329V13.6258C16.4794 12.933 15.9178 12.3714 15.2245 12.3714C14.8777 12.3714 14.5641 12.5121 14.3367 12.7394C14.1098 12.9663 13.9692 13.2804 13.9692 13.6268V14.9022"
        stroke="#494C57"
        strokeWidth="1.6"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 4L16 5.33333L17 6"
        stroke="#494C57"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="16" cy="5" r="4" stroke="#494C57" strokeWidth="1.6" />
    </Svg>
  )
}
