import { Box } from '@material-ui/core'
import { SvgProps } from '@transwap/ui/Core/Icons/Svg'

const MeyzerLogo = ({ className }: SvgProps) => {
  return (
    <Box sx={{ display: 'flex' }} className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="70"
        height="20"
        viewBox="0 0 214 41"
        fill="none"
      >
        <path d="M5.86898 0H0V40.5736H5.86898V0Z" fill="#FEBE02" />
        <path d="M11.7379 0H5.8689V40.5736H11.7379V0Z" fill="#A21522" />
        <path
          d="M34.4128 0L29.85 0.034917L22.7794 27.2353L16.2138 0.034917L11.738 0V11.8543L18.6868 40.5736H26.3147L34.4128 8.86892V40.5736H42.4587V0H34.4128Z"
          fill="#001524"
        />
        <path
          d="M74.4855 32.8569H58.0628V23.761H72.9704V16.0793H58.0628V7.68174H73.8237V0H58.0628H48.7979V7.68174V16.0793V23.761V32.8569V40.5561V40.5736H58.0628V40.5561H74.4855V32.8569Z"
          fill="#001524"
        />
        <path
          d="M177.793 32.8744H161.371V23.761H176.278V16.0793H161.371V7.68174H177.114V0H161.371H152.088V7.68174V16.0793V23.761V32.8744V40.5561V40.5736H161.371V40.5561H177.793V32.8744Z"
          fill="#001524"
        />
        <path
          d="M78.2297 0.0344849H88.5048L95.1923 16.0788L101.775 0.0344849H112.329L99.9119 24.9128L99.7377 40.5557H90.525V24.8081L78.2297 0.0344849Z"
          fill="#001524"
        />
        <path
          d="M127.324 32.8565L146 5.7085V0.0344849H116.909L116.857 7.68131H134.238L114.994 35.4752V40.5557L146.028 40.5731V32.8565H127.324Z"
          fill="#001524"
        />
        <path
          d="M209.402 26.2047C208.183 22.6956 207.8 22.3115 207.173 21.5607C206.546 20.7926 206.128 20.9322 205.571 20.5132C205.763 20.6529 207.121 19.9197 207.347 19.7974C207.905 19.5181 208.427 19.1864 208.932 18.8198C209.977 18.0516 210.761 17.0914 211.266 15.8867C212.154 13.8092 212.467 11.1729 212.224 8.93825C211.562 2.68811 205.954 -0.10525 200.207 0.0693354C196.48 0.174086 192.753 0.104252 189.044 0.104252C187.355 0.104252 185.665 0.104252 183.958 0.104252V40.5731H193.311C193.311 40.5731 193.311 24.5462 193.311 24.5287C193.311 24.4763 195.592 24.5112 195.818 24.5287C196.707 24.5985 197.542 24.8429 198.274 25.3842C199.702 26.4491 200.207 27.8633 200.904 29.4345C201.496 30.7788 204.195 40.5731 204.195 40.5731H214.017C214 40.5556 210.621 29.7139 209.402 26.2047ZM202.053 15.1535C201.949 15.3106 201.827 15.4503 201.705 15.5899C200.869 16.4803 199.354 16.9168 198.152 16.9168H193.432V7.66378C193.432 7.66378 195.07 7.62887 197.612 7.66378C199.354 7.6987 201.583 8.43196 202.436 10.0905C203.185 11.557 203.011 13.7393 202.053 15.1535Z"
          fill="#001524"
        />
      </svg>
    </Box>
  )
}

export default MeyzerLogo
