import { SvgIconProps } from '@material-ui/core'

const SwitchToPremiumAccount = ({ width = 24, height = 24 }: SvgIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3076 21.9997C17.556 21.9997 20 22 19.9999 17.9997L19.9999 15.5997L19.9999 13.1997M19.9999 13.1997L17.6922 15.5997M19.9999 13.1997L22.3076 15.5997"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 2.01055C6.75166 2.01055 4.30769 1 4.30769 6.01055L4.30769 8.41055L4.30769 10.8105M4.30769 10.8105L6.61538 8.41055M4.30769 10.8105L2 8.41055"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7999 4.0002C16.7999 3.33745 17.3372 2.8002 17.9999 2.8002C18.6626 2.8002 19.1999 3.33745 19.1999 4.0002C19.1999 4.66294 18.6626 5.2002 17.9999 5.2002C17.3372 5.2002 16.7999 4.66294 16.7999 4.0002ZM17.9999 1.2002C16.4535 1.2002 15.1999 2.4538 15.1999 4.0002C15.1999 4.92518 15.6484 5.74541 16.3399 6.25525C14.8208 6.78408 13.6391 8.01913 13.2267 9.5706C13.1133 9.99761 13.3674 10.4358 13.7944 10.5492C14.2214 10.6627 14.6596 10.4086 14.7731 9.98155C15.1367 8.61332 16.4314 7.57607 17.9999 7.57607C19.5684 7.57607 20.8631 8.61332 21.2267 9.98155C21.3402 10.4086 21.7784 10.6627 22.2054 10.5492C22.6324 10.4358 22.8865 9.99761 22.7731 9.5706C22.3608 8.01913 21.179 6.78408 19.66 6.25525C20.3514 5.74541 20.7999 4.92518 20.7999 4.0002C20.7999 2.4538 19.5463 1.2002 17.9999 1.2002ZM3.7999 16.0002C3.7999 15.3375 4.33716 14.8002 4.9999 14.8002C5.66265 14.8002 6.1999 15.3375 6.1999 16.0002C6.1999 16.6629 5.66265 17.2002 4.9999 17.2002C4.33716 17.2002 3.7999 16.6629 3.7999 16.0002ZM4.9999 13.2002C3.45351 13.2002 2.1999 14.4538 2.1999 16.0002C2.1999 16.9252 2.64843 17.7454 3.33986 18.2552C1.82085 18.7841 0.639052 20.0191 0.226741 21.5706C0.113262 21.9976 0.367427 22.4358 0.794433 22.5492C1.22144 22.6627 1.65959 22.4086 1.77307 21.9815C2.13668 20.6133 3.43139 19.5761 4.9999 19.5761C6.56842 19.5761 7.86313 20.6133 8.22674 21.9815C8.34022 22.4086 8.77837 22.6627 9.20538 22.5492C9.63238 22.4358 9.88655 21.9976 9.77307 21.5706C9.36076 20.0191 8.17896 18.7841 6.65995 18.2552C7.35138 17.7454 7.7999 16.9252 7.7999 16.0002C7.7999 14.4538 6.5463 13.2002 4.9999 13.2002Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SwitchToPremiumAccount
