import _sortBy from 'lodash/sortBy'
import { useCallback, useMemo } from 'react'
import { CountryModel, SelectOption } from 'src/@types'
import { BASE_UK_COUNTRY_CODE, EURO_COUNTRIES } from 'src/constants/common'
import useMasterData from './useMasterData'

const useCountries = () => {
  const { countries, countriesLicenses } = useMasterData()

  const getCountry = useCallback(
    (code: string) => {
      const country = countries?.find(
        item => item.code.toLowerCase() === code.toLowerCase()
      )
      return country
    },
    [countries]
  )

  const getCountryByCurrency = useCallback(
    (currency?: string) => {
      if (!currency || !countries) return undefined
      return countries.find(f => f.code === currency.slice(0, 2))?.code
    },
    [countries]
  )

  const getCountryName = useCallback(
    (code?: string) => {
      if (!code) {
        return undefined
      }

      const country = getCountry(code)

      return country?.name
    },
    [getCountry]
  )
  // ToDo: remove when API to get destination countries based on ccy is ready
  const getDestinationCountries = useCallback(
    (currency?: string) => {
      let options: CountryModel[] | undefined = []

      switch (currency) {
        case 'USD':
          options = countries
          break
        case 'EUR':
          options = countries?.filter(
            c =>
              EURO_COUNTRIES.includes(c.code) ||
              c.code === 'SG' ||
              c.code === 'CN'
          )
          break
        case 'GBP':
          options = countries?.filter(c => c.code === 'GB' || c.code === 'SG')
          break
        case 'AUD':
          options = countries?.filter(
            c =>
              c.code === 'AU' ||
              c.code === 'SG' ||
              c.code === 'NZ' ||
              c.code === 'GB'
          )
          break
        case 'NZD':
          options = countries?.filter(
            c => c.code === 'NZ' || c.code === 'SG' || c.code === 'GB'
          )
          break
        case 'JPY':
          options = countries?.filter(
            c => c.code === 'JP' || c.code === 'SG' || c.code === 'GB'
          )
          break
        case 'CNH':
          options = countries?.filter(
            c =>
              c.code === 'CN' ||
              c.code === 'SG' ||
              c.code === 'HK' ||
              c.code === 'GB'
          )
          break
        case 'AED':
        case 'CAD':
        case 'CHF':
        case 'HKD':
        case 'PLN':
        case 'SEK':
        case 'SGD':
        case 'THB':
          options = countries?.filter(
            c => c.code === currency?.slice(0, 2) || c.code === 'GB'
          )
          break
        default:
          options = countries?.filter(c => c.code === currency?.slice(0, 2))
          break
      }
      return _sortBy(options, 'name')
    },
    [countries]
  )

  const countryOptions = useMemo(() => {
    if (!countries || !countries.length) return []
    return countries.map(
      c => ({ label: getCountryName(c.code), value: c.code } as SelectOption)
    )
  }, [countries, getCountryName])

  const legalCountryOptions = useMemo(() => {
    if (!countriesLicenses || !countriesLicenses.length) return []
    const options = countriesLicenses.map(c => ({
      label: getCountryName(c.code),
      value: c.code,
    }))

    return [
      ...options,
      ...[
        {
          label: getCountryName(BASE_UK_COUNTRY_CODE),
          value: BASE_UK_COUNTRY_CODE,
        },
      ],
    ]
  }, [getCountryName, countriesLicenses])

  const phonePrefixCountriesSorted = useMemo(
    () =>
      _sortBy(
        countries.filter(c => !!c.phonePrefix),
        'phonePrefix'
      ),
    [countries]
  )

  return {
    phonePrefixCountriesSorted,
    countries: _sortBy(countries, 'name'),
    countriesLicenses,
    getCountry,
    getCountryName,
    getDestinationCountries,
    countryOptions,
    legalCountryOptions,
    getCountryByCurrency,
  }
}

export default useCountries
