import { makeStyles, Theme } from '@material-ui/core/styles'
import { Transwap } from '@transwap/ui/Core/Icons'
import { BrandingItemProps } from '@transwap/ui/Core/Layout/__Elements/Sidebar/__Elements/BrandingItem/BrandingItem.types'
import clsx from 'clsx'
import useOrganizationQuery from 'src/hooks/useOrganizationQuery'
import { Definis, DefinisBrand } from 'src/components/Icons'
import { useMemo } from 'react'
import AppName from './AppName'

const useStyles = makeStyles<Theme, BrandingItemProps>(theme => ({
  item: ({ backgroundColor }) => ({
    backgroundColor: backgroundColor || theme.palette.secondary.main,
    paddingLeft: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
    },
    flexDirection: 'column',
    width: 'fit-content',
    height: 88,
  }),
  branding: {
    display: 'flex',
  },
  brandingIcon: {
    marginRight: theme.spacing(2),
  },
  show: {
    transform: 'translate3d(0px, 0, 0)',
    transition: 'all 300ms linear',
    opacity: 1,
  },
  hide: {
    opacity: 0,
    transform: 'translate3d(-25px, 0, 0)',
  },
  definisBrand: {
    width: 120,
    height: 35,
    marginLeft: -20,
  },
}))

const BrandingItem = (props: BrandingItemProps) => {
  const classes = useStyles(props)
  const { isDefinisOrganization } = useOrganizationQuery()
  const { appName, sidebarExpanded, LinkComponent = 'a' } = props
  const renderBrandIcon = useMemo(() => {
    if (!isDefinisOrganization) {
      return (
        <>
          <div className={classes.brandingIcon}>
            <Transwap fontSize="small" color="white" variant="iconOnly" />
          </div>

          <Transwap
            fontSize="small"
            color="white"
            variant="textOnly"
            className={clsx({
              [classes.show]: true,
              [classes.hide]: !sidebarExpanded,
            })}
          />
        </>
      )
    }
    return sidebarExpanded ? (
      <DefinisBrand className={classes.definisBrand} />
    ) : (
      <Definis />
    )
  }, [sidebarExpanded, isDefinisOrganization, classes])

  return (
    <LinkComponent className={classes.item} href="/">
      <div className={classes.branding}>{renderBrandIcon}</div>
      {appName && (
        <AppName
          classes={{
            root: clsx({
              [classes.show]: true,
              [classes.hide]: !sidebarExpanded,
            }),
          }}
        >
          {appName}
        </AppName>
      )}
    </LinkComponent>
  )
}

export default BrandingItem
