import Svg, { SvgProps } from '@transwap/ui/Core/Icons/Svg'

const PaymentMethod = ({
  classes,
  fontSize,
  border,
  borderProps,
  color,
}: SvgProps) => {
  return (
    <Svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fontSize={fontSize}
      classes={classes}
      border={border}
      borderProps={borderProps}
      color={color}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.56569 7.56569C9.8781 7.25327 9.8781 6.74673 9.56569 6.43431C9.25327 6.1219 8.74673 6.1219 8.43431 6.43431L9.56569 7.56569ZM3.43431 11.4343L2.86863 12L4 13.1314L4.56569 12.5657L3.43431 11.4343ZM8.43431 6.43431L3.43431 11.4343L4.56569 12.5657L9.56569 7.56569L8.43431 6.43431Z"
        fill="#16171C"
      />
      <circle cx="8" cy="8" r="7" stroke="#16171C" strokeWidth="1.6" />
      <path
        d="M6 6H10V10"
        stroke="#16171C"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default PaymentMethod
