export const DEPOSIT_URL = {
  LIST: '/deposits',
  ADD: '/deposits/new',
}

export const FUNDING_URL = {
  LIST: '/fundings',
  ADD: '/fundings/new',
}

export const REFUND_URL = {
  LIST: '/refunds',
  ADD: '/refunds/new',
}

export const DEBIT_CREDIT_NOTES_URL = {
  LIST: '/debit-credit-notes',
  ADD: '/debit-credit-notes/new/:type',
}

export const AGENT_URL = {
  LIST: '/agents',
  PROFILE: '/agent/profile/:tabIndex',
}

export const AGENT_CREATE_URL = {
  BRANCHES: '/agent/branches:tabIndex',
  BANK_NOTES_DENOMINATION: '/agent/bankNotesDenomination/:tabIndex',
  PARTNER_MAP: '/agent/partnerMap/:tabIndex',
  PROFILE: '/agent/profile/:tabIndex',
}

export const SETTLEMENT_URL = {
  LIST: '/settlements',
  ADD: '/settlements/new',
  DETAIL: '/settlements/:settlementId',
}

export const BANK_FEED_URL = {
  LIST: '/bank-feeds',
  ADD: '/bank-feeds/new',
  EDIT: '/bank-feeds/edit/:id',
}

export const CREDIT_FEED_URL = {
  LIST: '/credit-feeds',
}

export const NOSTRO_URL = {
  LIST: '/nostro',
  DETAILS: '/nostro/:id',
}

export const HOUSE_TRANSFER_URL = {
  LIST: '/house-transfers',
}

export const PARTNER_FEED_URL = {
  LIST: '/partner-feeds',
  CREATE: '/partner-feeds/new',
  EDIT: '/partner-feeds/edit/:id',
}

export const WITHDRAWAL_URL = {
  LIST: '/withdrawals',
  ADD: '/withdrawals/new',
}

export const BUSINESSES_URL = {
  LIST: '/clients/businesses',
  DETAILS: '/clients/businesses/:id',
  EDIT: '/clients/businesses/edit/:id',
  BALANCE_DETAILS: '/clients/businesses/:id/balance/:currency',
  GA_DETAILS: '/clients/businesses/:id/global-accounts/:gaId',
  CARD_DETAILS: '/clients/businesses/:id/cards/:cardId',
}

export const INDIVIDUALS_URL = {
  LIST: '/clients/individuals',
  DETAILS: '/clients/individuals/:id',
  EDIT: '/clients/individuals/edit/:id',
  BALANCE_DETAILS: '/clients/individuals/:id/balance/:currency',
  GA_DETAILS: '/clients/individuals/:id/global-accounts/:gaId',
  CARD_DETAILS: '/clients/individuals/:id/cards/:cardId',
}

export const MTO_URL = {
  LIST: '/clients/mtos',
  DETAILS: '/clients/mtos/:id',
  EDIT: '/clients/mtos/edit/:id',
  BALANCE_DETAILS: '/clients/mtos/:id/balance/:currency',
  GA_DETAILS: '/clients/mtos/:id/global-accounts/:gaId',
}

export const BANK_URL = {
  LIST: '/maintenances/bank',
  ADD: '/maintenances/bank/new',
  EDIT: '/maintenances/bank/edit',
}

export const FUND_SOURCE_URL = {
  LIST: '/maintenances/fund-source',
  ADD: '/maintenances/fund-source/new',
  EDIT: '/maintenances/fund-source/edit',
}

export const TRANSFER_PURPOSE_URL = {
  LIST: '/maintenances/transfer-purpose',
  ADD: '/maintenances/transfer-purpose/new',
  EDIT: '/maintenances/transfer-purpose/edit',
}

export const RULES_POLICIES_COMPLIANCE_URL = {
  LIST: '/rules-policies/compliance',
  DETAILS: '/rules-policies/compliance/:id',
}

export const RULES_POLICIES_ENABLE_AUTO_PAYOUT_URL = {
  LIST: '/rules-policies/api-payment-config',
}

export const RULES_POLICIES_FEE_CHARGES_URL = {
  LIST: '/rules-policies/fee-charges',
  DETAILS: '/rules-policies/fee-charges/:id',
}

export const COUPONS_MANAGEMENT_URL = {
  LIST: '/coupons-management',
}

export const REFERRALS_URL = {
  LIST: '/referrals',
}

export const AUTH_URL = {
  CALLBACK: '/authentication/callback',
  SIGNOUT: '/authentication/signout',
  LOGOUT: '/logout',
  SILENT_CALLBACK: '/authentication/silent_callback',
}

export const FEE_SCHEDULE_URL = {
  LIST: '/references/fee-schedules',
}

export const CUSTOMER_GROUP_URL = {
  LIST: '/references/customer-groups',
  DETAIL: '/references/customer-groups/:id',
}

export const PAYMENTS_RETAILS_URL = {
  LIST: '/payments/retails',
  NEW_TRANSACTION: '/payments/retails/new-transaction',
}

export const PAYMENTS_MTOS_URL = {
  LIST: '/payments/mtos',
}

export const PAYMENTS_TRANSFER_BULK_URL = {
  LIST: '/payments/transfer-bulks',
}

export const CONVERSION_URL = {
  LIST: '/conversions',
  CREATE: '/conversion/new',
}

export const CARD_MANAGEMENT_CARDS_URL = {
  LIST: '/card-management/cards',
  ADD: '/card-management/cards/new',
}

export const CARD_MANAGEMENT_BALANCES_URL = {
  LIST: '/card-management/balances',
}

export const CARD_MANAGEMENT_TRANSACTIONS_URL = {
  LIST: '/card-management/transactions',
}

export const RECONCILIATION_URL = {
  LIST: '/reconciliation',
}

export const COMPLIANCE_URL = {
  LIST: '/compliance',
  INTERNAL: '/compliance/internal',
  RFI: '/compliance/rfi',
}

export const GLOBAL_ACCOUNT_ACCOUNT_URL = {
  LIST: '/global-accounts/accounts',
}

export const GLOBAL_ACCOUNT_FEED_URL = {
  LIST: '/global-accounts/feeds',
  // Todo: will enable when Api already
  // ADD: '/global-account/feeds/new',
  // EDIT: '/global-account/feeds/edit/:id',
}

export const GLOBAL_ACCOUNT_COLLECTION_URL = {
  LIST: '/global-accounts/collections',
}

export const REPORT_URL = '/report'

export const DASHBOARD_URL = '/'

export const ACCESS_DENIED = '/access-denied'
