import {
  DepositModel,
  Pageable,
  ApproveDepositModel,
  RejectDepositModel,
  CreateDepositModel,
  DepositSummary,
  DepositRequestModel,
  DepositDetailsModel,
  HistoryModel,
  NoteCreateModel,
  NotesRequestModel,
  NoteModel,
  UpdateDeposits,
  DepositSelectListQuery,
  TransactionComplianceDetailsModel,
  DepositTypeRequest,
  ReconcileDepositModel,
  DepositFeeChargeTransactionRequestModel,
} from 'src/@types'
import axios from 'src/api/axios'

const baseURL = `/deposits`

const deposits = {
  getDeposits: (params: DepositRequestModel) =>
    axios.get<Pageable<DepositModel>>(baseURL, { params }),
  getDepositById: (id: string) =>
    axios.get<DepositDetailsModel>(`${baseURL}/${id}`),
  approveDeposit: (params: ApproveDepositModel) =>
    axios.put<DepositDetailsModel>(`${baseURL}/${params.id}:approve`, params),
  editRemarkDeposits: ({ id, remark }: UpdateDeposits) =>
    axios.put<DepositDetailsModel>(`${baseURL}/${id}`, {
      remark,
    }),
  rejectDeposit: (params: RejectDepositModel) =>
    axios.put<DepositDetailsModel>(`${baseURL}/${params.id}:reject`, params),
  createDeposit: (params: CreateDepositModel) =>
    axios.post<DepositDetailsModel>(baseURL, params),
  getDepositSummary: (depositType: DepositTypeRequest) =>
    axios.get<DepositSummary>(`${baseURL}/:getSummary`, {
      params: {
        depositType,
      },
    }),
  getDepositHistories: (id: string) =>
    axios.get<Array<HistoryModel>>(`${baseURL}/${id}:activities`),
  createNotes: (params: NoteCreateModel) =>
    axios.post(`${baseURL}/attachment`, params),
  getNotes: (params: NotesRequestModel) =>
    axios.get<Pageable<NoteModel>>(`${baseURL}/attachment`, { params }),
  sendToBlackBox: (payload: UpdateDeposits) =>
    axios.put<DepositDetailsModel>(
      `${baseURL}/${payload.id}:sendToBlackBox`,
      payload
    ),
  getSelectListValues: (params: DepositSelectListQuery) =>
    axios.get<Pageable<string>>(`${baseURL}/:getValues`, {
      params: {
        pageIndex: 0,
        pageSize: 200,
        ...params,
      },
    }),
  getComplianceDetail: (id: string) =>
    axios.get<TransactionComplianceDetailsModel>(
      `${baseURL}/${id}:getComplianceDetail`
    ),
  reconcileDeposit: (payload: ReconcileDepositModel) =>
    axios.put(`${baseURL}/${payload.id}:reconcile`, {
      feedIds: payload.feedIds,
    }),
  getDepositFeeChargeTransactions: (
    params: DepositFeeChargeTransactionRequestModel
  ) =>
    axios.get<Pageable<DepositModel>>(`${baseURL}/:getDepositFeeCharge`, {
      params,
    }),
}

export default deposits
