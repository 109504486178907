/* eslint-disable react/jsx-props-no-spreading */
import Svg, { SvgProps } from '@transwap/ui/Core/Icons/Svg'

export default (props: Omit<SvgProps, 'children'>) => {
  return (
    <Svg
      {...props}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="2" y="2" width="20" height="20" rx="2" fill="#E4E7F1" />
    </Svg>
  )
}
