import Svg, { SvgProps } from '@transwap/ui/Core/Icons/Svg'

const VerticalMoreAction = ({
  classes,
  fontSize,
  border,
  borderProps,
  color,
}: SvgProps) => {
  return (
    <Svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fontSize={fontSize}
      classes={classes}
      border={border}
      borderProps={borderProps}
      color={color}
    >
      <circle
        r="1"
        transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 12 5)"
        stroke="currentColor"
        strokeWidth="2"
      />
      <circle
        r="1"
        transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 12 12)"
        stroke="currentColor"
        strokeWidth="2"
      />
      <circle
        r="1"
        transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 12 19)"
        stroke="currentColor"
        strokeWidth="2"
      />
    </Svg>
  )
}

export default VerticalMoreAction
