import {
  BankFeedModel,
  BankFeedRequestModel,
  Pageable,
  BankFeedSummary,
  BankFeedUpdateModel,
  BankFeedCreateModel,
  BankFeedDetailModel,
  BankFeedIgnoreModel,
  HistoryModel,
  NoteCreateModel,
  NotesRequestModel,
  NoteModel,
  FeedTypes,
} from 'src/@types'

import axios from 'src/api/axios'

const baseURL = '/feeds'

const bankFeeds = {
  getBankfeeds: (params: BankFeedRequestModel) =>
    axios.get<Pageable<BankFeedModel>>(baseURL, {
      params,
    }),
  getBankFeedById: (id: string) =>
    axios.get<BankFeedDetailModel>(`${baseURL}/${id}`),
  updateBankFeedById: (params: BankFeedUpdateModel, id = '') =>
    axios.put<BankFeedDetailModel>(`${baseURL}/${id}`, params),
  getBankFeedSummary: (feedType: FeedTypes) =>
    axios.get<BankFeedSummary>(`${baseURL}/:getSummary?feedType=${feedType}`),
  createBankFeed: (params: BankFeedCreateModel) =>
    axios.post<BankFeedDetailModel>(baseURL, params),
  ignoreBankFeed: (params: BankFeedIgnoreModel) =>
    axios.put<BankFeedDetailModel>(`${baseURL}/${params.id}:ignore`, params),
  getBankFeedHistories: (id: string) =>
    axios.get<Array<HistoryModel>>(`${baseURL}/${id}:activities`),
  createNotes: (params: NoteCreateModel) =>
    axios.post(`${baseURL}/attachment`, params),
  getNotes: (params: NotesRequestModel) =>
    axios.get<Pageable<NoteModel>>(`${baseURL}/attachment`, { params }),
}

export default bankFeeds
