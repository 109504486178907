import { useEffect, useState } from 'react'
import { QueryKey, useIsFetching, useQueryClient } from 'react-query'
import { QueryFilters } from 'react-query/types/core/utils'
import _isEqual from 'lodash/isEqual'
import useTransformQueryKey from './useTransformQueryKey'

function useQueryDataListener<T>(
  queryKey: QueryKey,
  isOrganizationIncluded = true,
  filters?: QueryFilters
) {
  const [data, setData] = useState<T>()
  const key = useTransformQueryKey(queryKey, isOrganizationIncluded)
  const queryClient = useQueryClient()
  const numOfFetching = useIsFetching(key, filters)
  useEffect(() => {
    if (!numOfFetching) {
      const newData = queryClient.getQueryData<T>(key, filters)
      if (!_isEqual(data, newData)) {
        setData(newData)
      }
    }
  }, [numOfFetching, queryClient, data, key, filters])

  return data
}

export default useQueryDataListener
