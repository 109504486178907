import Svg, { SvgProps } from '@transwap/ui/Core/Icons/Svg'

const EditIcon = ({
  fontSize = 'default',
  width = 24,
  height = 24,
  className,
}: SvgProps) => {
  return (
    <Svg
      fontSize={fontSize}
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3509 3.65858C15.429 3.58047 15.5556 3.58047 15.6337 3.65858L18.5404 6.56527C18.6175 6.64234 18.6187 6.76692 18.5431 6.84542L10.1854 15.5245C10.1477 15.5637 10.0957 15.5858 10.0414 15.5858H6.8C6.68954 15.5858 6.6 15.4962 6.6 15.3858V12.4923C6.6 12.4393 6.62107 12.3884 6.65858 12.3509L15.3509 3.65858ZM16.7651 2.52721C16.0622 1.82426 14.9225 1.82426 14.2195 2.52721L5.52721 11.2195C5.18964 11.5571 5 12.0149 5 12.4923V15.3858C5 16.3799 5.80589 17.1858 6.8 17.1858H10.0414C10.5305 17.1858 10.9986 16.9867 11.3379 16.6343L19.6956 7.95525C20.376 7.2487 20.3654 6.12749 19.6718 5.4339L16.7651 2.52721ZM3.8 19.5858C3.35817 19.5858 3 19.944 3 20.3858C3 20.8276 3.35817 21.1858 3.8 21.1858H20.8C21.2418 21.1858 21.6 20.8276 21.6 20.3858C21.6 19.944 21.2418 19.5858 20.8 19.5858H3.8Z"
        fill="#16171C"
      />
    </Svg>
  )
}

export default EditIcon
