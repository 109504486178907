import Svg, { SvgProps } from '@transwap/ui/Core/Icons/Svg'

const Blackbox = ({
  classes,
  fontSize,
  border,
  borderProps,
  color,
}: SvgProps) => {
  return (
    <Svg
      fontSize={fontSize}
      classes={classes}
      border={border}
      borderProps={borderProps}
      color={color}
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M15 10.7142V13.5714C15 13.9503 14.8595 14.3136 14.6095 14.5815C14.3594 14.8494 14.0203 14.9999 13.6667 14.9999H4.33333C3.97971 14.9999 3.64057 14.8494 3.39052 14.5815C3.14048 14.3136 3 13.9503 3 13.5714V10.7142"
        stroke="white"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.57143 7.28566L9 10.7142L12.4286 7.28566"
        stroke="white"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 10.7142L9 2.99995"
        stroke="white"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default Blackbox
