import StatusPageTemplate from 'src/pages/StatusPage/StatusPageTemplate'
import pageNotFound from 'src/assets/images/StatusPage/PageNotFound.svg'

const PageNotFound = () => {
  return (
    <StatusPageTemplate
      logo={pageNotFound}
      logoName="page-not-found"
      heading="Sorry, page not found!"
      bodyOne="Sorry, we couldn't find the page you're looking for. Perhaps you've mistyped the URL? Be sure to check your spelling."
    />
  )
}

export default PageNotFound
