/* eslint-disable promise/prefer-await-to-then */
import {
  CreditFeedModel,
  CreditFeedRequestModel,
  CreditFeedSummary,
  HistoryModel,
  NoteCreateModel,
  NoteModel,
  NotesRequestModel,
  Pageable,
} from 'src/@types'

import axios from 'src/api/axios'

const baseURL = '/creditfeeds'

const creditFeeds = {
  getCreditFeedSummary: () =>
    axios.get<CreditFeedSummary>(`${baseURL}/:getSummary`),
  getCreditFeeds: (params: CreditFeedRequestModel) =>
    axios.get<Pageable<CreditFeedModel>>(baseURL, {
      params,
    }),
  getCreditFeedById: (id: string) =>
    axios.get<CreditFeedModel>(`${baseURL}/${id}`),
  getCreditFeedHistories: (id: string) =>
    axios
      .get<Pageable<HistoryModel>>(`${baseURL}/activities`, {
        params: {
          creditFeedId: id,
          pageIndex: 0,
          pageSize: 500,
        },
      })
      .then(res => res.items as Array<HistoryModel>),
  createNotes: (params: NoteCreateModel) =>
    axios.post(`${baseURL}/attachment`, params),
  getNotes: (params: NotesRequestModel) =>
    axios.get<Pageable<NoteModel>>(`${baseURL}/attachment`, { params }),
}

export default creditFeeds
