import { CardStatus } from '@transwap/ui/App/Shared'

const renderWithdrawalStatus = (value: string) => {
  let label = ''
  let status: CardStatus = 'draft'
  switch (value) {
    case 'Completed':
      label = 'Completed'
      status = 'active'
      break
    case 'Rejected':
      label = 'Rejected'
      status = 'expired'
      break
    case 'Pending_Approval':
      label = 'Pending Approval'
      status = 'draft'
      break
    case 'Pending_Payout':
      label = 'Pending Payout'
      status = 'draft'
      break
    case 'Payout_Inprogess':
      label = 'Payout In Progress'
      status = 'info'
      break
    case 'Pending_Reconciliation':
      label = 'Pending Reconciliation'
      status = 'draft'
      break
    default:
      break
  }
  return {
    label,
    status,
  }
}

export default renderWithdrawalStatus
