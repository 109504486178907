import Svg, { SvgProps } from '@transwap/ui/Core/Icons/Svg'

const Unlock = ({
  classes,
  fontSize,
  border,
  borderProps,
  color,
}: SvgProps) => {
  return (
    <Svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fontSize={fontSize}
      classes={classes}
      border={border}
      borderProps={borderProps}
      color={color}
    >
      <rect
        x="2.8"
        y="9.8"
        width="14.4"
        height="11.4"
        rx="1.2"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 9V7C14 4.79086 15.7909 3 18 3V3C20.2091 3 22 4.79086 22 7V9"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="10" cy="15" r="2" fill="currentColor" />
    </Svg>
  )
}

export default Unlock
