import { ProfileModel } from 'src/@types'

import axios from 'src/api/axios'

const profiles = {
  getProfiles: () => axios.get<ProfileModel>('/profiles'),

  logout: () => axios.post('/profiles/logout'),
}

export default profiles
