import { useEffect } from 'react'
import { useAuthContext } from 'src/auth/AuthContext'
import Loading from 'src/components/Loading'
import useRouter from 'src/hooks/useRouter'

const Callback = () => {
  const { signinRedirectCallback, clearStaleState, setHasAuthenticated } =
    useAuthContext()
  const router = useRouter()
  useEffect(() => {
    const handleSigninCallback = async () => {
      try {
        const user = await signinRedirectCallback()
        setHasAuthenticated(true)
        router.push(user.state)
      } catch {
        clearStaleState()
      }
    }
    handleSigninCallback()
  }, [signinRedirectCallback, clearStaleState, router, setHasAuthenticated])

  return <Loading />
}

export default Callback
