/* eslint-disable react/jsx-props-no-spreading */
import Svg, { SvgProps } from '@transwap/ui/Core/Icons/Svg'

export default (props: Omit<SvgProps, 'children'>) => {
  return (
    <Svg
      {...props}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.96255 10.0928H4.66667C3.19391 10.0928 2 11.2867 2 12.7594V27.4261C2 28.8989 3.19391 30.0928 4.66667 30.0928H21.3333C22.8061 30.0928 24 28.8989 24 27.4261V17.7594C24 16.2867 22.8061 15.0928 21.3333 15.0928H5C4.26362 15.0928 3.66667 14.4958 3.66667 13.7594V13.0927C3.66667 12.3564 4.26362 11.7594 5 11.7594H8.29043L8.96255 10.0928ZM3.5 27.4261V16.1636C3.93501 16.4356 4.44915 16.5928 5 16.5928H21.3333C21.9777 16.5928 22.5 17.1151 22.5 17.7594V27.4261C22.5 28.0704 21.9777 28.5928 21.3333 28.5928H4.66667C4.02233 28.5928 3.5 28.0704 3.5 27.4261Z"
        fill="#16171C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 19C18.8954 19 18 19.8954 18 21V22C18 23.1046 18.8954 24 20 24H23C24.1046 24 25 23.1046 25 22V21C25 19.8954 24.1046 19 23 19H20ZM20.5 23C21.3284 23 22 22.3284 22 21.5C22 20.6716 21.3284 20 20.5 20C19.6716 20 19 20.6716 19 21.5C19 22.3284 19.6716 23 20.5 23Z"
        fill="#16171C"
      />
      <mask
        id="mask0_27619_36527"
        maskUnits="userSpaceOnUse"
        x="5"
        y="0"
        width="21"
        height="15"
      >
        <path
          d="M10.7031 0L25.2884 5.3086L22.3345 14.0927L5.66781 14.0927L10.7031 0Z"
          fill="#C4C4C4"
        />
      </mask>
      <g mask="url(#mask0_27619_36527)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.2267 2.33829C14.8541 2.38405 14.5055 2.54659 14.2309 2.80263C13.9188 3.09372 13.7485 3.56152 13.408 4.49712L13.3403 4.6832L14.7498 5.19623L14.8175 5.01015C14.9963 4.51909 15.1006 4.23597 15.1932 4.03429C15.2322 3.94944 15.2579 3.90499 15.2699 3.88586C15.3045 3.85819 15.3453 3.83921 15.3887 3.83048C15.4111 3.83357 15.4617 3.84243 15.5517 3.86711C15.7657 3.92579 16.0497 4.02788 16.5407 4.20661L21.2183 5.90912C21.7094 6.08785 21.9925 6.19214 22.1942 6.28477C22.279 6.32375 22.3235 6.34948 22.3426 6.3615C22.3703 6.3961 22.3893 6.43683 22.398 6.48025C22.3949 6.50264 22.386 6.55324 22.3614 6.64328C22.3027 6.85732 22.2006 7.14125 22.0219 7.63231L17.5832 19.8274C17.4045 20.3185 17.3002 20.6016 17.2075 20.8033C17.1686 20.8881 17.1428 20.9326 17.1308 20.9517C17.0962 20.9794 17.0555 20.9984 17.0121 21.0071C16.9897 21.004 16.9391 20.9951 16.849 20.9705C16.635 20.9118 16.3511 20.8097 15.86 20.631L15.4823 20.4935L14.9692 21.903L15.347 22.0405C16.2826 22.381 16.7504 22.5513 17.174 22.4993C17.5466 22.4535 17.8952 22.291 18.1698 22.0349C18.4819 21.7439 18.6522 21.2761 18.9927 20.3405L23.4314 8.14534C23.7719 7.20973 23.9422 6.74193 23.8902 6.31831C23.8444 5.94567 23.6819 5.59709 23.4258 5.32252C23.1347 5.01037 22.6669 4.84011 21.7313 4.49958L17.0538 2.79708C16.1182 2.45654 15.6504 2.28628 15.2267 2.33829Z"
          fill="#16171C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.1276 7.65775L12.2458 5.88093C11.7547 5.7022 11.4708 5.6001 11.2568 5.54143C11.1667 5.51675 11.1161 5.50788 11.0938 5.5048C11.0503 5.51352 11.0096 5.53251 10.975 5.56017C10.963 5.57931 10.9373 5.62376 10.8983 5.70861C10.8056 5.91029 10.7013 6.19341 10.5226 6.68447L6.05018 18.9724C5.87145 19.4635 5.76935 19.7474 5.71068 19.9614C5.68599 20.0515 5.67713 20.1021 5.67404 20.1245C5.68277 20.1679 5.70176 20.2086 5.72942 20.2432C5.74855 20.2552 5.79301 20.281 5.87786 20.3199C6.07953 20.4126 6.36266 20.5169 6.85371 20.6956L11.7355 22.4724C12.2265 22.6511 12.5105 22.7532 12.7245 22.8119C12.8145 22.8366 12.8651 22.8455 12.8875 22.8485C12.931 22.8398 12.9717 22.8208 13.0063 22.7932C13.0183 22.774 13.044 22.7296 13.083 22.6447C13.1756 22.4431 13.2799 22.1599 13.4587 21.6689L17.9311 9.38094C18.1098 8.88988 18.2119 8.60596 18.2706 8.39192C18.2953 8.30187 18.3042 8.25128 18.3072 8.22889C18.2985 8.18546 18.2795 8.14473 18.2519 8.11014C18.2327 8.09811 18.1883 8.07238 18.1034 8.03341C17.9018 7.94077 17.6186 7.83648 17.1276 7.65775ZM10.9318 4.01261C11.3554 3.96059 11.8232 4.13086 12.7588 4.47139L17.6406 6.24821C18.5762 6.58874 19.044 6.75901 19.3351 7.07115C19.5911 7.34572 19.7537 7.69431 19.7994 8.06694C19.8514 8.49056 19.6812 8.95836 19.3406 9.89397L14.8682 22.1819C14.5277 23.1175 14.3574 23.5853 14.0453 23.8764C13.7707 24.1324 13.4221 24.295 13.0495 24.3407C12.6259 24.3927 12.158 24.2225 11.2224 23.8819L6.34068 22.1051C5.40508 21.7646 4.93728 21.5943 4.6462 21.2822C4.39016 21.0076 4.22761 20.659 4.18186 20.2864C4.12984 19.8628 4.30011 19.395 4.64064 18.4594L9.11308 6.17144C9.45361 5.23584 9.62388 4.76803 9.93602 4.47695C10.2106 4.22091 10.5592 4.05836 10.9318 4.01261Z"
          fill="#16171C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.6668 12.3933C15.5959 12.588 15.3805 12.6885 15.1857 12.6176L14.9687 12.5386L14.712 13.2439C14.6411 13.4387 14.4257 13.5391 14.2309 13.4682C14.0362 13.3973 13.9357 13.182 14.0066 12.9872L14.2634 12.2818L13.0697 11.8474L13.0302 11.9559C12.7684 12.6751 11.9732 13.0459 11.2541 12.7841C10.5349 12.5224 10.1641 11.7272 10.4258 11.008L10.4653 10.8995L10.2483 10.8205C10.0535 10.7496 9.95309 10.5342 10.024 10.3394C10.0949 10.1447 10.3102 10.0442 10.505 10.1151L10.7221 10.1941L10.9788 9.48878C11.0497 9.294 11.265 9.19357 11.4598 9.26447C11.6546 9.33536 11.755 9.55073 11.6841 9.7455L11.4274 10.4509L12.6211 10.8853L12.6606 10.7768C12.9223 10.0576 13.7175 9.68681 14.4367 9.94857C15.1559 10.2103 15.5267 11.0055 15.2649 11.7247L15.2254 11.8332L15.4425 11.9122C15.6372 11.9831 15.7377 12.1985 15.6668 12.3933ZM14.5201 11.5765L14.5596 11.468C14.6796 11.1384 14.5096 10.7739 14.18 10.6539C13.8504 10.5339 13.4859 10.7039 13.3659 11.0335L13.3264 11.142L14.5201 11.5765ZM12.3643 11.5907L11.1707 11.1562L11.1312 11.2647C11.0112 11.5943 11.1812 11.9588 11.5108 12.0788C11.8404 12.1988 12.2049 12.0288 12.3248 11.6992L12.3643 11.5907Z"
          fill="#16171C"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.5841 22.624C26.2394 22.3942 26.1463 21.9285 26.376 21.5839L29.0987 17.4999L26.376 13.4159C26.1463 13.0713 26.2394 12.6056 26.5841 12.3759C26.9287 12.1461 27.3944 12.2392 27.6241 12.5839L30.6241 17.0839C30.7921 17.3358 30.7921 17.664 30.6241 17.9159L27.6241 22.4159C27.3944 22.7606 26.9287 22.8537 26.5841 22.624Z"
        fill="#16171C"
      />
    </Svg>
  )
}
