import axios from 'src/api/axios'
import {
  FeedDetailModel,
  FeedModel,
  FeedSumary,
  FeedRequestModel,
  CreateFeedModel,
  EditFeedModel,
} from 'src/@types/models/gaFeeds'
import {
  HistoryModel,
  NoteCreateModel,
  NoteModel,
  NotesRequestModel,
  Pageable,
} from 'src/@types'

const baseUrl = '/globalAccounts/feeds'

const globalAccountFeeds = {
  getFeeds: (params: FeedRequestModel) =>
    axios.get<Pageable<FeedModel>>(baseUrl, { params }),
  getFeedDetails: (id: string) =>
    axios.get<FeedDetailModel>(`${baseUrl}/${id}`),
  getFeedSumary: () => axios.get<FeedSumary>(`${baseUrl}/:summary`),
  ignoreFeed: (id: string, remark: string) =>
    axios.put<FeedDetailModel>(`${baseUrl}/${id}:ignore`, { remark }),
  createFeed: (payload: CreateFeedModel) =>
    axios.post<FeedDetailModel>(baseUrl, payload),
  updateFeed: (payload: EditFeedModel) =>
    axios.put<FeedDetailModel>(`${baseUrl}/${payload.id}`, payload),
  getFeedHistory: (id: string) =>
    axios.get<Array<HistoryModel>>(`${baseUrl}/${id}:activities`),
  createFeedNote: (payload: NoteCreateModel) =>
    axios.post(`${baseUrl}/attachment`, payload),
  getFeedNotes: (params: NotesRequestModel) =>
    axios.get<Pageable<NoteModel>>(`${baseUrl}/attachment`, { params }),
}

export default globalAccountFeeds
