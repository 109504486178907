import Svg, { SvgProps } from '@transwap/ui/Core/Icons/Svg'

const Mailbox = ({
  classes,
  fontSize,
  border,
  borderProps,
  color,
}: SvgProps) => {
  return (
    <Svg
      fontSize={fontSize}
      classes={classes}
      border={border}
      borderProps={borderProps}
      color={color}
      viewBox="0 0 24 24"
      fill="none"
    >
      <rect
        x="3"
        y="5"
        width="18"
        height="14"
        rx="1"
        stroke="#16171C"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 8.5L12 12L21 8.5"
        stroke="#16171C"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 16L18 16"
        stroke="#16171C"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default Mailbox
