import Svg, { SvgProps } from '@transwap/ui/Core/Icons/Svg'

const Definis = ({
  width = 202,
  height = 202,
  fontSize = 'medium',
}: SvgProps) => {
  return (
    <Svg
      width={width}
      height={height}
      fontSize={fontSize}
      viewBox="0 0 202 202"
    >
      <rect width="202" height="202" rx="101" fill="inherit" />
      <path
        d="M63.687 54.8814L46 65.5L29.5 55.4068L99.4281 16L173.5 59.6102V78L99.4281 33.8644L78.1621 46.5L108.234 63.8136L93.2122 72.2203L63.687 54.8814Z"
        fill="url(#paint0_linear_2_19)"
      />
      <path
        d="M168 81L153 72L100.5 103L28.5 59.5V144.5L79 174L94.5 165L44 135.5V87.5L100.5 119.5V156.5L159 122.5V135.5L86 177.5L100.5 186L172.5 144.5V97.5L116.5 129V111L168 81Z"
        fill="url(#paint1_linear_2_19)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2_19"
          x1="46.5935"
          y1="51.2034"
          x2="173.084"
          y2="72.6682"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00494761" stopColor="#2C2BAE" />
          <stop offset="0.542579" stopColor="#7D61F8" />
          <stop offset="0.911458" stopColor="#4FA6FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2_19"
          x1="45.5935"
          y1="131.758"
          x2="174.819"
          y2="142.592"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00494761" stopColor="#2C2BAE" />
          <stop offset="0.542579" stopColor="#7D61F8" />
          <stop offset="0.911458" stopColor="#4FA6FF" />
        </linearGradient>
      </defs>
    </Svg>
  )
}

export default Definis
