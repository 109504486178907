import { Body, Dialog } from '@transwap/ui/Core/DataDisplay'
import { SearchField } from '@transwap/ui/Core/Input'
import useTransQuery from 'src/hooks/useTransQuery'
import globalSearch from 'src/api/endpoints/globalSearch'
import { useCallback, useState } from 'react'
import { GLOBAL_SEARCH } from 'src/constants/queryKeys'
import { makeStyles } from '@material-ui/core/styles'
import GlobalSearchContent from './GlobalSearchContent'

interface SearchDialogProps {
  open: boolean
  onClose: () => void
}

const useStyles = makeStyles(theme => ({
  title: {
    padding: 16,
  },
  content: {
    paddingTop: 0,
  },
  result: {
    marginTop: theme.spacing(2),
  },
}))

const SearchDialog = ({ open, onClose }: SearchDialogProps) => {
  const classes = useStyles()
  const [globalSearchText, setGlobalSearchText] = useState('')

  const { data: globalSearchItem, isLoading } = useTransQuery(
    [GLOBAL_SEARCH, globalSearchText],
    () => globalSearch.getSearchItems(globalSearchText),
    { enabled: Boolean(globalSearchText) }
  )

  const handleSearch = useCallback((searchText: string) => {
    setGlobalSearchText(searchText)
  }, [])

  const handleCloseDialog = useCallback(() => {
    if (onClose) {
      onClose()
    }
  }, [onClose])

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      maxWidth="lg"
      classes={{
        title: classes.title,
        content: classes.content,
      }}
    >
      <SearchField width={500} onSearch={handleSearch} />
      <div className={classes.result}>
        <Body type="XS" color="textSecondary">
          {globalSearchItem?.length || 0} Results found
        </Body>
        <GlobalSearchContent
          isLoading={isLoading}
          items={globalSearchItem || []}
          searchText={globalSearchText}
          handleCloseDialog={handleCloseDialog}
        />
      </div>
    </Dialog>
  )
}

export default SearchDialog
