import { PropsWithChildren } from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4, 3),
    // 4px for loading container
    paddingTop: `${theme.spacing(4) - 4}px`,
    flex: 1,
    overflow: 'auto',
  },
}))

const LayoutContentBody = ({ children }: PropsWithChildren<unknown>) => {
  const classes = useStyles()

  return <div className={classes.root}>{children}</div>
}
export default LayoutContentBody
