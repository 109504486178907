import { SidebarMenuItem } from '@transwap/ui/Core/Layout'
import * as urls from 'src/constants/urls'
import * as features from 'src/constants/features'
import {
  Dashboard,
  Report,
  Balance,
  Bank,
  Profile,
  Payment,
  Marketing,
  Agency,
  Setting,
  Compliance,
  Global,
  Policy,
  Conversion,
  Card,
} from '@transwap/ui/Core/Icons'
import Reconciliation from 'src/components/Icons/Reconciliation'
import FundFeeds from 'src/components/Icons/FundFeeds'

const menuItems: SidebarMenuItem[] = [
  // ...DASHBOARD_MENUS,
  {
    key: features.DASHBOARD,
    name: 'Dashboard',
    icon: <Dashboard />,
    url: urls.DASHBOARD_URL,
  }, // REPORT_MENUS
  {
    key: features.REPORT,
    name: 'Reports',
    icon: <Report />,
    url: urls.REPORT_URL,
  },
  // BALANCE_MENUS
  {
    key: features.BALANCE,
    name: 'Balances',
    icon: <Balance />,
    subMenuItems: [
      {
        key: features.BALANCE_DEPOSIT,
        name: 'Deposits',
        url: urls.DEPOSIT_URL.LIST,
      },
      {
        key: features.BALANCE_FUNDING,
        name: 'Funding',
        url: urls.FUNDING_URL.LIST,
      },
      {
        key: features.BALANCE_WITHDRAWAL,
        name: 'Withdrawals',
        url: urls.WITHDRAWAL_URL.LIST,
      },
      {
        key: features.BALANCE_REFUND,
        name: 'Refunds',
        url: urls.REFUND_URL.LIST,
      },
      {
        key: features.BALANCE_DEBIT_CREDIT_NOTES,
        name: 'Debit & Credit Notes',
        url: urls.DEBIT_CREDIT_NOTES_URL.LIST,
      },
    ],
  },
  // FUNDFEEDS_MENUS
  {
    key: features.FUNDFEEDS,
    name: 'Fund Feeds',
    icon: <FundFeeds />,
    subMenuItems: [
      {
        key: features.FUNDFEEDS_BANK_FEEDS,
        name: 'Bank Feeds',
        url: urls.BANK_FEED_URL.LIST,
      },
      {
        key: features.FUNDFEEDS_CREDITFEEDS,
        name: 'Credit Feeds',
        url: urls.CREDIT_FEED_URL.LIST,
      },
      {
        key: features.FUNDFEEDS_PARTNERFEEDS,
        name: 'Partner Feeds',
        url: urls.PARTNER_FEED_URL.LIST,
      },
    ],
  },
  {
    key: features.NOSTRO,
    name: 'Nostro',
    icon: <Bank />,
    subMenuItems: [
      {
        key: features.NOSTRO_NOSTROS,
        url: urls.NOSTRO_URL.LIST,
        name: 'Nostros',
      },
      {
        key: features.NOSTRO_HOUSE_TRANSFERS,
        url: urls.HOUSE_TRANSFER_URL.LIST,
        name: 'H2H Transfers',
      },
    ],
  },
  // CLIENT_MENUS
  {
    key: features.CLIENT,
    name: 'Clients',
    icon: <Profile />,
    subMenuItems: [
      {
        key: features.CLIENT_RETAILS,
        name: 'Retails',
        subMenuItems: [
          {
            key: features.CLIENT_INDIVIDUAL,
            name: 'Individuals',
            url: urls.INDIVIDUALS_URL.LIST,
          },
          {
            key: features.CLIENT_BUSINESS,
            name: 'Businesses',
            url: urls.BUSINESSES_URL.LIST,
          },
        ],
      },
      {
        key: features.CLIENT_MTO,
        name: 'MTOs',
        url: urls.MTO_URL.LIST,
      },
    ],
  },
  // GLOBAL ACCOUNTS
  {
    key: features.GLOBAL_ACCOUNT,
    name: 'Global Account',
    icon: <Global />,
    subMenuItems: [
      {
        key: features.GLOBAL_ACCOUNT_ACCOUNT,
        name: 'Accounts',
        url: urls.GLOBAL_ACCOUNT_ACCOUNT_URL.LIST,
      },
      {
        key: features.GLOBAL_ACCOUNT_FEED,
        name: 'GA Feeds',
        url: urls.GLOBAL_ACCOUNT_FEED_URL.LIST,
      },
      {
        key: features.GLOBAL_ACCOUNT_COLLECTION,
        name: 'GA Collections',
        url: urls.GLOBAL_ACCOUNT_COLLECTION_URL.LIST,
      },
    ],
  },
  // CONVERSIONS
  {
    key: features.CONVERSION,
    name: 'Conversions',
    icon: <Conversion />,
    url: urls.CONVERSION_URL.LIST,
  },

  // CARD_MANAGEMENT
  {
    key: features.CARD_MANAGEMENT,
    name: 'Card management',
    icon: <Card />,
    subMenuItems: [
      {
        key: features.CARD_MANAGEMENT_CARD,
        name: 'Cards',
        url: urls.CARD_MANAGEMENT_CARDS_URL.LIST,
      },
      {
        key: features.CARD_MANAGEMENT_BALANCE,
        name: 'Balances',
        url: urls.CARD_MANAGEMENT_BALANCES_URL.LIST,
      },
      {
        key: features.CARD_MANAGEMENT_TRANSACTION,
        name: 'Transactions',
        url: urls.CARD_MANAGEMENT_TRANSACTIONS_URL.LIST,
      },
    ],
  },

  // RECONCILIATION
  {
    key: features.RECONCILIATION,
    name: 'Reconciliation',
    icon: <Reconciliation />,
    url: urls.RECONCILIATION_URL.LIST,
  },
  // COMPLIANCE
  {
    key: features.COMPLIANCE,
    name: 'Compliance',
    icon: <Compliance />,
    subMenuItems: [
      {
        key: features.COMPLIANCE,
        name: 'Internal',
        url: urls.COMPLIANCE_URL.INTERNAL,
      },
      {
        key: features.COMPLIANCE,
        name: 'RFI (Nium)',
        url: urls.COMPLIANCE_URL.RFI,
      },
    ],
  },

  // PAYMENTS_RETAILS
  {
    key: features.PAYMENT,
    name: 'Payment',
    icon: <Payment />,
    subMenuItems: [
      {
        key: features.PAYMENT_RETAIL,
        name: 'Retails',
        url: urls.PAYMENTS_RETAILS_URL.LIST,
      },
      {
        key: features.PAYMENT_MTO,
        name: 'MTOs',
        url: urls.PAYMENTS_MTOS_URL.LIST,
      },
      {
        key: features.PAYMENT_TRANSFER_BULK,
        name: 'Transfer Bulks',
        url: urls.PAYMENTS_TRANSFER_BULK_URL.LIST,
      },
    ],
  },
  // AGENCY_MENUS
  {
    key: features.AGENCY,
    name: 'Agency',
    icon: <Agency />,
    subMenuItems: [
      {
        key: features.AGENCY_AGENT,
        name: 'Agents',
        url: urls.AGENT_URL.LIST,
      },
      {
        key: features.AGENCY_SETTLEMENT,
        name: 'Settlements',
        url: urls.SETTLEMENT_URL.LIST,
      },
    ],
  },
  {
    key: features.REFERENCE,
    name: 'References',
    icon: <Setting />,
    subMenuItems: [
      {
        key: features.FEE_SCHEDULE,
        name: 'Fee Schedules',
        url: urls.FEE_SCHEDULE_URL.LIST,
      },
      {
        key: features.CUSTOMER_GROUP,
        name: 'Customer Groups',
        url: urls.CUSTOMER_GROUP_URL.LIST,
      },
    ],
  },
  // MARKETING_MENUS
  {
    key: features.MARKETING,
    name: 'Marketing',
    icon: <Marketing />,
    subMenuItems: [
      {
        key: features.MARKETING_COUPONMANAGEMENT,
        name: 'Coupons Management',
        url: urls.COUPONS_MANAGEMENT_URL.LIST,
      },
      {
        key: features.MARKETING_REFERRALS,
        name: 'Referrals',
        url: urls.REFERRALS_URL.LIST,
      },
    ],
  },
  // MAINTENANCE_MENUS
  {
    key: features.MAINTENANCE,
    name: 'Maintenances',
    icon: <Setting />,
    subMenuItems: [
      {
        key: features.MAINTENANCE_BANK,
        name: 'Banks',
        url: urls.BANK_URL.LIST,
      },
      {
        key: features.MAINTENANCE_FUNDSOURCE,
        name: 'Fund Source',
        url: urls.FUND_SOURCE_URL.LIST,
      },
      {
        key: features.MAINTENANCE_TRANSFERPURPOSE,
        name: 'Transfer Purpose',
        url: urls.TRANSFER_PURPOSE_URL.LIST,
      },
    ],
  },
  {
    key: features.RULE_POLICY,
    name: 'Rules & Policies',
    icon: <Policy />,
    subMenuItems: [
      {
        key: features.RULE_POLICY_COMPLIANCE,
        name: 'Compliance Policies',
        url: urls.RULES_POLICIES_COMPLIANCE_URL.LIST,
      },
      {
        key: features.RULE_POLICY_ENABLE_AUTO_PAYOUT,
        name: 'API Payment',
        url: urls.RULES_POLICIES_ENABLE_AUTO_PAYOUT_URL.LIST,
      },
      {
        key: features.RULE_POLICY_FEE_CHARGES,
        name: 'Fees & Charges',
        url: urls.RULES_POLICIES_FEE_CHARGES_URL.LIST,
      },
    ],
  },
]

export default menuItems
