import { Transwap } from '@transwap/ui/Core/Icons'
import { ReactNode } from 'react'
import { Definis, MeyzerLogo, MeyzerM } from 'src/components/Icons'
import Organizations from 'src/constants/organizations'

export type OrganizationConfig = {
  [key in Organizations]: {
    sidebarColor?: string
    icon: ReactNode
    iconOnly?: ReactNode
    appName: string
    label: string
  }
}

export const organizationObject: OrganizationConfig = {
  [Organizations.TranSwap]: {
    icon: <Transwap fontSize="extraSmall" />,
    iconOnly: <Transwap fontSize="extraSmall" variant="iconOnly" />,
    appName: 'radiomir',
    label: 'TS SG',
  },
  [Organizations.UK]: {
    icon: <Transwap fontSize="extraSmall" />,
    iconOnly: <Transwap fontSize="extraSmall" variant="iconOnly" />,
    appName: 'radiomir',
    label: 'TS UK',
  },
  [Organizations.MeyzerSwap]: {
    icon: <MeyzerLogo />,
    iconOnly: <MeyzerM width={20} />,
    sidebarColor: '#001524',
    appName: 'meyzer',
    label: 'Meyzer',
  },
  [Organizations.Definis]: {
    icon: <Definis />,
    iconOnly: <Definis />,
    sidebarColor: '#001524',
    appName: 'definis',
    label: 'Definis',
  },
}

const organizationConfig = (org?: Organizations) => {
  switch (org) {
    case Organizations.MeyzerSwap:
      return organizationObject.MeyzerSwap
    case Organizations.Definis:
      return organizationObject.Definis
    case Organizations.UK:
      return organizationObject.UK
    default:
      return organizationObject.TranSwap
  }
}

export default organizationConfig
