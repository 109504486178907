import { BankAccount, CreateBankAccountModel } from 'src/@types'
import axios from 'src/api/axios'

const baseURL = '/BankAccounts'

const bankAccounts = {
  createBankAccount: (params: CreateBankAccountModel) =>
    axios.post<BankAccount>(baseURL, params),
}

export default bankAccounts
