/* eslint-disable no-param-reassign */
import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios'
import queryString from 'query-string'
import authService from 'src/auth/authService'
import env from 'src/env'
import getOrganizationQuery from 'src/utils/getOrganizationQuery'
import history from 'src/utils/router/history'
import { ACCESS_DENIED } from 'src/constants/urls'
import Organizations from 'src/constants/organizations'

interface ExtendedAxiosRequestConfig extends AxiosRequestConfig {
  retryCount: number
}

const instance = axios.create({
  baseURL: `${env.REACT_APP_RADIOMIR_API_URL}/${
    env.REACT_APP_API_VERSION || 'v1'
  }`,
})

const onRequest = async (config: AxiosRequestConfig) => {
  if (env.REACT_APP_ENV === 'automation') {
    config.headers.Authorization = `test`
    config.headers.CountryCode = `GLB`
  } else {
    const token = await authService.getAccessToken()
    config.headers.Authorization = token
    config.headers['transwap-api-key'] = env.REACT_APP_APIM_KEY
    config.headers.countryCode = 'GLB'
    const currentOrganization = getOrganizationQuery()

    if (!config.headers['ts-organization-name']) {
      config.headers['ts-organization-name'] = currentOrganization
    }
    // Todo: will enhance later when BE is ready support
    if (currentOrganization === Organizations.Definis) {
      config.headers['ts-organization-name'] = 'BizWhale'
    }
  }
  config.paramsSerializer = params =>
    queryString.stringify(params, { arrayFormat: 'index' })
  return config
}

const onRequestError = (error: AxiosError) => {
  return Promise.resolve(error)
}

const onResponse = (response: AxiosResponse) => {
  return response.data
}

const onResponseError = async (error: AxiosError) => {
  const status = error.response?.status
  const originalRequest = error.config as ExtendedAxiosRequestConfig
  const MAX_RETRIES = 3
  switch (status) {
    case 401:
      authService.signout()
      break
    case 403:
      history.push(ACCESS_DENIED)
      break
    case 429:
      if (!originalRequest.retryCount) {
        originalRequest.retryCount = 0
      }

      if (
        originalRequest.method?.toUpperCase() !== 'GET' &&
        originalRequest.retryCount < MAX_RETRIES
      ) {
        originalRequest.retryCount += 1

        await new Promise(resolve => setTimeout(resolve, 1000))

        return instance(originalRequest)
      }
      break
    default:
      break
  }

  return Promise.reject(error)
}

instance.interceptors.request.use(onRequest, onRequestError)
instance.interceptors.response.use(onResponse, onResponseError)

export default instance
