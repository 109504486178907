import { useCallback, useState, PropsWithChildren, useMemo } from 'react'
import { useLocation, Link } from 'react-router-dom'
import {
  Sidebar as LayoutSidebar,
  SidebarMenuItem,
} from '@transwap/ui/Core/Layout'
import { makeStyles } from '@material-ui/core/styles'
import { PROFILE } from 'src/constants/queryKeys'
import useQueryDataListener from 'src/hooks/useQueryDataListener'
import { Breadcrumb, ProfileModel } from 'src/@types'
import organizationConfig from 'src/utils/organizationConfig'
import * as features from 'src/constants/features'
import useOrganizationQuery from 'src/hooks/useOrganizationQuery'
import env from 'src/env'
import useRoles from 'src/hooks/useRoles'
import { getBizWhaleMenuItems } from 'src/utils/getBizWhaleRoleConfig'
import { getPenTestMenuItems, isPenTestAccount } from 'src/utils/pentest'
import defaultMenuItems from './menuItems'
import LayoutContent from './LayoutContent'
import LayoutContentBody from './LayoutContentBody'
import LayoutContentHeader from './LayoutContentHeader'
import BrandingItem from './BradingItem'

let initialSidebar = {
  isOpen: true,
  hasHoverEffects: false,
  hasFixedPosition: false,
}

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
    backgroundColor: theme.palette.grey[300],
  },
}))

export interface LayoutPrimaryProps {
  menuItems?: SidebarMenuItem[]
  bottomMenuItems?: SidebarMenuItem[]
  breadcrumbs: Breadcrumb[]
}
const LayoutPrimary = ({
  menuItems = defaultMenuItems,
  bottomMenuItems = [],
  children,
  breadcrumbs,
}: PropsWithChildren<LayoutPrimaryProps>) => {
  const {
    currentOrganization,
    appendOrganizationToUrl,
    isTranswapOrganization,
    isDefinisOrganization,
  } = useOrganizationQuery()

  const { isBizWhale } = useRoles()
  const profiles = useQueryDataListener<ProfileModel>(PROFILE, false)

  const filteredMenuItems = useMemo(() => {
    if (isPenTestAccount(profiles?.email)) {
      return getPenTestMenuItems()
    }
    let temp = isBizWhale ? getBizWhaleMenuItems() : menuItems
    if (!isTranswapOrganization)
      temp = temp.filter(f => !f.key.includes(features.REFERENCE))
    return temp
  }, [isBizWhale, menuItems, isTranswapOrganization, profiles?.email])

  const isEnabledFeature = useCallback(
    (menuItem: SidebarMenuItem) => {
      if (isDefinisOrganization && menuItem.key === features.CARD_MANAGEMENT) {
        return false
      }
      if (profiles && profiles.featureToggle) {
        const menuItemSettings = profiles.featureToggle.find(
          feature => feature.id === menuItem.key
        )

        return menuItemSettings && menuItemSettings.enabled
      }
      return false
    },
    [profiles, isDefinisOrganization]
  )

  const enabledMenuItems = useMemo(
    () =>
      filteredMenuItems.filter(isEnabledFeature).map(menuItem => {
        if (menuItem.subMenuItems) {
          const newMenuItem = { ...menuItem }
          newMenuItem.subMenuItems = menuItem.subMenuItems
            .filter(isEnabledFeature)
            .map(itemLevel2 => {
              if (itemLevel2.subMenuItems) {
                const newItemLevel2 = { ...itemLevel2 }
                newItemLevel2.subMenuItems = itemLevel2.subMenuItems
                  ?.filter(isEnabledFeature)
                  .map(itemLevel3 => {
                    return {
                      ...itemLevel3,
                      url: appendOrganizationToUrl(itemLevel3.url as string),
                    }
                  })
                return newItemLevel2
              }
              return {
                ...itemLevel2,
                url: appendOrganizationToUrl(itemLevel2.url as string),
              }
            })

          return newMenuItem
        }
        return {
          ...menuItem,
          url: appendOrganizationToUrl(menuItem.url as string),
        }
      }),
    [filteredMenuItems, appendOrganizationToUrl, isEnabledFeature]
  )

  const { pathname } = useLocation()

  const [sidebar, setSidebar] = useState(initialSidebar)

  const handleHeaderClick = useCallback(() => {
    if (sidebar.isOpen) {
      setSidebar(currentSidebarState => ({
        ...currentSidebarState,
        isOpen: false,
        hasHoverEffects: true,
      }))
    } else {
      setSidebar(currentSidebarState => ({
        ...currentSidebarState,
        hasFixedPosition: false,
        isOpen: true,
        hasHoverEffects: false,
      }))
    }
  }, [sidebar.isOpen])

  const handleMouseEnter = useCallback(() => {
    initialSidebar = sidebar
    if (sidebar.hasHoverEffects) {
      setSidebar(currentSidebarState => {
        const newSidebar = {
          ...currentSidebarState,
          hasFixedPosition: true,
          isOpen: true,
        }
        initialSidebar = newSidebar
        return newSidebar
      })
    }
  }, [sidebar])

  const handleMouseLeave = useCallback(() => {
    initialSidebar = sidebar
    if (sidebar.hasHoverEffects) {
      setSidebar(currentSidebarState => {
        const newSidebar = {
          ...currentSidebarState,
          hasFixedPosition: true,
          isOpen: false,
        }
        initialSidebar = newSidebar
        return newSidebar
      })
    }
  }, [sidebar])

  const classes = useStyles()

  const { sidebarColor, appName } = useMemo(() => {
    return organizationConfig(currentOrganization)
  }, [currentOrganization])

  return (
    <div
      className={classes.root}
      style={{
        display: 'flex',
        justifyContent: sidebar.hasFixedPosition ? 'flex-end' : 'space-between',
      }}
    >
      <LayoutSidebar
        activeUrl={appendOrganizationToUrl(pathname)}
        open={sidebar.isOpen}
        menuItems={enabledMenuItems}
        bottomMenuItems={bottomMenuItems}
        onMenuClick={() => {}}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        appName={appName}
        style={{
          position: sidebar.hasFixedPosition ? 'fixed' : 'static',
        }}
        LinkComponent={Link}
        backgroundColor={sidebarColor}
        envInfo={env.REACT_APP_ENV}
        version={env.REACT_APP_VERSION}
        BrandingItemComponent={BrandingItem}
      />
      <LayoutContent sidebarExpand={!sidebar.hasHoverEffects}>
        <LayoutContentHeader
          onToggleSidebarClick={handleHeaderClick}
          profiles={profiles}
          breadcrumbs={breadcrumbs}
        />
        <LayoutContentBody>{children}</LayoutContentBody>
      </LayoutContent>
    </div>
  )
}

export default LayoutPrimary
