// Organization query params
export const ORGANIZATION_QUERY = 'org'

enum Organizations {
  TranSwap = 'TranSwap',
  UK = 'UK',
  MeyzerSwap = 'MeyzerSwap',
  Definis = 'Definis',
}

export default Organizations
