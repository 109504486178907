/* eslint-disable react/jsx-props-no-spreading */
import { Suspense, useMemo, useEffect } from 'react'
import { Switch, Route as PublicRoute } from 'react-router-dom'
import routes from 'src/utils/router/routes'
import PrimaryLayout from 'src/layouts/primary'
import useBreadcrumbs from 'src/hooks/useBreadcrumbs'
import { ProfileModel } from 'src/@types'
import { PROFILE } from 'src/constants/queryKeys'
import useOrganizationQuery from 'src/hooks/useOrganizationQuery'
import useQueryDataListener from 'src/hooks/useQueryDataListener'
import * as features from 'src/constants/features'
import useRoles from 'src/hooks/useRoles'
import getOrganizationQuery from 'src/utils/getOrganizationQuery'
import Organizations from 'src/constants/organizations'
import { getBizWhaleRoutes } from 'src/utils/getBizWhaleRoleConfig'
import { getPenTestRoutes, isPenTestAccount } from 'src/utils/pentest'
import ProtectedRoute from './ProtectedRoute'
import PageRenderer from './PageRenderer'

const RouteRenderer = () => {
  const { getRouteBreadcrumb } = useBreadcrumbs()
  const profiles = useQueryDataListener<ProfileModel>(PROFILE, false)

  const currentOrgFromUrl = getOrganizationQuery()

  const { isDefinisOrganization, setOrganization, isTranswapOrganization } =
    useOrganizationQuery()

  const { isBizWhale } = useRoles()

  const filteredRoutes = useMemo(() => {
    if (isPenTestAccount(profiles?.email)) {
      return getPenTestRoutes()
    }
    return isBizWhale ? getBizWhaleRoutes() : routes
  }, [isBizWhale, profiles?.email])

  const renderedRoutes = useMemo(
    () =>
      filteredRoutes
        .map(route => ({
          exact: true,
          layout: PrimaryLayout,
          layoutProps: {
            breadcrumbs: getRouteBreadcrumb(route.id),
          },
          ...route,
        }))
        .map(route => {
          const currentFeature = profiles?.featureToggle?.filter(
            f => route.featureId.includes(f.id) && f.id !== features.TRANSFER
          )

          const disabledFeature = currentFeature?.find(c => {
            return (
              (c.id.includes(features.CARD_MANAGEMENT) &&
                isDefinisOrganization) ||
              (!isTranswapOrganization && c.id.includes(features.REFERENCE)) ||
              !c.enabled
            )
          })

          const Route =
            route.isPublic && !disabledFeature ? PublicRoute : ProtectedRoute

          return (
            <Route
              isEnabledFeature={!disabledFeature}
              key={route.path}
              exact={route.exact}
              path={route.path}
              isCreate={route.isCreate}
            >
              <route.layout {...route.layoutProps}>
                <Suspense fallback={null}>
                  <PageRenderer Component={route.component} />
                </Suspense>
              </route.layout>
            </Route>
          )
        }),
    [
      getRouteBreadcrumb,
      profiles,
      isDefinisOrganization,
      filteredRoutes,
      isTranswapOrganization,
    ]
  )

  useEffect(() => {
    if (isBizWhale && currentOrgFromUrl !== Organizations.Definis) {
      setOrganization(Organizations.Definis)
    }
  }, [isBizWhale, currentOrgFromUrl, setOrganization])

  return <Switch>{renderedRoutes}</Switch>
}

export default RouteRenderer
