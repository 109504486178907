import Svg, { SvgProps } from '@transwap/ui/Core/Icons/Svg'

const Clear = ({ classes, fontSize, border, borderProps, color }: SvgProps) => {
  return (
    <Svg
      fontSize={fontSize}
      classes={classes}
      border={border}
      borderProps={borderProps}
      color={color}
    >
      <circle cx="12" cy="12" r="12" fill="currentColor" />
      <path
        d="M8,8 L16,16 M8,16 L16,8"
        stroke="white"
        strokeWidth="1.06667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default Clear
