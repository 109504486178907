import { IBalanceAccount, Pageable } from 'src/@types'
import {
  ICreateHouseTransferRequestModel,
  IHouseTransferBankAccountModel,
  IHouseTransferBankAccountRequestModel,
  IHouseTransferModel,
  IHouseTransferRequestModel,
} from 'src/@types/models/houseTransfers'
import axios from '../axios'

const baseUrl = '/houseTransfers'

export default {
  getTransactions: (params: IHouseTransferRequestModel) =>
    axios.get<Pageable<IHouseTransferModel>>(baseUrl, { params }),
  createTransaction: (payload: ICreateHouseTransferRequestModel) =>
    axios.post(baseUrl, payload),
  getBankAccounts: (params?: IHouseTransferBankAccountRequestModel) =>
    axios.get<Array<IHouseTransferBankAccountModel>>(
      `${baseUrl}/:getBankAccounts`,
      {
        params,
      }
    ),
  getBalanceAccounts: () =>
    axios.get<Array<IBalanceAccount>>(`${baseUrl}/:getBalanceAccounts`),
  getListCreatedBy: () =>
    axios.get<Array<string>>(`${baseUrl}/:getListCreatedBy`),
}
