import ProgressIndicator from '@transwap/ui/Core/DataDisplay/Progress/ProgressIndicator'
import useStyles from './Loading.styles'

export interface LoadingProps {
  show?: boolean
}
const Loading = ({ show = true }: LoadingProps) => {
  const classes = useStyles()

  return show ? (
    <div className={classes.progress}>
      <ProgressIndicator />
    </div>
  ) : null
}

export default Loading
