import {
  Divider,
  Grid,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
} from '@material-ui/core'
import { Body, Tooltip } from '@transwap/ui/Core/DataDisplay'
import ProgressBar from '@transwap/ui/Core/DataDisplay/Progress/ProgressBar'
import { Profile, Signout } from '@transwap/ui/Core/Icons'
import GlobalSearch from 'src/components/GlobalSearch'
import { IconButton } from '@transwap/ui/Core/Input'
import { Header } from '@transwap/ui/Core/Layout'
import { useCallback, useMemo, useState } from 'react'
import { useIsFetching } from 'react-query'
import { Link } from 'react-router-dom'
import { Breadcrumb, ProfileModel } from 'src/@types'
import { useAuthContext } from 'src/auth'
import Organizations from 'src/constants/organizations'
import organizationConfig, {
  OrganizationConfig,
  organizationObject,
} from 'src/utils/organizationConfig'
import clsx from 'clsx'
import useOrganizationQuery from 'src/hooks/useOrganizationQuery'
import useRoles from 'src/hooks/useRoles'
import { isPenTestAccount } from 'src/utils/pentest'
import useProfile from 'src/hooks/useProfile'

interface LayoutContentHeaderProps {
  onToggleSidebarClick: () => void
  profiles?: ProfileModel
  breadcrumbs?: Breadcrumb[]
}

const useStyles = makeStyles(theme => ({
  loadingContainer: {
    height: 4,
  },
  signoutButton: {
    background: theme.palette.error[50],
  },
  menuContainer: {
    padding: theme.spacing(1),
  },
  menuItem: {
    gap: theme.spacing(2),
    borderRadius: theme.spacing(1),
    padding: theme.spacing(0, 2),
    minWidth: 176,
    '&:hover': {
      background: theme.palette.grey[400],
    },
  },
  notSwitchedOrg: {
    '&:hover': {
      background: 'transparent',
    },
    cursor: 'default',
  },
  selectedOrg: {
    background: theme.palette.grey[400],
  },
  menuItemText: {
    padding: theme.spacing(1, 1),
    margin: 0,
  },
  organizationContainer: {
    margin: theme.spacing(1, 0),
  },
  dividerRoot: {
    margin: theme.spacing(1),
  },
}))

let initCollapse = false
const LayoutContentHeader = ({
  onToggleSidebarClick,
  profiles,
  breadcrumbs,
}: LayoutContentHeaderProps) => {
  const [collapse, setCollapse] = useState(initCollapse)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const numOfFetching = useIsFetching()
  const classes = useStyles()
  const { signout } = useAuthContext()
  const { profileLogout } = useProfile()
  const { currentOrganization, setOrganization } = useOrganizationQuery()
  const { isBizWhale } = useRoles()

  const handleSignout = () => {
    profileLogout()
    signout()
  }

  const handleToggle = useCallback(() => {
    setCollapse(col => {
      initCollapse = !col
      return initCollapse
    })
    onToggleSidebarClick()
  }, [onToggleSidebarClick])

  const handleProfileIconClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(current => {
        if (current) return null
        return event.currentTarget
      })
    },
    []
  )

  const handleOrgChange = useCallback(
    (val: Organizations) => {
      if (!isBizWhale) {
        setOrganization(val)
      }
    },
    [setOrganization, isBizWhale]
  )

  const orgIcon = useMemo(() => {
    if (isBizWhale) {
      return organizationConfig(Organizations.Definis).iconOnly
    }
    return organizationConfig(currentOrganization).iconOnly
  }, [currentOrganization, isBizWhale])

  const filteredOrigizationConfig = useMemo(() => {
    if (isPenTestAccount(profiles?.email)) {
      return {
        [Organizations.TranSwap]: organizationObject.TranSwap,
      } as OrganizationConfig
    }

    // Remove MeyzerSwap & Definis for now
    return {
      [Organizations.TranSwap]: organizationObject.TranSwap,
      [Organizations.UK]: organizationObject.UK,
    } as OrganizationConfig
  }, [profiles?.email])

  const isSingleOrg = Object.keys(filteredOrigizationConfig).length === 1

  return (
    <>
      <Header
        username={profiles?.name}
        role={
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Tooltip title={currentOrganization || ''}>
                <div>{orgIcon}</div>
              </Tooltip>
            </Grid>
            <Grid item>BASIC</Grid>
          </Grid>
        }
        onToggleSidebarClick={handleToggle}
        isSidebarCollapse={collapse}
        breadcrumbs={breadcrumbs}
        heading={
          breadcrumbs ? breadcrumbs[breadcrumbs?.length - 1].heading : ''
        }
        LinkComponent={Link}
        actionsComponents={[
          <IconButton
            shape="circle"
            onClick={handleProfileIconClick}
            iconColor="black"
          >
            <Profile />
            <Menu
              open={!!anchorEl}
              getContentAnchorEl={null}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 55,
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
            >
              <div className={classes.menuContainer}>
                <MenuItem
                  onClick={handleSignout}
                  classes={{ root: classes.menuItem }}
                >
                  <IconButton
                    size="M"
                    shape="circle"
                    className={classes.signoutButton}
                    iconColor="error"
                  >
                    <Signout />
                  </IconButton>
                  <Body type="MM">Logout</Body>
                </MenuItem>
                {!isSingleOrg && (
                  <>
                    <Divider classes={{ root: classes.dividerRoot }} />
                    <ListItemText classes={{ root: classes.menuItemText }}>
                      <Body type="LB">Organization</Body>
                    </ListItemText>
                    {Object.keys(filteredOrigizationConfig).map(key => (
                      <MenuItem
                        disabled={[
                          Organizations.MeyzerSwap,
                          Organizations.Definis,
                        ].includes(key as keyof OrganizationConfig)}
                        onClick={() => handleOrgChange(key as Organizations)}
                        classes={{
                          root: clsx(
                            classes.menuItem,
                            classes.organizationContainer,
                            {
                              [classes.selectedOrg]:
                                currentOrganization === key,
                              [classes.notSwitchedOrg]: isBizWhale,
                            }
                          ),
                        }}
                      >
                        <IconButton size="M" shape="circle">
                          {
                            filteredOrigizationConfig[
                              key as keyof OrganizationConfig
                            ].iconOnly
                          }
                        </IconButton>
                        <Body type="MM">
                          {
                            filteredOrigizationConfig[
                              key as keyof OrganizationConfig
                            ].label
                          }
                        </Body>
                      </MenuItem>
                    ))}
                  </>
                )}
              </div>
            </Menu>
          </IconButton>,
          <GlobalSearch />,
        ]}
      />
      <div className={classes.loadingContainer}>
        {numOfFetching > 0 && <ProgressBar />}
      </div>
    </>
  )
}

LayoutContentHeader.defaultProps = {
  profiles: {},
  breadcrumbs: undefined,
}

export default LayoutContentHeader
