import { Grid, makeStyles } from '@material-ui/core'
import { Body } from '@transwap/ui/Core/DataDisplay'
import { ConversionStatus } from 'src/@types'
import { GlobalSearchItemType } from 'src/@types/models/globalSearch'
import { NA } from 'src/constants/common'
import renderBankFeedStatus from 'src/pages/FundFeeds/BankFeeds/utils/renderBankFeedStatus'
import renderCreditFeedStatus from 'src/pages/FundFeeds/CreditFeeds/utils/renderCreditFeedStatus'
import renderDepositStatus from 'src/pages/Balances/Deposits/utils/renderDepositStatus'
import renderRefundStatus from 'src/pages/Balances/Refunds/utils/renderRefundStatus'
import renderWithdrawalStatus from 'src/pages/Balances/Withdrawals/utils/renderWithdrawalStatus'
import renderClientStatus from 'src/pages/Clients/ultis/renderClientStatus'
import renderComplianceStatus from 'src/pages/Compliances/utils/renderComplianceStatus'
import renderConversionStatus from 'src/pages/Conversions/utils/renderConversionStatus'
import renderGAFeedStatus from 'src/pages/GlobalAccounts/Feeds/utils/renderGAFeedStatus'
import TransactionStatusCard from 'src/pages/Payments/components/TransactionStatusCard'
import renderPaymentStatus from 'src/pages/Payments/utils/renderPaymentStatus'

interface GlobalSearchDescriptionProps {
  type?: GlobalSearchItemType
  itemStatus: string
  transactionNumber?: string
}

const useStyles = makeStyles(theme => ({
  item: {
    borderRight: `1px solid ${theme.palette.divider}`,
    borderLeft: `1px solid ${theme.palette.divider}`,
  },
  statusCard: {
    marginLeft: theme.spacing(1),
  },
}))

const getStatus = (type: GlobalSearchItemType, status: string) => {
  switch (type) {
    case 'Feed':
      return renderBankFeedStatus(status)
    case 'Deposit':
      return renderDepositStatus(status)
    case 'Withdrawal':
      return renderWithdrawalStatus(status)
    case 'GAFeed':
      return renderGAFeedStatus(status)
    case 'CreditFeed':
      return renderCreditFeedStatus(status)
    case 'Refund':
      return renderRefundStatus(status)
    case 'Personal':
      return renderClientStatus(status, 'Personal')
    case 'MTO':
    case 'Business':
      return renderClientStatus(status, 'Business')
    case 'Transfer':
    case 'TransferMTO':
      return renderPaymentStatus(status)
    case 'Compliance':
      return renderComplianceStatus(status)
    case 'Conversion':
    case 'SmartConversion':
      return renderConversionStatus(status as ConversionStatus)
    default:
      return renderBankFeedStatus(status)
  }
}

const GlobalSearchDescription = ({
  type,
  itemStatus,
  transactionNumber,
}: GlobalSearchDescriptionProps) => {
  const classes = useStyles()
  const { label, status } = getStatus(type || 'Feed', itemStatus)
  return (
    <Grid container spacing={1} justifyContent="center" alignItems="center">
      <Grid item xs={3}>
        <Body type="XS">{type || NA}</Body>
      </Grid>
      <Grid item xs={4} className={classes.item}>
        <Body align="center" type="XS">
          {transactionNumber || NA}
        </Body>
      </Grid>
      <Grid item xs={5}>
        <div className={classes.statusCard}>
          <TransactionStatusCard
            isBlackBox={itemStatus === 'Black_Box' || itemStatus === 'Blackbox'}
            label={label}
            status={status}
            size="large"
          />
        </div>
      </Grid>
    </Grid>
  )
}

GlobalSearchDescription.defaultProps = {
  type: 'Feed',
  transactionNumber: '',
}
export default GlobalSearchDescription
