import {
  CustomerGroupCreateModel,
  CustomerGroupDetailModel,
  CustomerGroupModel,
  CustomerGroupQueryModel,
  CustomerGroupToggleModel,
  CustomerGroupUpdateModel,
  Pageable,
} from 'src/@types'
import axiosWrapper from 'src/api/axios'

const baseURL = '/customerGroups'

const feeSchedules = {
  getCustomerGroups: (params: CustomerGroupQueryModel) =>
    axiosWrapper.get<Pageable<CustomerGroupModel>>(
      `${baseURL}/?&timestamp=${new Date().getTime()}`,
      { params }
    ),
  getCustomerGroupDetails: (id: string) =>
    axiosWrapper.get<CustomerGroupDetailModel>(
      `${baseURL}/${id}?&timestamp=${new Date().getTime()}`
    ),
  createCustomerGroup: (payload: CustomerGroupCreateModel) =>
    axiosWrapper.post<CustomerGroupDetailModel>(baseURL, payload),
  updateCustomerGroup: (payload: CustomerGroupUpdateModel) =>
    axiosWrapper.put<CustomerGroupDetailModel>(
      `${baseURL}/${payload.id}`,
      payload
    ),
  toggleCustomerGroup: (payload: CustomerGroupToggleModel) =>
    axiosWrapper.put<boolean>(`${baseURL}/toggle/${payload.id}`, payload),
}

export default feeSchedules
