import { PropsWithChildren } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { drawerOpenWidth, drawerCloseWidth } from '@transwap/ui/Core/Layout'

export interface LayoutContentProps extends PropsWithChildren<unknown> {
  sidebarExpand?: boolean
}
const useStyles = makeStyles<Theme, LayoutContentProps>(() => ({
  root: ({ sidebarExpand }) => {
    return {
      maxWidth: `calc(100% - ${
        sidebarExpand ? drawerOpenWidth : drawerCloseWidth
      }px)`,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    }
  },
}))

const LayoutContent = ({ children, sidebarExpand }: LayoutContentProps) => {
  const classes = useStyles({ sidebarExpand })
  return <div className={classes.root}>{children}</div>
}

export default LayoutContent
