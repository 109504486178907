import { ElementType } from 'react'

export interface PageRendererProps {
  Component: ElementType
}

const PageRenderer = ({ Component }: PageRendererProps) => {
  return <Component />
}

export default PageRenderer
