export const DASHBOARD = 'Dashboard'
export const NOTFOUND = 'NotFound'
export const REPORT = 'Report'
export const SETTINGS = 'Settings'
export const AUTH = 'Auth'
export const AUTH_CALLBACK = 'Auth.Callback'
export const AUTH_CALLBACK_LOGOUT = 'Auth.Callback.Logout'
export const AUTH_CALLBACK_SILENT = 'Auth.Callback.Silent'

export const BALANCE = 'Balance'
export const BALANCE_DEPOSIT = 'Balance.Deposit'
export const BALANCE_DEPOSIT_LIST = 'Balance.Deposit.List'
export const BALANCE_DEPOSIT_CREATE = 'Balance.Deposit.Create'
export const BALANCE_FUNDING = 'Balance.Funding'
export const BALANCE_FUNDING_LIST = 'Balance.Funding.List'
export const BALANCE_FUNDING_CREATE = 'Balance.Funding.Create'
export const BALANCE_WITHDRAWAL = 'Balance.Withdrawal'
export const BALANCE_WITHDRAWAL_LIST = 'Balance.Withdrawal.List'
export const BALANCE_WITHDRAWAL_CREATE = 'Balance.Withdrawal.Create'
export const BALANCE_REFUND = 'Balance.Refund'
export const BALANCE_REFUND_LIST = 'Balance.Refund.List'
export const BALANCE_REFUND_CREATE = 'Balance.Refund.Create'
export const BALANCE_DEBIT_CREDIT_NOTES = 'Balance.DebitCreditNotes'
export const BALANCE_DEBIT_CREDIT_NOTES_LIST = 'Balance.DebitCreditNotes.List'
export const BALANCE_DEBIT_CREDIT_NOTES_CREATE =
  'Balance.DebitCreditNotes.Create'

export const FUNDFEEDS = 'FundFeeds'
export const FUNDFEEDS_BANKFEED_CREATE = 'FundFeeds.Bankfeed.Create'
export const FUNDFEEDS_BANKFEED_EDIT = 'FundFeeds.Bankfeed.Edit'
export const FUNDFEEDS_CREDITFEED_LIST = 'FundFeeds.CreditFeed.List'
export const FUNDFEEDS_BANK_FEEDS = 'FundFeeds.BankFeeds'
export const FUNDFEEDS_CREDITFEEDS = 'FundFeeds.CreditFeeds'
export const FUNDFEEDS_PARTNERFEEDS = 'FundFeeds.PartnerFeeds'
export const FUNDFEEDS_PARTNERFEED_CREATE = 'FundFeeds.PartnerFeed.Create'
export const FUNDFEEDS_PARTNERFEED_EDIT = 'FundFeeds.PartnerFeed.Edit'

export const NOSTRO = 'Nostro'
export const NOSTRO_NOSTROS = 'Nostro.Nostros'
export const NOSTRO_LIST = 'Nostro.Nostros.List'
export const NOSTRO_DETAIL = 'Nostro.Nostros.Detail'

export const NOSTRO_HOUSE_TRANSFERS = 'Nostro.HouseTransfers'
export const NOSTRO_HOUSE_TRANSFERS_LIST = 'Nostro.HouseTransfers.List'

export const CLIENT = 'Client'
export const CLIENT_RETAILS = 'Client.Retails'
export const CLIENT_INDIVIDUAL = 'Client.Retails.Individual'
export const CLIENT_INDIVIDUAL_LIST = 'Client.Retails.Individual.List'
export const CLIENT_INDIVIDUAL_DETAIL = 'Client.Retails.Individual.Detail'
export const CLIENT_INDIVIDUAL_EDIT = 'Client.Retails.Individual.Edit'
export const CLIENT_INDIVIDUAL_BALANCE_DETAILS =
  'Client.Retails.Individual.BalanceDetails'
export const CLIENT_INDIVIDUAL_WALLET = 'Client.Retails.Individual.Wallet'
export const CLIENT_INDIVIDUAL_RECIPIENT = 'Client.Retails.Individual.Recipient'
export const CLIENT_INDIVIDUAL_PROFILE = 'Client.Retails.Individual.Profile'
export const CLIENT_BUSINESS = 'Client.Retails.Business'
export const CLIENT_BUSINESS_LIST = 'Client.Retails.Business.List'
export const CLIENT_BUSINESS_DETAIL = 'Client.Retails.Business.Detail'
export const CLIENT_BUSINESS_EDIT = 'Client.Retails.Business.Edit'
export const CLIENT_BUSINESS_BALANCE_DETAILS =
  'Client.Retails.Business.BalanceDetails'
export const CLIENT_BUSINESS_COMPANY_INFO =
  'Client.Retails.Business.CompanyInfo'
export const CLIENT_BUSINESS_WALLET = 'Client.Retails.Business.Wallet'
export const CLIENT_BUSINESS_RECIPIENT = 'Client.Retails.Business.Recipient'
export const CLIENT_BUSINESS_PROFILE = 'Client.Retails.Business.Profile'
export const CLIENT_MTO = 'Client.MTO'
export const CLIENT_MTO_LIST = 'Client.MTO.List'
export const CLIENT_MTO_DETAIL = 'Client.MTO.Detail'
export const CLIENT_MTO_EDIT = 'Client.MTO.Edit'
export const CLIENT_MTO_BALANCE_DETAILS = 'Client.MTO.Balance.Details'
export const CLIENT_ALLOW_SWITCH_BALANCE_TYPE = 'Client.AllowSwitchBalanceType'

export const GLOBAL_ACCOUNT = 'GlobalAccount'
export const GLOBAL_ACCOUNT_ACCOUNT = 'GlobalAccount.Account'
export const GLOBAL_ACCOUNT_ACCOUNT_DETAIL = 'GlobalAccount.Acount.Detail'
export const GLOBAL_ACCOUNT_ACCOUNT_LIST = 'GlobalAccount.Account.List'

export const GLOBAL_ACCOUNT_FEED = 'GlobalAccount.GAFeed'
export const GLOBAL_ACCOUNT_FEED_LIST = 'GlobalAccount.GAFeed.List'
// export const GLOBAL_ACCOUNT_FEED_EDIT = 'GlobalAccount.GAFeed.Edit'
// export const GLOBAL_ACCOUNT_FEED_CREATE = 'GlobalAccount.GAFeed.Create'

export const GLOBAL_ACCOUNT_COLLECTION = 'GlobalAccount.Collection'
export const GLOBAL_ACCOUNT_COLLECTION_LIST = 'GlobalAccount.Collection.List'

export const TRANSFER = 'Transfer'
export const TRANSFER_LIST = 'Transfer.List'
export const TRANSFER_DETAIL = 'Transfer.Detail'

export const MAINTENANCE = 'Maintenance'
export const MAINTENANCE_BANK = 'Maintenance.Bank'
export const MAINTENANCE_BANK_LIST = 'Maintenance.Bank.List'
export const MAINTENANCE_BANK_CREATE = 'Maintenance.Bank.Create'
export const MAINTENANCE_BANK_EDIT = 'Maintenance.Bank.Edit'
export const MAINTENANCE_FUNDSOURCE = 'Maintenance.FundSource'
export const MAINTENANCE_FUNDSOURCE_LIST = 'Maintenance.FundSource.List'
export const MAINTENANCE_FUNDSOURCE_CREATE = 'Maintenance.FundSource.Create'
export const MAINTENANCE_FUNDSOURCE_EDIT = 'Maintenance.FundSource.Edit'
export const MAINTENANCE_TRANSFERPURPOSE = 'Maintenance.TranferPurpose'
export const MAINTENANCE_TRANSFERPURPOSE_LIST =
  'Maintenance.TranferPurpose.List'
export const MAINTENANCE_TRANSFERPURPOSE_CREATE =
  'Maintenance.TranferPurpose.Create'
export const MAINTENANCE_TRANSFERPURPOSE_EDIT =
  'Maintenance.TranferPurpose.Edit'

export const RULE_POLICY = 'RulePolicy'
export const RULE_POLICY_COMPLIANCE = 'RulePolicy.Compliance'
export const RULE_POLICY_COMPLIANCE_LIST = 'RulePolicy.Compliance.List'
export const RULE_POLICY_COMPLIANCE_DETAILS = 'RulePolicy.Compliance.Details'

export const RULE_POLICY_ENABLE_AUTO_PAYOUT = 'RulePolicy.EnableAutoPayout'
export const RULE_POLICY_ENABLE_AUTO_PAYOUT_LIST =
  'RulePolicy.EnableAutoPayout.List'
export const RULE_POLICY_FEE_CHARGES = 'RulePolicy.FeeCharges'
export const RULE_POLICY_FEE_CHARGES_LIST = 'RulePolicy.FeeCharges.List'
export const RULE_POLICY_FEE_CHARGES_DETAILS = 'RulePolicy.FeeCharges.Details'

export const AGENCY = 'Agency'
export const AGENCY_AGENT = 'Agency.Agent'
export const AGENCY_AGENT_LIST = 'Agency.Agent.List'
export const AGENCY_AGENT_PROFILE = 'Agency.Agent.Profile'
export const AGENCY_SETTLEMENT = 'Agency.Settlement'
export const AGENCY_SETTLEMENT_LIST = 'Agency.Settlement.List'
export const AGENCY_SETTLEMENT_CREATE = 'Agency.Settlement.Create'
export const AGENCY_SETTLEMENT_DETAIL = 'Agency.Settlement.Detail'

export const MARKETING = 'Marketing'
export const MARKETING_COUPONMANAGEMENT = 'Marketing.CouponManagement'
export const MARKETING_COUPONMANAGEMENT_LIST = 'Marketing.CouponManagement.List'
export const MARKETING_REFERRALS = 'ReferralReward'
export const MARKETING_REFERRALS_LIST = 'Marketing.Referrals.List'

export const PAYMENT = 'Payment'
export const PAYMENT_RETAIL = 'Payment.Retail'
export const PAYMENT_RETAIL_LIST = 'Payment.Retail.List'
export const PAYMENT_RETAIL_NEW_TRANSACTION = 'Payment.Retail.NewTransaction'
export const PAYMENT_MTO = 'Payment.Partner'
export const PAYMENT_MTO_LIST = 'Payment.Partner.List'
export const PAYMENT_TRANSFER_BULK = 'Payment.GlobalBulk'
export const PAYMENT_TRANSFER_BULK_LIST = 'Payment.GlobalBulk.List'

export const CONVERSION = 'Conversion'
export const CONVERSION_LIST = 'Conversion.List'
export const CONVERSION_CREATE = 'Conversion.Create'

export const COMPLIANCE = 'Compliance'
export const COMPLIANCE_LIST = 'Compliance.List'
export const EXTERNAL_COMPLIANCE_LIST = 'ExternalCompliance.List'

export const GLOBAL_SEARCH = 'EnableGlobalSearch'

export const CARD_MANAGEMENT = 'CardManagement'
export const CARD_MANAGEMENT_CARD = 'CardManagement.Card'
export const CARD_MANAGEMENT_CARD_CREATE = 'CardManagement.Card.Create'
export const CARD_MANAGEMENT_CARD_LIST = 'CardManagement.Card.List'
export const CARD_MANAGEMENT_CARD_DETAIL = 'CardManagement.Card.Detail'
export const CARD_MANAGEMENT_BALANCE = 'CardManagement.Balance'
export const CARD_MANAGEMENT_BALANCE_LIST = 'CardManagement.Balance.List'
export const CARD_MANAGEMENT_TRANSACTION = 'CardManagement.Transaction'
export const CARD_MANAGEMENT_TRANSACTION_LIST =
  'CardManagement.Transaction.List'
export const RECONCILIATION = 'Reconciliation'

export const REFERENCE = 'Reference'
export const FEE_SCHEDULE = 'Reference.FeeSchedule'
export const FEE_SCHEDULE_LIST = 'Reference.FeeSchedule.List'

export const CUSTOMER_GROUP = 'Reference.CustomerGroup'
export const CUSTOMER_GROUP_LIST = 'Reference.CustomerGroup.List'
export const CUSTOMER_GROUP_DETAIL = 'Reference.CustomerGroup.Detail'
