/* eslint-disable react/jsx-props-no-spreading */
import Svg, { SvgProps } from '@transwap/ui/Core/Icons/Svg'

const Reload = (props: Omit<SvgProps, 'children'>) => {
  return (
    <Svg
      {...props}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.79499 18.7288C11.2641 21.7233 16.5039 21.3385 19.4984 17.8693C22.4929 14.4002 22.1081 9.16041 18.639 6.16592C15.1698 3.17144 9.93004 3.55622 6.93556 7.02536M6.93556 7.02536L8.16734 2.53751M6.93556 7.02536L11.1617 7.49603"
        stroke="#16171C"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default Reload
