/* eslint-disable react/jsx-props-no-spreading */
import Svg, { SvgProps } from '@transwap/ui/Core/Icons/Svg'

export default (props: Omit<SvgProps, 'children'>) => {
  return (
    <Svg {...props} viewBox="0 0 32 32">
      <path
        d="M25.3335 18.667H6.66683C6.04729 18.667 5.73752 18.667 5.47992 18.7182C4.42208 18.9286 3.59515 19.7556 3.38474 20.8134C3.3335 21.071 3.3335 21.3808 3.3335 22.0003C3.3335 22.6199 3.3335 22.9296 3.38474 23.1872C3.59515 24.2451 4.42208 25.072 5.47992 25.2824C5.73752 25.3337 6.04729 25.3337 6.66683 25.3337H25.3335C25.953 25.3337 26.2628 25.3337 26.5204 25.2824C27.5782 25.072 28.4052 24.2451 28.6156 23.1872C28.6668 22.9296 28.6668 22.6199 28.6668 22.0003C28.6668 21.3808 28.6668 21.071 28.6156 20.8134C28.4052 19.7556 27.5782 18.9286 26.5204 18.7182C26.2628 18.667 25.953 18.667 25.3335 18.667Z"
        fill="#F2F3FA"
        stroke="#16171C"
        strokeWidth="1.2"
      />
      <mask
        id="mask0"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="6"
        y="0"
        width="21"
        height="24"
      >
        <rect x="6.42969" width="19.7037" height="23.0815" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0)">
        <path
          d="M13.6002 1.26699H21.3928C22.1494 1.26699 22.6808 1.26746 23.0954 1.30133C23.5029 1.33463 23.7445 1.3972 23.931 1.49225C24.3199 1.69038 24.636 2.00654 24.8342 2.39541C24.9292 2.58195 24.9918 2.82349 25.0251 3.23106C25.059 3.64563 25.0594 4.17702 25.0594 4.93366V24.5485C25.0594 25.3051 25.059 25.8365 25.0251 26.2511C24.9918 26.6586 24.9292 26.9002 24.8342 27.0867C24.636 27.4756 24.3199 27.7917 23.931 27.9899C23.7445 28.0849 23.5029 28.1475 23.0954 28.1808C22.6808 28.2147 22.1494 28.2151 21.3928 28.2151H13.6002C12.8435 28.2151 12.3121 28.2147 11.8976 28.1808C11.49 28.1475 11.2485 28.0849 11.0619 27.9899L10.7895 28.5245L11.0619 27.9899C10.673 27.7917 10.3569 27.4756 10.1587 27.0867C10.0637 26.9002 10.0011 26.6586 9.96783 26.2511C9.93396 25.8365 9.9335 25.3051 9.9335 24.5485V4.93367C9.9335 4.17703 9.93396 3.64563 9.96783 3.23106C10.0011 2.82349 10.0637 2.58195 10.1587 2.39541C10.3569 2.00654 10.673 1.69038 11.0619 1.49225C11.2485 1.3972 11.49 1.33463 11.8976 1.30133C12.3121 1.26746 12.8435 1.26699 13.6002 1.26699Z"
          fill="#F2F3FA"
          stroke="#16171C"
          strokeWidth="1.2"
        />
        <path
          d="M10.6964 4.74844H18.4889C19.2456 4.74844 19.777 4.7489 20.1915 4.78278C20.5991 4.81608 20.8407 4.87865 21.0272 4.97369C21.4161 5.17183 21.7322 5.48799 21.9304 5.87686C22.0254 6.06339 22.088 6.30493 22.1213 6.7125C22.1551 7.12707 22.1556 7.65847 22.1556 8.4151V28.0299C22.1556 28.7866 22.1551 29.3179 22.1213 29.7325C22.088 30.1401 22.0254 30.3816 21.9304 30.5682C21.7322 30.957 21.4161 31.2732 21.0272 31.4713C20.8407 31.5664 20.5991 31.6289 20.1915 31.6622C19.777 31.6961 19.2456 31.6966 18.4889 31.6966H10.6964C9.93972 31.6966 9.40832 31.6961 8.99375 31.6622C8.58618 31.6289 8.34464 31.5664 8.15811 31.4713L7.88571 32.0059L8.15811 31.4713C7.76924 31.2732 7.45308 30.957 7.25494 30.5682C7.1599 30.3816 7.09733 30.1401 7.06403 29.7325C7.03015 29.3179 7.02969 28.7866 7.02969 28.0299V8.41511C7.02969 7.65847 7.03015 7.12708 7.06403 6.7125C7.09733 6.30493 7.1599 6.06339 7.25494 5.87686C7.45308 5.48799 7.76924 5.17183 8.15811 4.97369C8.34464 4.87865 8.58618 4.81608 8.99375 4.78278C9.40832 4.7489 9.93972 4.74844 10.6964 4.74844Z"
          fill="#F2F3FA"
          stroke="#16171C"
          strokeWidth="1.2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.3706 14.3854C10.3706 14.0745 10.6227 13.8224 10.9336 13.8224H11.28V12.6965C11.28 12.3856 11.5321 12.1335 11.843 12.1335C12.1539 12.1335 12.4059 12.3856 12.4059 12.6965V13.8224H14.3113V13.6492C14.3113 12.5012 15.242 11.5706 16.39 11.5706C17.538 11.5706 18.4686 12.5012 18.4686 13.6492V13.8224H18.8151C19.126 13.8224 19.378 14.0745 19.378 14.3854C19.378 14.6963 19.126 14.9483 18.8151 14.9483H18.4686V16.0743C18.4686 16.3852 18.2166 16.6372 17.9056 16.6372C17.5947 16.6372 17.3427 16.3852 17.3427 16.0743V14.9483H15.4373V15.1216C15.4373 16.2696 14.5066 17.2002 13.3586 17.2002C12.2106 17.2002 11.28 16.2696 11.28 15.1216V14.9483H10.9336C10.6227 14.9483 10.3706 14.6963 10.3706 14.3854ZM12.4059 14.9483V15.1216C12.4059 15.6477 12.8325 16.0743 13.3586 16.0743C13.8848 16.0743 14.3113 15.6477 14.3113 15.1216V14.9483H12.4059ZM15.4373 13.8224H17.3427V13.6492C17.3427 13.123 16.9161 12.6965 16.39 12.6965C15.8638 12.6965 15.4373 13.123 15.4373 13.6492V13.8224Z"
          fill="#16171C"
        />
      </g>
      <path
        d="M11.3335 28.667L16.0002 31.3337L20.6668 28.667"
        stroke="#16171C"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}
