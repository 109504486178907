import { QueryKey } from 'react-query'
import _cloneDeep from 'lodash/cloneDeep'
import useOrganizationQuery from './useOrganizationQuery'

const useTransformQueryKey = (
  queryKey: QueryKey,
  isOrganizationIncluded = true
) => {
  const { currentOrganization } = useOrganizationQuery()

  let key: QueryKey = _cloneDeep(queryKey)
  if (isOrganizationIncluded) {
    if (Array.isArray(key)) {
      key.push(currentOrganization)
    } else if (typeof queryKey === 'string') {
      key = [queryKey, currentOrganization] as unknown as QueryKey
    }
  }

  return key
}

export default useTransformQueryKey
