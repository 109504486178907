import * as urls from 'src/constants/urls'
import * as features from 'src/constants/features'
import { ElementType, Fragment, lazy, ReactPropTypes } from 'react'

export interface Route {
  id: string
  featureId: string
  path: string
  component: ElementType
  layout?: ElementType
  layoutProps?: ReactPropTypes
  isPublic?: boolean
  isCreate?: boolean
  customRender?: ElementType
}

export const routes: Route[] = [
  // ...AUTH_ROUTES,
  {
    id: features.AUTH_CALLBACK,
    featureId: features.AUTH_CALLBACK,
    path: urls.AUTH_URL.CALLBACK,
    component: lazy(() => import('src/auth/components/Callback')),
    isPublic: true,
    layout: Fragment,
  },
  {
    id: features.AUTH_CALLBACK_LOGOUT,
    featureId: features.AUTH_CALLBACK_LOGOUT,
    path: urls.AUTH_URL.SIGNOUT,
    component: lazy(() => import('src/auth/components/SignoutCallback')),
    isPublic: true,
    layout: Fragment,
  },
  {
    id: features.AUTH_CALLBACK_SILENT,
    featureId: features.AUTH_CALLBACK_SILENT,
    path: urls.AUTH_URL.SILENT_CALLBACK,
    component: lazy(() => import('src/auth/components/SilentRenew')),
    isPublic: true,
    layout: Fragment,
  },
  // ...BALANCE_ROUTES,
  {
    id: features.BALANCE_DEPOSIT_LIST,
    featureId: features.BALANCE_DEPOSIT,
    path: urls.DEPOSIT_URL.LIST,
    component: lazy(
      () =>
        import('src/pages/Balances/Deposits/DepositList/components/Deposits')
    ),
  },
  {
    id: features.BALANCE_DEPOSIT_CREATE,
    featureId: features.BALANCE_DEPOSIT,
    path: urls.DEPOSIT_URL.ADD,
    isCreate: true,
    component: lazy(() => import('src/pages/Balances/Deposits/DepositCreate')),
  },
  {
    id: features.BALANCE_FUNDING_LIST,
    featureId: features.BALANCE_DEPOSIT,
    path: urls.FUNDING_URL.LIST,
    component: lazy(() => import('src/pages/Balances/Fundings')),
  },
  {
    id: features.BALANCE_FUNDING_CREATE,
    featureId: features.BALANCE_FUNDING_CREATE,
    path: urls.FUNDING_URL.ADD,
    component: lazy(() => import('src/pages/Balances/Fundings/FundingCreate')),
  },
  {
    id: features.BALANCE_WITHDRAWAL_LIST,
    featureId: features.BALANCE_WITHDRAWAL,
    path: urls.WITHDRAWAL_URL.LIST,
    component: lazy(
      () => import('src/pages/Balances/Withdrawals/WithdrawalList')
    ),
  },
  {
    id: features.BALANCE_WITHDRAWAL_CREATE,
    featureId: features.BALANCE_WITHDRAWAL,
    path: urls.WITHDRAWAL_URL.ADD,
    isCreate: true,
    component: lazy(
      () => import('src/pages/Balances/Withdrawals/WithdrawalCreate')
    ),
  },
  {
    id: features.BALANCE_REFUND_LIST,
    featureId: features.BALANCE_REFUND,
    path: urls.REFUND_URL.LIST,
    component: lazy(() => import('src/pages/Balances/Refunds/Refunds')),
  },
  {
    id: features.BALANCE_REFUND_CREATE,
    featureId: features.BALANCE_REFUND,
    path: urls.REFUND_URL.ADD,
    isCreate: true,
    component: lazy(
      () => import('src/pages/Balances/Refunds/RefundCreate/RefundCreate')
    ),
  },
  {
    id: features.FUNDFEEDS_BANK_FEEDS,
    featureId: features.FUNDFEEDS_BANK_FEEDS,
    path: urls.BANK_FEED_URL.LIST,
    component: lazy(() => import('src/pages/FundFeeds/BankFeeds/BankFeedList')),
  },
  {
    id: features.FUNDFEEDS_BANKFEED_CREATE,
    featureId: features.FUNDFEEDS_BANK_FEEDS,
    path: urls.BANK_FEED_URL.ADD,
    isCreate: true,
    component: lazy(
      () => import('src/pages/FundFeeds/BankFeeds/BankFeedCreate')
    ),
  },
  {
    id: features.FUNDFEEDS_BANKFEED_EDIT,
    featureId: features.FUNDFEEDS_BANK_FEEDS,
    path: urls.BANK_FEED_URL.EDIT,
    component: lazy(() => import('src/pages/FundFeeds/BankFeeds/BankFeedEdit')),
  },
  {
    id: features.FUNDFEEDS_CREDITFEED_LIST,
    featureId: features.FUNDFEEDS_CREDITFEEDS,
    path: urls.CREDIT_FEED_URL.LIST,
    component: lazy(
      () => import('src/pages/FundFeeds/CreditFeeds/CreditFeedList')
    ),
  },
  {
    id: features.BALANCE_DEBIT_CREDIT_NOTES_LIST,
    featureId: features.BALANCE_DEBIT_CREDIT_NOTES,
    path: urls.DEBIT_CREDIT_NOTES_URL.LIST,
    component: lazy(() => import('src/pages/Balances/DebitCreditNotes')),
  },
  {
    id: features.BALANCE_DEBIT_CREDIT_NOTES_CREATE,
    featureId: features.BALANCE_DEBIT_CREDIT_NOTES,
    path: urls.DEBIT_CREDIT_NOTES_URL.ADD,
    component: lazy(() => import('src/pages/Balances/DebitCreditNotes/Create')),
  },
  {
    id: features.NOSTRO_LIST,
    featureId: features.NOSTRO_NOSTROS,
    path: urls.NOSTRO_URL.LIST,
    component: lazy(() => import('src/pages/Nostros/List/NostroList')),
  },
  {
    id: features.NOSTRO_DETAIL,
    featureId: features.NOSTRO_NOSTROS,
    path: urls.NOSTRO_URL.DETAILS,
    component: lazy(() => import('src/pages/Nostros/Details/NostroDetails')),
  },
  {
    id: features.NOSTRO_HOUSE_TRANSFERS_LIST,
    featureId: features.NOSTRO_HOUSE_TRANSFERS,
    path: urls.HOUSE_TRANSFER_URL.LIST,
    component: lazy(() => import('src/pages/Nostros/HouseTransfers/page')),
  },
  {
    id: features.FUNDFEEDS_PARTNERFEEDS,
    featureId: features.FUNDFEEDS_PARTNERFEEDS,
    path: urls.PARTNER_FEED_URL.LIST,
    component: lazy(
      () => import('src/pages/FundFeeds/PartnerFeeds/PartnerList')
    ),
  },
  {
    id: features.FUNDFEEDS_PARTNERFEED_CREATE,
    featureId: features.FUNDFEEDS_PARTNERFEEDS,
    path: urls.PARTNER_FEED_URL.CREATE,
    component: lazy(
      () => import('src/pages/FundFeeds/PartnerFeeds/PartnerCreate')
    ),
  },
  {
    id: features.FUNDFEEDS_PARTNERFEED_EDIT,
    featureId: features.FUNDFEEDS_PARTNERFEEDS,
    path: urls.PARTNER_FEED_URL.EDIT,
    component: lazy(
      () => import('src/pages/FundFeeds/PartnerFeeds/PartnerEdit')
    ),
  },
  // ...CLIENT_ROUTES,
  {
    id: features.CLIENT_INDIVIDUAL_LIST,
    featureId: features.CLIENT_INDIVIDUAL,
    path: urls.INDIVIDUALS_URL.LIST,
    component: lazy(
      () => import('src/pages/Clients/Retails/Individuals/IndividualList')
    ),
  },
  {
    id: features.CLIENT_INDIVIDUAL_DETAIL,
    featureId: features.CLIENT_INDIVIDUAL,
    path: urls.INDIVIDUALS_URL.DETAILS,
    component: lazy(
      () => import('src/pages/Clients/Retails/Individuals/IndividualDetails')
    ),
  },
  {
    id: features.CLIENT_INDIVIDUAL_EDIT,
    featureId: features.CLIENT_INDIVIDUAL,
    path: urls.INDIVIDUALS_URL.EDIT,
    component: lazy(
      () => import('src/pages/Clients/Retails/Individuals/IndividualEdit')
    ),
  },
  {
    id: features.CLIENT_INDIVIDUAL_BALANCE_DETAILS,
    featureId: features.CLIENT_INDIVIDUAL,
    path: urls.INDIVIDUALS_URL.BALANCE_DETAILS,
    component: lazy(
      () => import('src/pages/Clients/Retails/Individuals/BalanceDetails')
    ),
  },
  {
    id: features.CLIENT_BUSINESS_LIST,
    featureId: features.CLIENT_BUSINESS,
    path: urls.BUSINESSES_URL.LIST,
    component: lazy(
      () => import('src/pages/Clients/Retails/Businesses/BusinessList')
    ),
  },
  {
    id: features.CLIENT_BUSINESS_DETAIL,
    featureId: features.CLIENT_BUSINESS,
    path: urls.BUSINESSES_URL.DETAILS,
    component: lazy(
      () => import('src/pages/Clients/Retails/Businesses/BusinessDetails')
    ),
  },
  {
    id: features.CLIENT_BUSINESS_EDIT,
    featureId: features.CLIENT_BUSINESS,
    path: urls.BUSINESSES_URL.EDIT,
    component: lazy(
      () => import('src/pages/Clients/Retails/Businesses/BusinessEdit')
    ),
  },
  {
    id: features.CLIENT_BUSINESS_BALANCE_DETAILS,
    featureId: features.CLIENT_BUSINESS,
    path: urls.BUSINESSES_URL.BALANCE_DETAILS,
    component: lazy(
      () => import('src/pages/Clients/Retails/Businesses/BalanceDetails')
    ),
  },
  // RULES & POLICIES
  {
    id: features.RULE_POLICY_COMPLIANCE_LIST,
    featureId: features.RULE_POLICY_COMPLIANCE,
    path: urls.RULES_POLICIES_COMPLIANCE_URL.LIST,
    component: lazy(
      () => import('src/pages/RulesPolicies/Compliance/ComplianceList')
    ),
  },
  {
    id: features.RULE_POLICY_COMPLIANCE_DETAILS,
    featureId: features.RULE_POLICY_COMPLIANCE,
    path: urls.RULES_POLICIES_COMPLIANCE_URL.DETAILS,
    component: lazy(
      () => import('src/pages/RulesPolicies/Compliance/ComplianceDetails')
    ),
  },
  {
    id: features.RULE_POLICY_ENABLE_AUTO_PAYOUT_LIST,
    featureId: features.RULE_POLICY_ENABLE_AUTO_PAYOUT,
    path: urls.RULES_POLICIES_ENABLE_AUTO_PAYOUT_URL.LIST,
    component: lazy(() => import('src/pages/RulesPolicies/ApiPaymentConfig/')),
  },
  {
    id: features.RULE_POLICY_FEE_CHARGES_LIST,
    featureId: features.RULE_POLICY_FEE_CHARGES,
    path: urls.RULES_POLICIES_FEE_CHARGES_URL.LIST,
    component: lazy(() => import('src/pages/RulesPolicies/FeeCharges/')),
  },
  // GLOBAL ACCOUNTS
  {
    id: features.GLOBAL_ACCOUNT_ACCOUNT_LIST,
    featureId: features.GLOBAL_ACCOUNT_ACCOUNT,
    path: urls.GLOBAL_ACCOUNT_ACCOUNT_URL.LIST,
    component: lazy(() => import('src/pages/GlobalAccounts/Accounts')),
  },
  // GLOBAL ACCOUNT COLLECTIONS
  {
    id: features.GLOBAL_ACCOUNT_ACCOUNT_DETAIL,
    featureId: features.GLOBAL_ACCOUNT_ACCOUNT,
    path: urls.INDIVIDUALS_URL.GA_DETAILS,
    component: lazy(
      () => import('src/pages/GlobalAccounts/Accounts/AccountDetails')
    ),
  },
  {
    id: features.GLOBAL_ACCOUNT_ACCOUNT_DETAIL,
    featureId: features.GLOBAL_ACCOUNT_ACCOUNT,
    path: urls.BUSINESSES_URL.GA_DETAILS,
    component: lazy(
      () => import('src/pages/GlobalAccounts/Accounts/AccountDetails')
    ),
  },
  {
    id: features.GLOBAL_ACCOUNT_ACCOUNT_DETAIL,
    featureId: features.GLOBAL_ACCOUNT_ACCOUNT,
    path: urls.MTO_URL.GA_DETAILS,
    component: lazy(
      () => import('src/pages/GlobalAccounts/Accounts/AccountDetails')
    ),
  },
  // GLOBAL ACCOUNT FEEDS
  {
    id: features.GLOBAL_ACCOUNT_FEED_LIST,
    featureId: features.GLOBAL_ACCOUNT_FEED,
    path: urls.GLOBAL_ACCOUNT_FEED_URL.LIST,
    component: lazy(() => import('src/pages/GlobalAccounts/Feeds')),
  },
  // Todo: will enable when api already
  // {
  //   id: features.GLOBAL_ACCOUNT_FEED_CREATE,
  //   featureId: features.GLOBAL_ACCOUNT_FEED,
  //   path: urls.GLOBAL_ACCOUNT_FEED_URL.ADD,
  //   component: lazy(() => import('src/pages/GlobalAccounts/Feeds/CreateFeed')),
  // },
  // {
  //   id: features.GLOBAL_ACCOUNT_FEED_EDIT,
  //   featureId: features.GLOBAL_ACCOUNT_FEED,
  //   path: urls.GLOBAL_ACCOUNT_FEED_URL.EDIT,
  //   component: lazy(() => import('src/pages/GlobalAccounts/Feeds/EditFeed')),
  // },
  {
    id: features.GLOBAL_ACCOUNT_COLLECTION_LIST,
    featureId: features.GLOBAL_ACCOUNT_COLLECTION,
    path: urls.GLOBAL_ACCOUNT_COLLECTION_URL.LIST,
    component: lazy(() => import('src/pages/GlobalAccounts/Collections')),
  },
  {
    id: features.CLIENT_MTO_LIST,
    featureId: features.CLIENT_MTO,
    path: urls.MTO_URL.LIST,
    component: lazy(() => import('src/pages/Clients/MTOs/MTOList')),
  },
  {
    id: features.CLIENT_MTO_DETAIL,
    featureId: features.CLIENT_MTO,
    path: urls.MTO_URL.DETAILS,
    component: lazy(() => import('src/pages/Clients/MTOs/MTODetails')),
  },
  {
    id: features.CLIENT_MTO_EDIT,
    featureId: features.CLIENT_MTO,
    path: urls.MTO_URL.EDIT,
    component: lazy(() => import('src/pages/Clients/MTOs/MTOEdit')),
  },
  {
    id: features.CLIENT_MTO_BALANCE_DETAILS,
    featureId: features.CLIENT_MTO,
    path: urls.MTO_URL.BALANCE_DETAILS,
    component: lazy(() => import('src/pages/Clients/MTOs/BalanceDetails')),
  },
  // ...DASHBOARD_ROUTES,
  {
    id: features.DASHBOARD,
    featureId: features.DASHBOARD,
    path: urls.DASHBOARD_URL,
    component: lazy(() => import('src/pages/Dashboard')),
  },

  // ...AGENCY_ROUTES,
  {
    id: features.AGENCY_SETTLEMENT_LIST,
    featureId: features.AGENCY_SETTLEMENT,
    path: urls.SETTLEMENT_URL.LIST,
    component: lazy(
      () => import('src/pages/Agency/Settlements/SettlementList/Settlements')
    ),
  },
  {
    id: features.AGENCY_SETTLEMENT_CREATE,
    featureId: features.AGENCY_SETTLEMENT,
    path: urls.SETTLEMENT_URL.ADD,
    component: lazy(
      () =>
        import('src/pages/Agency/Settlements/SettlementCreate/SettlementCreate')
    ),
  },
  {
    id: features.AGENCY_SETTLEMENT_DETAIL,
    featureId: features.AGENCY_SETTLEMENT,
    path: urls.SETTLEMENT_URL.DETAIL,
    component: lazy(
      () =>
        import(
          'src/pages/Agency/Settlements/SettlementDetails/SettlementDetails'
        )
    ),
  },
  // ...MARKETING_ROUTES,
  {
    id: features.MARKETING_COUPONMANAGEMENT_LIST,
    featureId: features.MARKETING_COUPONMANAGEMENT,
    path: urls.COUPONS_MANAGEMENT_URL.LIST,
    component: lazy(() => import('src/pages/Marketing/Coupons')),
  },
  {
    id: features.MARKETING_REFERRALS_LIST,
    featureId: features.MARKETING_REFERRALS,
    path: urls.REFERRALS_URL.LIST,
    component: lazy(
      () => import('src/pages/Marketing/Referrals/ReferralsList')
    ),
  },
  // ...PAYMENTS_ROUTES,
  {
    id: features.PAYMENT_RETAIL_LIST,
    featureId: features.PAYMENT_RETAIL,
    path: urls.PAYMENTS_RETAILS_URL.LIST,
    component: lazy(() => import('src/pages/Payments/Retails/Retails')),
  },
  {
    id: features.PAYMENT_RETAIL_NEW_TRANSACTION,
    featureId: features.PAYMENT_RETAIL,
    path: urls.PAYMENTS_RETAILS_URL.NEW_TRANSACTION,
    component: lazy(() => import('src/pages/Payments/Retails/NewTransaction')),
  },
  {
    id: features.PAYMENT_TRANSFER_BULK_LIST,
    featureId: features.PAYMENT_TRANSFER_BULK,
    path: urls.PAYMENTS_TRANSFER_BULK_URL.LIST,
    component: lazy(() => import('src/pages/Payments/TransferBulks')),
  },
  {
    id: features.PAYMENT_MTO_LIST,
    featureId: features.PAYMENT_MTO,
    path: urls.PAYMENTS_MTOS_URL.LIST,
    component: lazy(() => import('src/pages/Payments/MTOs')),
  },
  // ...CONVERSIONS_ROUTES,
  {
    id: features.CONVERSION_LIST,
    featureId: features.CONVERSION,
    path: urls.CONVERSION_URL.LIST,
    component: lazy(() => import('src/pages/Conversions')),
  },
  {
    id: features.CONVERSION_CREATE,
    featureId: features.CONVERSION,
    path: urls.CONVERSION_URL.CREATE,
    component: lazy(() => import('src/pages/Conversions/ConversionCreate')),
  },
  // ...CARD_ROUTES
  {
    id: features.CARD_MANAGEMENT_CARD_LIST,
    featureId: features.CARD_MANAGEMENT_CARD,
    path: urls.CARD_MANAGEMENT_CARDS_URL.LIST,
    component: lazy(() => import('src/pages/CardManagement/Cards')),
  },
  {
    id: features.CARD_MANAGEMENT_CARD_DETAIL,
    featureId: features.CARD_MANAGEMENT_CARD,
    path: urls.INDIVIDUALS_URL.CARD_DETAILS,
    component: lazy(() => import('src/pages/CardManagement/Cards/CardDetails')),
  },
  {
    id: features.CARD_MANAGEMENT_CARD_DETAIL,
    featureId: features.CARD_MANAGEMENT_CARD,
    path: urls.BUSINESSES_URL.CARD_DETAILS,
    component: lazy(() => import('src/pages/CardManagement/Cards/CardDetails')),
  },
  {
    id: features.CARD_MANAGEMENT_CARD_CREATE,
    featureId: features.CARD_MANAGEMENT_CARD,
    path: urls.CARD_MANAGEMENT_CARDS_URL.ADD,
    component: lazy(() => import('src/pages/CardManagement/Cards/CardCreate')),
  },
  {
    id: features.CARD_MANAGEMENT_BALANCE_LIST,
    featureId: features.CARD_MANAGEMENT_BALANCE,
    path: urls.CARD_MANAGEMENT_BALANCES_URL.LIST,
    component: lazy(() => import('src/pages/CardManagement/Balances')),
  },
  {
    id: features.CARD_MANAGEMENT_TRANSACTION_LIST,
    featureId: features.CARD_MANAGEMENT_TRANSACTION,
    path: urls.CARD_MANAGEMENT_TRANSACTIONS_URL.LIST,
    component: lazy(() => import('src/pages/CardManagement/Transactions')),
  },
  // ...RECONCILIATION_ROUTES,
  {
    id: features.RECONCILIATION,
    featureId: features.RECONCILIATION,
    path: urls.RECONCILIATION_URL.LIST,
    component: lazy(() => import('src/pages/Reconciliation')),
  },
  // ...COMPLIANCE_ROUTES,
  {
    id: features.COMPLIANCE_LIST,
    featureId: features.COMPLIANCE,
    path: urls.COMPLIANCE_URL.INTERNAL,
    component: lazy(() => import('src/pages/Compliances/ComplianceList')),
  },
  {
    id: features.EXTERNAL_COMPLIANCE_LIST,
    featureId: features.COMPLIANCE,
    path: urls.COMPLIANCE_URL.RFI,
    component: lazy(() => import('src/pages/Compliances/RFIList')),
  },
  {
    id: features.FEE_SCHEDULE_LIST,
    featureId: features.FEE_SCHEDULE_LIST,
    path: urls.FEE_SCHEDULE_URL.LIST,
    component: lazy(() => import('src/pages/References/FeeSchedules')),
  },
  {
    id: features.CUSTOMER_GROUP_LIST,
    featureId: features.CUSTOMER_GROUP,
    path: urls.CUSTOMER_GROUP_URL.LIST,
    component: lazy(() => import('src/pages/References/CustomerGroups')),
  },
  {
    id: features.CUSTOMER_GROUP_DETAIL,
    featureId: features.CUSTOMER_GROUP,
    path: urls.CUSTOMER_GROUP_URL.DETAIL,
    component: lazy(
      () => import('src/pages/References/CustomerGroups/Details')
    ),
  },
  // ...PAGENOTFOUND_LAST_ROUTES, //LAST ROUTES
  {
    id: features.NOTFOUND,
    featureId: features.NOTFOUND,
    path: urls.ACCESS_DENIED,
    layout: Fragment,
    isPublic: true,
    component: lazy(() => import('src/pages/StatusPage/PermissionDenied')),
  },
  {
    id: features.NOTFOUND,
    featureId: features.NOTFOUND,
    path: '*',
    layout: Fragment,
    component: lazy(() => import('src/pages/StatusPage/PageNotFound')),
  },
]

export default routes
