import {
  Pageable,
  ApproveCardRequestModel,
  CancelCardRequestModel,
  CardsModel,
  CardsRequestModel,
  FreezeCardRequestModel,
  RejectCardRequestModel,
  CardsSummaryModel,
  CardsSummaryRequestModel,
  CreateCardModel,
  EditCardRequestModel,
  CardTransactionsRequestModel,
  CardTransactionsModel,
} from 'src/@types'

import axios from 'src/api/axios'

const baseURL = '/cards'

const cards = {
  getCards: (params?: CardsRequestModel) =>
    axios.get<Pageable<CardsModel>>(baseURL, { params }),
  getCardDetails: (id: string) => axios.get<CardsModel>(`${baseURL}/${id}`),
  getCardsSummary: (params?: CardsSummaryRequestModel) =>
    axios.get<CardsSummaryModel>(`${baseURL}/:getSummary`, { params }),
  getCardTransactions: (params?: CardTransactionsRequestModel) =>
    axios.get<Pageable<CardTransactionsModel>>(`${baseURL}/transactions`, {
      params,
    }),
  createCard: (payload: CreateCardModel) => axios.post(baseURL, payload),
  rejectCard: (cardId: string, payload: RejectCardRequestModel) =>
    axios.put(`${baseURL}/${cardId}:reject`, payload),
  approveCard: (cardId: string, payload: ApproveCardRequestModel) =>
    axios.put(`${baseURL}/${cardId}:approve`, payload),
  freezeCard: (cardId: string, payload: FreezeCardRequestModel) =>
    axios.put<CardsModel>(`${baseURL}/${cardId}:freeze`, payload),
  cancelCard: (cardId: string, payload: CancelCardRequestModel) =>
    axios.put(`${baseURL}/${cardId}:terminate`, payload),
  editCard: (cardId: string, payload: EditCardRequestModel) =>
    axios.put(`${baseURL}/${cardId}`, payload),
}

export default cards
