import { ListItem } from '@transwap/ui/App/Shared'
import { makeStyles } from '@material-ui/core'
import {
  GlobalSearchItemModel,
  GlobalSearchItemType,
} from 'src/@types/models/globalSearch'
import useRouter from 'src/hooks/useRouter'
import {
  BANK_FEED_URL,
  CREDIT_FEED_URL,
  DASHBOARD_URL,
  DEPOSIT_URL,
  GLOBAL_ACCOUNT_FEED_URL,
  MTO_URL,
  REFUND_URL,
  WITHDRAWAL_URL,
  INDIVIDUALS_URL,
  BUSINESSES_URL,
  PAYMENTS_RETAILS_URL,
  PAYMENTS_MTOS_URL,
  COMPLIANCE_URL,
} from 'src/constants/urls'
import { NA } from 'src/constants/common'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import GlobalSearchDescription from './GlobalSearchDescription'

interface ItemGlobalSearchProps {
  data: GlobalSearchItemModel
  searchText: string
  handleCloseDialog: () => void
}

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: 0,
    boxShadow: `0px 1px 0px ${theme.palette.divider}`,

    '& > *': {
      marginLeft: 0,
    },
  },
  link: {
    textDecoration: 'none',
  },
}))

const getPathName = (type: GlobalSearchItemType) => {
  switch (type) {
    case 'Feed':
      return BANK_FEED_URL.LIST
    case 'Deposit':
      return DEPOSIT_URL.LIST
    case 'Withdrawal':
      return WITHDRAWAL_URL.LIST
    case 'GAFeed':
      return GLOBAL_ACCOUNT_FEED_URL.LIST
    case 'CreditFeed':
      return CREDIT_FEED_URL.LIST
    case 'Refund':
      return REFUND_URL.LIST
    case 'MTO':
      return MTO_URL.LIST
    case 'Personal':
      return INDIVIDUALS_URL.LIST
    case 'Business':
      return BUSINESSES_URL.LIST
    case 'Transfer':
      return PAYMENTS_RETAILS_URL.LIST
    case 'TransferMTO':
      return PAYMENTS_MTOS_URL.LIST
    case 'Compliance':
      return COMPLIANCE_URL.LIST
    default:
      return DASHBOARD_URL
  }
}

const ItemGlobalSearch = ({
  data,
  searchText,
  handleCloseDialog,
}: ItemGlobalSearchProps) => {
  const classes = useStyles()

  const { location } = useRouter()

  const searchString = useMemo(() => {
    const queryString = new URLSearchParams(location.search)
    if (queryString.has('searchText')) {
      queryString.set('searchText', searchText)
    } else {
      queryString.append('searchText', searchText)
    }

    return queryString.toString()
  }, [location.search, searchText])

  return (
    <Link
      to={{
        pathname: getPathName(data.type),
        hash: data.category?.toLocaleLowerCase(),
        search: searchString,
      }}
      className={classes.link}
      onClick={handleCloseDialog}
    >
      <ListItem
        title={data.accountName || NA}
        description={
          <GlobalSearchDescription
            type={data.type}
            itemStatus={data.status || ''}
            transactionNumber={data.transactionNumber || ''}
          />
        }
        classes={{ root: classes.root }}
      />
    </Link>
  )
}

export default ItemGlobalSearch
