import {
  TransactionComplianceDetailsModel,
  HistoryModel,
  NoteCreateModel,
  NoteModel,
  NotesRequestModel,
  Pageable,
  PayoutConfirmationModel,
  PayoutDetails,
  TransferApproveModel,
  TransferDetailsModel,
  TransferExpireModel,
  TransferModel,
  TransferRejectModel,
  TransferRequestModel,
  TransferSendBlackBoxModel,
  TransferSummary,
  TransferSummaryRequestModel,
  UpdateTransferModel,
  UpdatePayoutInformation,
  CreateQuotationRequestModel,
  QuotationResponseModel,
  ValidateTransferComplainceRequestModel,
  CreateTransferRequestModel,
  ValidateTransferComplainceResponseModel,
  CreateTransferResponseModel,
  NoteRemoveModel,
  TransferExpireBatchModel,
} from 'src/@types'
import axios from 'src/api/axios'

const baseURL = '/transfers'

const transfers = {
  getTransfers: (params: TransferRequestModel) =>
    axios.get<Pageable<TransferModel>>(baseURL, { params }),
  getTransferById: (id: string) =>
    axios.get<TransferDetailsModel>(`${baseURL}/${id}`),
  getTransferSummary: (params: TransferSummaryRequestModel) =>
    axios.get<TransferSummary>(`${baseURL}/:getSummary`, {
      params,
    }),
  submitPayout: (id: string, payload: PayoutConfirmationModel) =>
    axios.put<TransferDetailsModel>(`${baseURL}/${id}:submitPayout`, payload),
  completePayout: (id: string, payload: PayoutDetails) =>
    axios.put<TransferDetailsModel>(`${baseURL}/${id}:complete`, payload),
  cancelManualPayout: (id: string, payload: PayoutDetails) =>
    axios.put<TransferDetailsModel>(`${baseURL}/${id}:cancelManual`, payload),
  approveTransfer: (payload: TransferApproveModel) =>
    axios.put<TransferDetailsModel>(
      `${baseURL}/${payload.id}:approveCompliance`,
      {
        remark: payload.remark,
      }
    ),
  rejectTransfer: (payload: TransferRejectModel) =>
    axios.put<TransferDetailsModel>(`${baseURL}/${payload.id}:reject`, {
      reason: payload.reason,
      rejectionCode: payload.rejectionCode,
    }),
  getComplianceDetails: (id: string) =>
    axios.get<TransactionComplianceDetailsModel>(
      `${baseURL}/${id}:getComplianceDetail`
    ),
  sendToBlackBox: (payload: TransferSendBlackBoxModel) =>
    axios.put<TransferDetailsModel>(
      `${baseURL}/${payload.id}:sendToBlackBox`,
      payload
    ),
  expireTransfer: (payload: TransferExpireModel) =>
    axios.put<TransferDetailsModel>(`${baseURL}/${payload.id}:expire`, payload),
  expireBatchTransfers: (payload: TransferExpireBatchModel) =>
    axios.put<Array<TransferDetailsModel>>(`${baseURL}/batchExpire`, payload),
  getTransferHistory: (id: string) =>
    axios.get<Array<HistoryModel>>(`${baseURL}/${id}:activities`, {
      params: {
        transferId: id,
        pageIndex: 0,
        pageSize: 500,
      },
    }),
  createNotes: (params: NoteCreateModel) =>
    axios.post(`${baseURL}/attachment`, params),
  removeNotes: (params: NoteRemoveModel) =>
    axios.post(`${baseURL}/attachment:remove`, params),
  getNotes: (params: NotesRequestModel) =>
    axios.get<Pageable<NoteModel>>(`${baseURL}/attachment`, {
      params,
    }),
  updateTransfer: (id: string, params: UpdateTransferModel) =>
    axios.put(`${baseURL}/${id}`, params),
  updatePaymentMethodToWallet: (id: string) =>
    axios.put(`${baseURL}/${id}:changePaymentMethod`),
  triggerNotification: (id: string) =>
    axios.put(`${baseURL}/${id}:triggerWebhook`, null),
  updatePayoutInformation: (id: string, payload: UpdatePayoutInformation) =>
    axios.put(`${baseURL}/${id}:updatePayoutInformation`, payload),
  generateQuotation: (payload: CreateQuotationRequestModel) =>
    axios.post<QuotationResponseModel>(`${baseURL}/quotation`, payload),
  createTransfer: (payload: CreateTransferRequestModel) =>
    axios.post<CreateTransferResponseModel>(baseURL, payload),
  createTransferWithAttachment: (payload: CreateTransferRequestModel) =>
    axios.post<CreateTransferResponseModel>(
      `${baseURL}/:withAttachment`,
      payload
    ),
  validateTransferCompliance: (
    params: ValidateTransferComplainceRequestModel
  ) =>
    axios.get<ValidateTransferComplainceResponseModel>(
      `${baseURL}/:validateTransferCompliance`,
      { params }
    ),

  downloadReceipt: (id: string) =>
    axios.get<Blob>(`${baseURL}/${id}:downloadReceipt`, {
      responseType: 'blob',
    }),

  getTransferMethods: (
    accountId: string,
    sourceCurrency: string,
    destinationCurrency: string,
    destinationCountry: string
  ) =>
    axios.get<Array<string>>(`${baseURL}/:getTransferMethods`, {
      params: {
        accountId,
        senderCurrency: sourceCurrency,
        recipientCurrency: destinationCurrency,
        recipientCountry: destinationCountry,
      },
    }),
}

export default transfers
