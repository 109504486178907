/* eslint-disable promise/prefer-await-to-then */
import {
  HistoryModel,
  NoteCreateModel,
  NoteModel,
  NotesRequestModel,
  Pageable,
} from 'src/@types'
import {
  ApproveGlobalAccount,
  GlobalAccountRequestModel,
  GlobalAccountDetailModel,
  GlobalAccountModel,
  GlobalAccountSummary,
  GlobalAccountTransactionModel,
  RejectedGlobalAccount,
  GlobalAccountTransactionQueryModel,
} from 'src/@types/models/gaAccount'
import axios from 'src/api/axios'

const baseUrl = '/globalAccounts/accounts'

const globalAccounts = {
  getGlobalAccounts: (params: GlobalAccountRequestModel) =>
    axios.get<Pageable<GlobalAccountModel>>(baseUrl, { params }),
  getGlobalAccountDetails: (id: string) =>
    axios.get<GlobalAccountDetailModel>(`${baseUrl}/${id}`),
  getGlobalAccountSummary: () =>
    axios.get<GlobalAccountSummary>(`${baseUrl}/:summary`),
  getTransactions: (id: string, params: GlobalAccountTransactionQueryModel) =>
    axios.get<Pageable<GlobalAccountTransactionModel>>(
      `${baseUrl}/${id}:transactions`,
      {
        params,
      }
    ),
  approveGlobalAccount: (payload: ApproveGlobalAccount) =>
    axios.put<GlobalAccountDetailModel>(`${baseUrl}/${payload.id}:approve`, {
      accountId: payload.accountId,
      remark: payload.remark,
    }),
  rejectGlobalAccount: (payload: RejectedGlobalAccount) =>
    axios.put<GlobalAccountDetailModel>(`${baseUrl}/${payload.id}:reject`, {
      remark: payload.remark,
    }),
  getGlobalAccountHistories: (id: string) =>
    axios
      .get<Pageable<HistoryModel>>(`${baseUrl}/activities`, {
        params: {
          gloablaAccountId: id,
          pageIndex: 0,
          pageSize: 500,
        },
      })
      .then(res => res.items as Array<HistoryModel>),
  createGlobalAccountNotes: (payload: NoteCreateModel) =>
    axios.post(`${baseUrl}/attachment`, payload),
  getGlobalAccountNotes: (params: NotesRequestModel) =>
    axios.get<Pageable<NoteModel>>(`${baseUrl}/attachment`, { params }),
}

export default globalAccounts
