import axios from 'src/api/axios'
import {
  GetTagsQuery,
  CreateTagPayload,
  RefTag,
  AssignTagPayload,
  RecordTag,
  UpdateTagPayload,
} from 'src/@types/models/tags'

const baseURL = '/tags'

const tags = {
  getRefTags: (params?: GetTagsQuery) =>
    axios.get<RefTag[]>(baseURL, { params }),
  getTags: (recordId: string) =>
    axios.get<RecordTag[]>(`transactiontags/:find?transactionId=${recordId}`),
  createTag: (payload: CreateTagPayload) => axios.post(baseURL, payload),
  updateTag: (tagId: string, payload: UpdateTagPayload) =>
    axios.put(`${baseURL}/${tagId}`, payload),
  deleteTag: (tagId: string) => axios.delete(`${baseURL}/${tagId}`),
  assignTag: (payload: AssignTagPayload) =>
    axios.post('transactiontags', payload),
  unassignTag: (recordTagId: string) =>
    axios.delete(`transactiontags/${recordTagId}`),
}

export default tags
