import Svg, { SvgProps } from '@transwap/ui/Core/Icons/Svg'

const Attachment = ({
  classes,
  fontSize,
  border,
  borderProps,
  color,
}: SvgProps) => {
  return (
    <Svg
      fontSize={fontSize}
      classes={classes}
      border={border}
      borderProps={borderProps}
      color={color}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 5V3C11 2.44772 10.5523 2 10 2H3C2.44772 2 2 2.44771 2 3V12C2 12.5523 2.44772 13 3 13H7"
        stroke="#888B94"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="7"
        y="5"
        width="9"
        height="11"
        rx="1"
        stroke="#888B94"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default Attachment
