import Organizations, { ORGANIZATION_QUERY } from 'src/constants/organizations'
import queryString from 'query-string'
import { ORGANIZATION_KEY } from 'src/constants/storageKeys'

const getOrganizationQuery = () => {
  const query = queryString.parse(window.location.search)
  if (
    Object.values(Organizations).includes(
      query[ORGANIZATION_QUERY] as Organizations
    )
  ) {
    return query[ORGANIZATION_QUERY] as Organizations
  }
  return sessionStorage.getItem(ORGANIZATION_KEY) || undefined
}

export default getOrganizationQuery
