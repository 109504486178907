import menuItems from 'src/layouts/primary/menuItems'
import * as features from 'src/constants/features'
import routes from 'src/utils/router/routes'
import { SidebarMenuItem } from '@transwap/ui/Core/Layout'

const funcToRemoveMenuItem = (
  item: SidebarMenuItem,
  parentFeature: string,
  removeFeatureKey: string
) => {
  if (item.key === parentFeature && item.subMenuItems) {
    const items = item.subMenuItems.filter(s => s.key !== removeFeatureKey)
    return { ...item, subMenuItems: items }
  }
  return item
}

export const getBizWhaleMenuItems = () => {
  const bizWhaleMenuItems = menuItems.filter(menuItem => {
    return (
      menuItem.key === features.CLIENT ||
      menuItem.key === features.PAYMENT ||
      menuItem.key === features.DASHBOARD
    )
  })
  return bizWhaleMenuItems
    .map(b => funcToRemoveMenuItem(b, features.CLIENT, features.CLIENT_MTO))
    .map(b =>
      funcToRemoveMenuItem(b, features.PAYMENT, features.PAYMENT_TRANSFER_BULK)
    )
    .map(b => funcToRemoveMenuItem(b, features.PAYMENT, features.PAYMENT_MTO))
}

export const getBizWhaleRoutes = () => {
  const enableFeatures = [
    features.CLIENT_INDIVIDUAL_LIST,
    features.CLIENT_INDIVIDUAL_DETAIL,
    features.CLIENT_BUSINESS_LIST,
    features.CLIENT_BUSINESS_DETAIL,
    features.AUTH,
    features.DASHBOARD,
    features.PAYMENT_RETAIL,
    features.NOTFOUND,
  ]
  const bizWhaleRoutes = routes.filter(route => {
    return enableFeatures.some(e => route.id.startsWith(e))
  })
  return bizWhaleRoutes
}
