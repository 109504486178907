import * as urls from 'src/constants/urls'
import * as features from 'src/constants/features'
import { BreadcrumbsMap } from 'src/@types/common'

const breadcrumbsMap: BreadcrumbsMap = {
  // Dashboard Routes
  [features.DASHBOARD]: {
    name: 'Dashboard',
    url: urls.DASHBOARD_URL,
    heading: 'Dashboard',
  },
  // Global account
  [features.GLOBAL_ACCOUNT]: {
    name: 'Global accounts',
    heading: 'Global accounts',
  },
  [features.GLOBAL_ACCOUNT_ACCOUNT_LIST]: {
    name: 'Accounts',
    heading: 'Accounts',
    url: urls.GLOBAL_ACCOUNT_ACCOUNT_URL.LIST,
    parent: features.GLOBAL_ACCOUNT,
  },
  [features.GLOBAL_ACCOUNT_ACCOUNT_DETAIL]: {
    name: 'Details',
    heading: 'Global account details',
    url: urls.INDIVIDUALS_URL.GA_DETAILS,
    parent: features.CLIENT_INDIVIDUAL_DETAIL,
  },
  // Global account feeds
  [features.GLOBAL_ACCOUNT_FEED_LIST]: {
    name: 'Feeds',
    heading: 'Feeds',
    url: urls.GLOBAL_ACCOUNT_FEED_URL.LIST,
    parent: features.GLOBAL_ACCOUNT,
  },
  // Todo: will enable when api already
  // [features.GLOBAL_ACCOUNT_FEED_CREATE]: {
  //   name: 'New global account feed',
  //   heading: 'New global account feed',
  //   url: urls.GLOBAL_ACCOUNT_FEED_URL.ADD,
  //   parent: features.GLOBAL_ACCOUNT_FEED_LIST,
  // },
  // [features.GLOBAL_ACCOUNT_FEED_EDIT]: {
  //   name: 'Edit',
  //   heading: 'Global account edit',
  //   url: urls.GLOBAL_ACCOUNT_FEED_URL.EDIT,
  //   parent: features.GLOBAL_ACCOUNT_FEED_LIST,
  // },
  // Global account collections
  [features.GLOBAL_ACCOUNT_COLLECTION_LIST]: {
    name: 'Collections',
    heading: 'Collections',
    url: urls.GLOBAL_ACCOUNT_COLLECTION_URL.LIST,
    parent: features.GLOBAL_ACCOUNT,
  },
  // Balance Routes
  [features.BALANCE]: {
    name: 'Balances',
    heading: 'Balances',
  },
  [features.BALANCE_DEPOSIT_LIST]: {
    name: 'Deposits',
    url: urls.DEPOSIT_URL.LIST,
    heading: 'Deposits',
    parent: features.BALANCE,
  },
  [features.BALANCE_DEPOSIT_CREATE]: {
    name: 'New Deposit',
    url: urls.DEPOSIT_URL.ADD,
    heading: 'New Deposit',
    parent: features.BALANCE_DEPOSIT_LIST,
  },
  [features.BALANCE_FUNDING_LIST]: {
    name: 'Fundings',
    url: urls.FUNDING_URL.LIST,
    heading: 'Fundings',
    parent: features.BALANCE,
  },
  [features.BALANCE_FUNDING_CREATE]: {
    name: 'New Funding',
    url: urls.FUNDING_URL.ADD,
    heading: 'New Funding',
    parent: features.BALANCE_FUNDING_LIST,
  },
  [features.BALANCE_WITHDRAWAL_LIST]: {
    name: 'Withdrawals',
    url: urls.WITHDRAWAL_URL.LIST,
    heading: 'Withdrawals',
    parent: features.BALANCE,
  },
  [features.BALANCE_WITHDRAWAL_CREATE]: {
    name: 'New Withdrawal',
    url: urls.WITHDRAWAL_URL.ADD,
    heading: 'New Withdrawal',
    parent: features.BALANCE_WITHDRAWAL_LIST,
  },
  [features.FUNDFEEDS_BANK_FEEDS]: {
    name: 'Bank Feeds',
    url: urls.BANK_FEED_URL.LIST,
    heading: 'Bank Feeds',
    parent: features.FUNDFEEDS,
  },
  [features.FUNDFEEDS_BANKFEED_CREATE]: {
    name: 'New Bank Feed',
    url: urls.BANK_FEED_URL.ADD,
    heading: 'New Bank Feed',
    parent: features.FUNDFEEDS_BANK_FEEDS,
  },
  [features.FUNDFEEDS_BANKFEED_EDIT]: {
    name: 'Edit Bank Feed',
    url: urls.BANK_FEED_URL.EDIT,
    heading: 'Edit Bank Feed',
    parent: features.FUNDFEEDS_BANK_FEEDS,
  },
  [features.FUNDFEEDS_CREDITFEED_LIST]: {
    name: 'Credit Feeds',
    url: urls.CREDIT_FEED_URL.LIST,
    heading: 'Credit Feeds',
    parent: features.FUNDFEEDS,
  },
  [features.BALANCE_REFUND_LIST]: {
    name: 'Refunds',
    url: urls.REFUND_URL.LIST,
    heading: 'Refunds',
    parent: features.BALANCE,
  },
  [features.BALANCE_REFUND_CREATE]: {
    name: 'New Refund',
    url: urls.REFUND_URL.ADD,
    heading: 'New Refund',
    parent: features.BALANCE_REFUND_LIST,
  },
  [features.FUNDFEEDS_PARTNERFEEDS]: {
    name: 'Partner Feeds',
    url: urls.PARTNER_FEED_URL.LIST,
    heading: 'Partner Feeds',
    parent: features.FUNDFEEDS,
  },
  [features.FUNDFEEDS_PARTNERFEED_CREATE]: {
    name: 'New Partner Feed',
    url: urls.PARTNER_FEED_URL.CREATE,
    heading: 'New Partner Feed',
    parent: features.FUNDFEEDS_PARTNERFEEDS,
  },
  [features.FUNDFEEDS_PARTNERFEED_EDIT]: {
    name: 'Edit Partner Feed',
    url: urls.PARTNER_FEED_URL.CREATE,
    heading: 'Edit Partner Feed',
    parent: features.FUNDFEEDS_PARTNERFEEDS,
  },
  [features.BALANCE_DEBIT_CREDIT_NOTES_LIST]: {
    name: 'Debit & Credit Notes',
    url: urls.DEBIT_CREDIT_NOTES_URL.LIST,
    heading: 'Debit & Credit Notes',
    parent: features.BALANCE,
  },
  [features.BALANCE_DEBIT_CREDIT_NOTES_CREATE]: {
    name: 'New debit note',
    url: urls.DEBIT_CREDIT_NOTES_URL.ADD,
    heading: 'New debit note',
    parent: features.BALANCE_DEBIT_CREDIT_NOTES_LIST,
  },
  [features.NOSTRO]: {
    name: 'Nostro',
    heading: 'Nostro',
  },
  [features.NOSTRO_LIST]: {
    name: 'Nostro',
    url: urls.NOSTRO_URL.LIST,
    heading: 'Nostro',
  },
  [features.NOSTRO_DETAIL]: {
    name: 'Details',
    url: urls.NOSTRO_URL.DETAILS,
    heading: 'Nostro Details',
    parent: features.NOSTRO_LIST,
  },
  [features.NOSTRO_HOUSE_TRANSFERS_LIST]: {
    name: 'H2H Transfers',
    url: urls.HOUSE_TRANSFER_URL.LIST,
    heading: 'H2H Transfers',
    parent: features.NOSTRO,
  },
  // Client Routes
  [features.CLIENT]: {
    name: 'Clients',
    heading: 'Individuals',
  },
  [features.CLIENT_RETAILS]: {
    name: 'Retails',
    heading: 'Individuals',
    parent: features.CLIENT,
  },
  [features.CLIENT_INDIVIDUAL_LIST]: {
    name: 'Individuals',
    url: urls.INDIVIDUALS_URL.LIST,
    heading: 'Individuals',
    parent: features.CLIENT_RETAILS,
  },
  [features.CLIENT_INDIVIDUAL_DETAIL]: {
    name: 'Details',
    url: urls.INDIVIDUALS_URL.DETAILS,
    heading: 'Individual Client Details',
    parent: features.CLIENT_INDIVIDUAL_LIST,
  },
  [features.CLIENT_INDIVIDUAL_EDIT]: {
    name: 'Edit',
    url: urls.INDIVIDUALS_URL.EDIT,
    heading: 'Individual Client Edit',
    parent: features.CLIENT_INDIVIDUAL_LIST,
  },
  [features.CLIENT_INDIVIDUAL_BALANCE_DETAILS]: {
    name: 'Balance Details',
    url: urls.INDIVIDUALS_URL.BALANCE_DETAILS,
    heading: 'Individual Client Balances',
    parent: features.CLIENT_INDIVIDUAL_DETAIL,
  },
  [features.CLIENT_BUSINESS_LIST]: {
    name: 'Businesses',
    url: urls.BUSINESSES_URL.LIST,
    heading: 'Businesses',
    parent: features.CLIENT_RETAILS,
  },
  [features.CLIENT_BUSINESS_DETAIL]: {
    name: 'Details',
    url: urls.BUSINESSES_URL.DETAILS,
    heading: 'Business Client Details',
    parent: features.CLIENT_BUSINESS_LIST,
  },
  [features.CLIENT_BUSINESS_EDIT]: {
    name: 'Edit',
    url: urls.BUSINESSES_URL.EDIT,
    heading: 'Business Client Edit',
    parent: features.CLIENT_BUSINESS_LIST,
  },
  [features.CLIENT_BUSINESS_BALANCE_DETAILS]: {
    name: 'Balance Details',
    url: urls.BUSINESSES_URL.BALANCE_DETAILS,
    heading: 'Business Client Balance Details',
    parent: features.CLIENT_BUSINESS_DETAIL,
  },
  [features.CLIENT_MTO_LIST]: {
    name: 'MTOs',
    url: urls.MTO_URL.LIST,
    heading: 'MTOs',
    parent: features.CLIENT,
  },
  [features.CLIENT_MTO_DETAIL]: {
    name: 'Details',
    url: urls.MTO_URL.DETAILS,
    heading: 'MTO Client Details',
    parent: features.CLIENT_MTO_LIST,
  },
  [features.CLIENT_MTO_EDIT]: {
    name: 'Edit',
    url: urls.MTO_URL.EDIT,
    heading: 'MTO Client Edit',
    parent: features.CLIENT_MTO_LIST,
  },
  [features.CLIENT_MTO_BALANCE_DETAILS]: {
    name: 'Balance Details',
    url: urls.MTO_URL.BALANCE_DETAILS,
    heading: 'MTO Client Balance Details',
    parent: features.CLIENT_MTO_DETAIL,
  },
  // Payments
  [features.PAYMENT]: {
    name: 'Payments',
    heading: 'Payments',
  },
  [features.PAYMENT_RETAIL_LIST]: {
    name: 'Retails',
    url: urls.PAYMENTS_RETAILS_URL.LIST,
    parent: features.PAYMENT,
    heading: 'Retails',
  },
  [features.PAYMENT_RETAIL_NEW_TRANSACTION]: {
    name: 'New Transaction',
    url: urls.PAYMENTS_RETAILS_URL.NEW_TRANSACTION,
    parent: features.PAYMENT_RETAIL_LIST,
    heading: 'New Transaction',
  },
  [features.PAYMENT_MTO_LIST]: {
    name: 'MTOs',
    url: urls.PAYMENTS_MTOS_URL.LIST,
    parent: features.PAYMENT,
    heading: 'MTOs',
  },
  [features.PAYMENT_TRANSFER_BULK_LIST]: {
    name: 'Transfer bulks',
    url: urls.PAYMENTS_TRANSFER_BULK_URL.LIST,
    parent: features.PAYMENT,
    heading: 'Transfer bulks',
  },

  // Conversions
  [features.CONVERSION_LIST]: {
    name: 'Conversions',
    url: urls.CONVERSION_URL.LIST,
    heading: 'Conversions',
  },
  [features.CONVERSION_CREATE]: {
    name: 'Create Conversion',
    url: urls.CONVERSION_URL.CREATE,
    heading: 'Create Conversion',
    parent: features.CONVERSION_LIST,
  },

  // Cards
  [features.CARD_MANAGEMENT]: {
    name: 'Card management',
    heading: 'Card management',
  },
  [features.CARD_MANAGEMENT_CARD_LIST]: {
    name: 'Cards',
    url: urls.CARD_MANAGEMENT_CARDS_URL.LIST,
    heading: 'Cards',
    parent: features.CARD_MANAGEMENT,
  },
  [features.CARD_MANAGEMENT_CARD_DETAIL]: {
    name: 'Card details',
    url: urls.INDIVIDUALS_URL.CARD_DETAILS,
    heading: 'Card details',
    parent: features.CLIENT_INDIVIDUAL_DETAIL,
  },
  [features.CARD_MANAGEMENT_CARD_CREATE]: {
    name: 'Issue new card',
    url: urls.CARD_MANAGEMENT_CARDS_URL.ADD,
    heading: 'Issue new card',
    parent: features.CARD_MANAGEMENT_CARD_LIST,
  },
  [features.CARD_MANAGEMENT_BALANCE_LIST]: {
    name: 'Balances',
    url: urls.CARD_MANAGEMENT_BALANCES_URL.LIST,
    heading: 'Balances',
    parent: features.CARD_MANAGEMENT,
  },
  [features.CARD_MANAGEMENT_TRANSACTION_LIST]: {
    name: 'Transactions',
    url: urls.CARD_MANAGEMENT_TRANSACTIONS_URL.LIST,
    heading: 'Transactions',
    parent: features.CARD_MANAGEMENT,
  },
  // Reconciliation
  [features.RECONCILIATION]: {
    name: 'Reconciliation',
    url: urls.RECONCILIATION_URL.LIST,
    heading: 'Reconciliation',
  },
  // Compliance
  [features.COMPLIANCE_LIST]: {
    name: 'Compliance',
    url: urls.COMPLIANCE_URL.LIST,
    heading: 'Compliance',
  },
  [features.EXTERNAL_COMPLIANCE_LIST]: {
    name: 'Compliance',
    url: urls.COMPLIANCE_URL.RFI,
    heading: 'Compliance',
  },

  // Agency Routes
  [features.AGENCY]: {
    name: 'Agency',
    heading: 'Agency',
  },
  [features.AGENCY_SETTLEMENT_LIST]: {
    name: 'Settlements',
    url: urls.SETTLEMENT_URL.LIST,
    heading: 'Settlements',
    parent: features.AGENCY,
  },
  [features.AGENCY_SETTLEMENT_CREATE]: {
    name: 'New Settlement',
    url: urls.SETTLEMENT_URL.ADD,
    heading: 'New Settlement',
    parent: features.AGENCY_SETTLEMENT_LIST,
  },
  [features.REFERENCE]: {
    name: 'References',
    heading: 'References',
  },
  [features.FEE_SCHEDULE_LIST]: {
    name: 'Fee Schedules',
    url: urls.FEE_SCHEDULE_URL.LIST,
    heading: 'Fee Schedules',
    parent: features.REFERENCE,
  },
  [features.CUSTOMER_GROUP_LIST]: {
    name: 'Customer groups',
    url: urls.CUSTOMER_GROUP_URL.LIST,
    heading: 'Customer groups',
    parent: features.REFERENCE,
  },
  [features.CUSTOMER_GROUP_DETAIL]: {
    name: 'Customer group details',
    url: urls.CUSTOMER_GROUP_URL.DETAIL,
    heading: 'Customer group details',
    parent: features.CUSTOMER_GROUP_LIST,
  },
  [features.AGENCY_SETTLEMENT_DETAIL]: {
    name: 'Settlement Details',
    url: urls.SETTLEMENT_URL.DETAIL,
    heading: 'Settlement Details',
    parent: features.AGENCY_SETTLEMENT_LIST,
  },
  // Marketing Routes
  [features.MARKETING]: {
    name: 'Marketing',
    heading: 'Marketing',
  },
  [features.MARKETING_COUPONMANAGEMENT_LIST]: {
    name: 'Coupons Management',
    heading: 'Coupons Management',
    parent: features.MARKETING,
  },
  [features.MARKETING_REFERRALS_LIST]: {
    name: 'Referrals',
    heading: 'Referrals',
    parent: features.MARKETING,
  },
  // RULES & POLICIES
  [features.RULE_POLICY]: {
    name: 'Rules & Policies',
    heading: 'Rules & Policies',
  },
  [features.RULE_POLICY_COMPLIANCE_LIST]: {
    name: 'Compliance Policies',
    url: urls.RULES_POLICIES_COMPLIANCE_URL.LIST,
    heading: 'Compliance Policies',
    parent: features.RULE_POLICY,
  },
  [features.RULE_POLICY_COMPLIANCE_DETAILS]: {
    name: 'Details',
    url: urls.RULES_POLICIES_COMPLIANCE_URL.DETAILS,
    heading: 'Compliance Policies Details',
    parent: features.RULE_POLICY_COMPLIANCE_LIST,
  },
  [features.RULE_POLICY_ENABLE_AUTO_PAYOUT_LIST]: {
    name: 'API Payment',
    url: urls.RULES_POLICIES_ENABLE_AUTO_PAYOUT_URL.LIST,
    heading: 'API Payment',
    parent: features.RULE_POLICY,
  },
  [features.RULE_POLICY_FEE_CHARGES_LIST]: {
    name: 'Fees & Charges',
    url: urls.RULES_POLICIES_FEE_CHARGES_URL.LIST,
    heading: 'Fees & Charges',
    parent: features.RULE_POLICY,
  },
  [features.RULE_POLICY_FEE_CHARGES_DETAILS]: {
    name: 'Fees & Charges Details',
    url: urls.RULES_POLICIES_FEE_CHARGES_URL.DETAILS,
    heading: 'Fees & Charges Details',
    parent: features.RULE_POLICY,
  },
}

export default breadcrumbsMap
