import Svg, { SvgProps } from '@transwap/ui/Core/Icons/Svg'

const Attachment = ({
  classes,
  fontSize,
  border,
  borderProps,
  color,
}: SvgProps) => {
  return (
    <Svg
      fontSize={fontSize}
      classes={classes}
      border={border}
      borderProps={borderProps}
      color={color}
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M14.3333 14.6668L10.996 17.7255C8.78821 19.7489 5.38356 19.6927 3.24376 17.5975V17.5975C0.936741 15.3385 0.994974 11.6071 3.37136 9.42123L11.5674 1.88232C13.1283 0.446483 15.5338 0.462038 17.0761 1.91794V1.91794C18.8182 3.56243 18.7549 6.35287 16.9401 7.91673L9.49268 14.3345C8.81 14.9227 7.81601 14.9753 7.07511 14.4623V14.4623C6.02263 13.7335 5.929 12.2121 6.88418 11.3598L12.2099 6.60753"
        stroke="#16171C"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default Attachment
