import { StyledComponentProps } from '@material-ui/core'
import { ButtonColors } from '@transwap/ui/Core/Input'
import { createContext, useCallback, useState } from 'react'
import ConfirmDialog from 'src/components/ConfirmDialog'
import { SelectionRejectProps } from 'src/components/ConfirmDialog/SelectionReject'

export interface ConfirmationData extends StyledComponentProps {
  title?: string | React.ReactNode
  description?: React.ReactNode
  onConfirm?: (remark?: string, rejectionCode?: string) => Promise<unknown>
  confirmLabel: string
  isShowCloseButton?: boolean
  confirmButtonColor?: ButtonColors
  onClose?: () => void
  closeLabel?: string
  helperText?: string
  require?: boolean
  isShowRemark?: boolean
  onRenderTitle?: () => React.ReactNode
  width?: string | number
  remarkLabel?: string
  isRejectTransaction?: boolean
  selectionRejectProps?: Pick<
    SelectionRejectProps,
    'category' | 'transactionType'
  >
}
export interface ConfirmDialogContextData {
  showConfirmation: (data: ConfirmationData) => void
}

export const ConfirmDialogContext = createContext<ConfirmDialogContextData>({
  showConfirmation: () => {},
})

export interface ConfirmDialogProviderProps {
  children: React.ReactNode
}

export const ConfirmDialogProvider = ({
  children,
}: ConfirmDialogProviderProps) => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState<boolean>()
  const [modalData, setData] = useState<ConfirmationData>({
    title: '',
    description: '',
    confirmLabel: 'Confirm',
    closeLabel: 'Close',
    require: false,
    isRejectTransaction: false,
  })

  const {
    isShowCloseButton = true,
    title,
    description,
    onClose,
    onConfirm,
    confirmLabel,
    confirmButtonColor,
    closeLabel,
    helperText,
    require,
    isShowRemark,
    onRenderTitle,
    isRejectTransaction,
    selectionRejectProps,
    width,
    remarkLabel,
    classes,
  } = modalData

  const handleShowConfirmation = useCallback((data: ConfirmationData) => {
    const { onClose: onInternalClose, onConfirm: onInternalConfirm } = data

    const handleClose = () => {
      setOpen(false)
      setLoading(false)
      if (onInternalClose) {
        onInternalClose()
      }
    }
    const handleConfirm = async (remark?: string, rejectionCode?: string) => {
      if (onInternalConfirm) {
        setLoading(true)
        await onInternalConfirm(remark, rejectionCode).finally(() => {
          setLoading(false)
          setOpen(false)
        })
      }
    }

    setOpen(true)
    setData({
      ...data,
      onClose: handleClose,
      onConfirm: handleConfirm,
    })
  }, [])

  return (
    <>
      <ConfirmDialogContext.Provider
        value={{
          showConfirmation: handleShowConfirmation,
        }}
      >
        {children}
      </ConfirmDialogContext.Provider>
      {open && (
        <ConfirmDialog
          classes={classes}
          open={open}
          setOpen={setOpen}
          title={title}
          description={description}
          onClose={onClose}
          onConfirm={onConfirm}
          closeLabel={closeLabel}
          isShowRemark={isShowRemark}
          confirmLabel={confirmLabel}
          confirmButtonColor={confirmButtonColor}
          helperText={helperText}
          require={require}
          loading={loading}
          onRenderTitle={onRenderTitle}
          width={width}
          isRejectTransaction={isRejectTransaction}
          selectionRejectProps={selectionRejectProps}
          remarkLabel={remarkLabel}
          isShowCloseButton={isShowCloseButton}
        />
      )}
    </>
  )
}

export default ConfirmDialogProvider
