import {
  AccountExtensionModel,
  AccountRequestModel,
  AccountAgencyRequestModel,
  AccountAgencyModel,
  WalletAccount,
  Pageable,
  AccountDetailsModel,
  AccountBalanceSelectListQueryModel,
  AccountBalanceSelectListModel,
} from 'src/@types'

import axios from 'src/api/axios'

const baseURL = '/accounts'
const urlV2 = axios.defaults.baseURL?.replace('v1', 'v2')

const accounts = {
  getAccountById: (id: string, organizationName?: string) =>
    axios.get<AccountDetailsModel>(`${baseURL}/${id}`, {
      baseURL: urlV2,
      headers: {
        'ts-organization-name': organizationName,
      },
    }),
  getAccountSelectList: (params: AccountRequestModel) =>
    axios.get<Array<AccountExtensionModel>>(`${baseURL}/:getSelectList`, {
      params,
    }),
  getAgencySelectlist: (params?: AccountAgencyRequestModel) =>
    axios.get<Pageable<AccountAgencyModel>>(`${baseURL}/agencies`, {
      params,
    }),
  getWalletsByAccountId: (accountId: string) =>
    axios.get<Array<WalletAccount>>(`${baseURL}/wallets/${accountId}`),
  getAccountBalanceSelectList: (params: AccountBalanceSelectListQueryModel) =>
    axios.get<Array<AccountBalanceSelectListModel>>(
      `${baseURL}/:getAccountBalanceSelectList`,
      { params }
    ),
}

export default accounts
