import { Pageable } from 'src/@types'
import {
  ReconciliationTransactionModel,
  ReconciliationQueryModel,
  ReconciliationMasterAccountBalanceModel,
} from 'src/@types/models/reconciliations'
import axios from 'src/api/axios'

const baseUrl = '/Reconciliations'

const reconciliation = {
  getReconciliationSummary: (params: ReconciliationQueryModel) =>
    axios.get<Array<ReconciliationMasterAccountBalanceModel>>(
      `${baseUrl}/:getSummary`,
      {
        params,
      }
    ),
  getReconciliationTransactions: (params: ReconciliationQueryModel) =>
    axios.get<Pageable<ReconciliationTransactionModel>>(baseUrl, {
      params,
    }),
}

export default reconciliation
