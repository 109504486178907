import { useCallback, useContext } from 'react'
import { Breadcrumb } from 'src/@types'
import { BreadcrumbsContext } from 'src/contexts/BreadcrumbsContext'
import useOrganizationQuery from './useOrganizationQuery'

const useBreadcrumbs = () => {
  const { breadcrumbsMap, setBreadcrumbsMap } = useContext(BreadcrumbsContext)
  const { appendOrganizationToUrl } = useOrganizationQuery()
  const getRouteBreadcrumb = useCallback(
    (routeId: string) => {
      let breadcrumb = { ...breadcrumbsMap[routeId] }

      if (!breadcrumb) {
        return undefined
      }
      if (breadcrumb.url) {
        breadcrumb.url = appendOrganizationToUrl(breadcrumb.url)
      }
      const breadcrumbs: Breadcrumb[] = [breadcrumb]
      while (breadcrumb.parent) {
        breadcrumb = { ...breadcrumbsMap[breadcrumb.parent] }
        if (breadcrumb.url) {
          breadcrumb.url = appendOrganizationToUrl(breadcrumb.url)
        }
        breadcrumbs.unshift(breadcrumb)
      }
      return breadcrumbs
    },
    [breadcrumbsMap, appendOrganizationToUrl]
  )

  const updateBreadcrumb = useCallback(
    (breadcrumbKey?: string, breadcrumbUpdates?: Partial<Breadcrumb>) => {
      if (breadcrumbKey && breadcrumbUpdates) {
        setBreadcrumbsMap(currentBreadcrumbsMap => ({
          ...currentBreadcrumbsMap,
          [breadcrumbKey]: {
            ...currentBreadcrumbsMap[breadcrumbKey],
            ...breadcrumbUpdates,
          },
        }))
      }
    },
    [setBreadcrumbsMap]
  )

  return { getRouteBreadcrumb, updateBreadcrumb }
}

export default useBreadcrumbs
