import { GlobalSearchItemModel } from 'src/@types/models/globalSearch'
import axios from 'src/api/axios'

const baseUrl = '/GlobalSearch'

const globalSearch = {
  getSearchItems: (searchText: string) =>
    axios.get<Array<GlobalSearchItemModel>>(baseUrl, {
      params: { searchText },
    }),
}

export default globalSearch
