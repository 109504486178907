import {
  UserManager,
  WebStorageStateStore,
  UserManagerSettings,
  User,
} from 'oidc-client'
import env from 'src/env'

const hostName = env.REACT_APP_RADIOMIR_URI
const config = {
  authority: `${env.REACT_APP_RADIOMIR_IDENTITY_SERVER}/v2.0`,
  client_id: env.REACT_APP_RADIOMIR_CLIENT_ID,
  response_type: 'id_token token',
  scope: 'https://graph.microsoft.com/user.read openid profile',
  redirect_uri: `${hostName}/authentication/callback`,
  post_logout_redirect_uri: `${hostName}/authentication/signout`,
  silent_redirect_uri: `${hostName}/authentication/silent_callback`,
  automaticSilentRenew: true,
  loadUserInfo: true,
  filterProtocolClaims: true,
  revokeAccessTokenOnSignout: true,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
} as UserManagerSettings

class AuthService {
  private userManager

  private currentUser?: User

  constructor() {
    this.userManager = new UserManager(config)

    this.userManager.events.addUserLoaded(user => {
      this.currentUser = user
    })

    this.userManager.events.addUserUnloaded(() => {
      this.currentUser = undefined
    })

    this.userManager.events.addUserSignedOut(() => {
      this.currentUser = undefined
    })

    this.userManager.events.addSilentRenewError(async () => {
      await this.userManager.removeUser()
      this.signin()
    })
  }

  private getPath = () => {
    const { pathname, search = '' } = global.location
    return pathname + search
  }

  public checkAuthentication = async () => {
    const user = await this.userManager.getUser()

    return user && !user.expired
  }

  public getUserManager = () => {
    return this.userManager
  }

  public getAccessToken = async () => {
    const user = await this.userManager.getUser()
    return `${user?.token_type} ${user?.access_token}`
  }

  public getUser = () => {
    if (!this.currentUser || this.currentUser?.expired) {
      return undefined
    }
    return this.currentUser
  }

  public signin = () => {
    return this.userManager.signinRedirect({ data: this.getPath() })
  }

  public signinRedirectCallback = () => {
    return this.userManager.signinRedirectCallback()
  }

  public signout = () => {
    return this.userManager?.signoutRedirect({ data: this.getPath() })
  }

  public signoutRedirectCallback = async () => {
    await this.userManager.signoutRedirectCallback()
    window.localStorage.clear()
    window.location.replace('/')
    this.userManager.clearStaleState()
  }

  public clearStaleState = () => {
    return this.userManager.clearStaleState()
  }

  public getExpiresIn = () => {
    return this.currentUser?.expires_in
  }

  public signinSilentCallback = () => {
    return this.userManager.signinSilentCallback()
  }
}

export default new AuthService()
