import { useMutation, useQuery } from 'react-query'
import { profileEndpoints } from 'src/api/endpoints'
import profiles from 'src/api/endpoints/profiles'
import { PROFILE } from 'src/constants/queryKeys'

const useProfile = () => {
  const { data, refetch, isLoading } = useQuery(
    PROFILE,
    () => profileEndpoints.getProfiles(),
    {
      enabled: false,
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  )

  const { mutate: profileLogout } = useMutation(() => profiles.logout())

  const onRefetch = () => {
    if (!data) refetch()
  }

  return {
    onRefetch,
    data,
    isLoading,
    profileLogout,
  }
}

export default useProfile
