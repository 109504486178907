import Svg, { SvgProps } from '@transwap/ui/Core/Icons/Svg'

const Reconciliation = ({
  classes,
  fontSize,
  border,
  borderProps,
  color,
}: SvgProps) => (
  <Svg
    viewBox="0 0 24 24"
    fontSize={fontSize}
    classes={classes}
    border={border}
    borderProps={borderProps}
    color={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0002 22.6667C17.8913 22.6667 22.6668 17.8911 22.6668 12C22.6668 6.10897 17.8913 1.33334 12.0002 1.33334C6.10913 1.33334 1.3335 6.10897 1.3335 12C1.3335 17.8911 6.10913 22.6667 12.0002 22.6667Z"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 18.5637V16.9062"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 7.09347V5.43604"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.2824 9.16063C15.0034 8.1432 14.1008 7.3555 12.9521 7.14217C11.8034 6.92884 10.6054 7.32268 9.93262 8.12679C9.85057 8.22525 9.0957 9.14422 9.45672 10.1288C9.68647 10.7524 10.228 11.097 10.9829 11.5565C11.8034 12.0652 12.0331 11.9832 13.2803 12.6232C14.5439 13.2796 14.7572 13.6078 14.8557 13.7719C15.2167 14.4612 14.9706 15.1504 14.9213 15.2817C14.5767 16.1679 13.6249 16.5289 13.2803 16.6766C13.1326 16.7422 11.7542 17.2345 10.3921 16.611C9.63724 16.2663 9.24339 15.7412 9.11211 15.5771C8.89877 15.2653 8.7839 14.9863 8.71826 14.773"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
)

export default Reconciliation
