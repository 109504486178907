import {
  ComplianceDetailsModel,
  HistoryModel,
  NoteCreateModel,
  NoteModel,
  NotesRequestModel,
  Pageable,
} from 'src/@types'
import {
  ComplianceActionModel,
  ComplianceModel,
  CompliancesRequestModel,
  ComplianceSummaryModel,
} from 'src/@types/models/compliances'
import axios from 'src/api/axios'

const baseURL = '/compliance'

const compliances = {
  getCompliances: (params?: CompliancesRequestModel) =>
    axios.get<Pageable<ComplianceModel>>(baseURL, { params }),
  getComplianceSumary: () =>
    axios.get<ComplianceSummaryModel>(`${baseURL}/:getSummary`),
  getComplianceDetails: (id: string) =>
    axios.get<ComplianceDetailsModel>(`${baseURL}/${id}`),
  approveCompliance: (params: ComplianceActionModel) =>
    axios.put<ComplianceDetailsModel>(
      `${baseURL}/${params.id}:approve`,
      params
    ),
  rejectCompliance: (params: ComplianceActionModel) =>
    axios.put<ComplianceDetailsModel>(`${baseURL}/${params.id}:reject`, params),
  getComplianceHistories: (id: string) =>
    axios.get<Array<HistoryModel>>(`${baseURL}/${id}:activities`),
  createComplianceNote: (payload: NoteCreateModel) =>
    axios.post(`${baseURL}/attachment`, payload),
  getComplianceNotes: (params: NotesRequestModel) =>
    axios.get<Pageable<NoteModel>>(`${baseURL}/attachment`, {
      params,
    }),
}

export default compliances
