import Svg, { SvgProps } from '@transwap/ui/Core/Icons/Svg'

const AmendmentForSettlement = ({
  classes,
  fontSize,
  border,
  borderProps,
  color,
}: SvgProps) => {
  return (
    <span>
      <Svg
        fontSize={fontSize}
        classes={classes}
        border={border}
        borderProps={borderProps}
        color={color}
        viewBox="0 0 32 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M31.1924 8.10387L23.2881 0.198596C23.0941 0.00460385 22.8033 -0.0532892 22.5501 0.051663C22.2972 0.156615 22.1323 0.403422 22.1323 0.677314V4.48708H16.0007C15.6269 4.48708 15.3236 4.79042 15.3236 5.16419C15.3236 5.53795 15.6269 5.8413 16.0007 5.8413H22.8094C23.1832 5.8413 23.4865 5.53795 23.4865 5.16419V2.3122L29.7562 8.58258L23.4865 14.8523V12.0003C23.4865 11.6265 23.1832 11.3232 22.8094 11.3232H21.4934C21.1579 8.58766 18.8246 6.4612 16 6.4612C13.1755 6.4612 10.8421 8.58766 10.5063 11.3232H9.86709C9.86709 11.3154 9.86709 11.3073 9.86709 11.2995L9.86743 7.51376C9.86743 7.23987 9.70255 6.99307 9.44965 6.88811C9.19641 6.7835 8.90559 6.84072 8.7116 7.03505L0.807684 14.939C0.543272 15.2034 0.543272 15.632 0.807684 15.8964L8.71194 23.8023C8.84161 23.932 9.01461 24.0007 9.19066 24.0007C9.278 24.0007 9.36603 23.9838 9.44999 23.9493C9.70289 23.8443 9.86777 23.5975 9.86777 23.3236L9.86743 19.5372C9.86743 19.5294 9.86743 19.5213 9.86743 19.5135H16.0007C16.3745 19.5135 16.6778 19.2102 16.6778 18.8364C16.6778 18.4627 16.3745 18.1593 16.0007 18.1593H9.18998C8.81622 18.1593 8.51287 18.4627 8.51287 18.8364C8.51287 18.8391 8.51287 18.8418 8.51287 18.8442C8.51321 18.9332 8.51355 20.4452 8.51355 21.6884L2.24384 15.4177L8.51355 9.14831C8.51355 10.3719 8.51321 11.8564 8.51287 11.9871C8.51219 12.0237 8.51456 12.0612 8.51998 12.0985C8.56873 12.4309 8.85413 12.6774 9.18998 12.6774H10.5066C10.8421 15.4129 13.1755 17.5394 16 17.5394C18.8246 17.5394 21.1579 15.4129 21.4938 12.6774H22.1326V16.4868C22.1326 16.7607 22.2975 17.0075 22.5504 17.1125C22.6344 17.1474 22.7224 17.1639 22.8097 17.1639C22.9861 17.1639 23.1588 17.0952 23.2884 16.9656L31.1927 9.0613C31.4568 8.79689 31.4568 8.36828 31.1924 8.10387ZM16 16.1852C13.6924 16.1852 11.8151 14.3079 11.8151 12.0003C11.8151 9.69271 13.6924 7.81542 16 7.81542C18.3076 7.81542 20.1849 9.69271 20.1849 12.0003C20.1849 14.3079 18.3076 16.1852 16 16.1852Z"
          fill="black"
        />
        <path
          d="M15.8315 11.2766C15.7134 11.2478 15.5979 11.2194 15.4879 11.1892C15.277 11.131 15.152 11.0132 15.1453 10.8669C15.1429 10.8134 15.1544 10.6824 15.3132 10.6228C16.0756 10.3391 16.7162 10.87 16.7507 10.8994C17.0307 11.1439 17.4552 11.1175 17.7027 10.8392C17.9509 10.5599 17.9258 10.1316 17.6462 9.88344C17.6161 9.85669 17.181 9.48123 16.5161 9.29164V8.98863C16.5161 8.61487 16.2127 8.31152 15.839 8.31152C15.4652 8.31152 15.1619 8.61487 15.1619 8.98863V9.25711C15.0559 9.2825 14.9482 9.31331 14.8385 9.35427C14.1821 9.60006 13.7616 10.2318 13.7924 10.9269C13.8256 11.6646 14.3497 12.2797 15.128 12.4944C15.2492 12.5282 15.3775 12.5594 15.5082 12.5915C15.8952 12.6867 16.5432 12.8458 16.553 13.0401C16.5598 13.1776 16.5212 13.2378 16.4873 13.2747C16.3908 13.38 16.1755 13.4528 15.9402 13.461C15.4855 13.4755 14.8998 13.2128 14.6466 12.9684C14.3774 12.7083 13.9488 12.7161 13.6891 12.9849C13.4291 13.2541 13.4369 13.683 13.7057 13.9424C14.0693 14.2938 14.6127 14.5697 15.1622 14.7112V15.0115C15.1622 15.3853 15.4655 15.6886 15.8393 15.6886C16.2131 15.6886 16.5164 15.3853 16.5164 15.0115V14.7383C16.9047 14.6408 17.244 14.4533 17.4844 14.1919C17.7836 13.8665 17.9292 13.445 17.9055 12.9724C17.8449 11.7712 16.6332 11.4736 15.8315 11.2766Z"
          fill="black"
        />
      </Svg>
    </span>
  )
}

export default AmendmentForSettlement
