import Svg, { SvgProps } from '@transwap/ui/Core/Icons/Svg'

const MoneyCoin = ({
  classes,
  fontSize,
  border,
  borderProps,
  color,
}: SvgProps) => {
  return (
    <Svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fontSize={fontSize}
      classes={classes}
      border={border}
      borderProps={borderProps}
      color={color}
    >
      <path
        d="M12 22C17.5229 22 22 17.5229 22 12C22 6.47715 17.5229 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5229 6.47715 22 12 22Z"
        stroke="white"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 18.1535V16.5996"
        stroke="white"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 7.40028V5.84644"
        stroke="white"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0769 9.3379C14.8154 8.38406 13.9692 7.64559 12.8923 7.44559C11.8154 7.24559 10.6923 7.61483 10.0615 8.36867C9.9846 8.46098 9.27691 9.32252 9.61537 10.2456C9.83076 10.8302 10.3384 11.1533 11.0461 11.5841C11.8154 12.061 12.0308 11.9841 13.2 12.5841C14.3846 13.1994 14.5846 13.5071 14.6769 13.661C15.0154 14.3072 14.7846 14.9533 14.7384 15.0764C14.4154 15.9072 13.5231 16.2457 13.2 16.3841C13.0615 16.4457 11.7692 16.9072 10.4923 16.3226C9.7846 15.9995 9.41537 15.5072 9.2923 15.3533C9.0923 15.061 8.9846 14.7995 8.92307 14.5995"
        stroke="white"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default MoneyCoin
