/* eslint-disable import/prefer-default-export */
import env from 'src/env'
import * as features from 'src/constants/features'
import routes from 'src/utils/router/routes'
import menuItems from 'src/layouts/primary/menuItems'

const accounts = `${env.REACT_APP_PENTEST_ACCOUNTS}`
  .split(';')
  .map(acc => acc.toLowerCase())

export const isPenTestAccount = (email?: string) => {
  if (!email) {
    return false
  }
  return accounts.includes(email.toLowerCase())
}

export const getPenTestRoutes = () => {
  const enableFeatures = [
    features.BALANCE_DEPOSIT_LIST,
    features.AUTH,
    features.DASHBOARD,
    features.PAYMENT_RETAIL_LIST,
    features.NOTFOUND,
  ]
  const penTestRoutes = routes.filter(route => {
    return enableFeatures.includes(route.id)
  })
  return penTestRoutes
}

export const getPenTestMenuItems = () => {
  const parentItems = menuItems.filter(m =>
    [features.DASHBOARD, features.BALANCE, features.PAYMENT].includes(m.key)
  )
  const enableItems = [features.BALANCE_DEPOSIT, features.PAYMENT_RETAIL]
  return parentItems.map(parentItem => ({
    ...parentItem,
    subMenuItems: parentItem.subMenuItems?.filter(item =>
      enableItems.includes(item.key)
    ),
  }))
}
