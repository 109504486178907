import { CardStatus } from '@transwap/ui/App/Shared'

const renderBankFeedStatus = (value: string) => {
  let status: CardStatus = 'redeemed'
  switch (value) {
    case 'Unknown':
      status = 'draft'
      break
    case 'Processing':
      break
    case 'Bound':
      status = 'active'
      break
    case 'Ignored':
      status = 'expired'
      break
    default:
      break
  }
  return {
    label: value,
    status,
  }
}

export default renderBankFeedStatus
