import {
  WithdrawalModel,
  WithdrawalRequestModel,
  Pageable,
  WithdrawalDetailsModel,
  WithdrawalSummary,
  WithdrawalSubmitPayoutModel,
  WithdrawalCreateModel,
  HistoryModel,
  NoteCreateModel,
  NotesRequestModel,
  NoteModel,
  WithdrawalSelectListRequestModel,
  WithdrawalUpdateModel,
  TransactionComplianceDetailsModel,
} from 'src/@types'

import axios from 'src/api/axios'

const baseURL = '/withdrawals'

const withdrawals = {
  getWithdrawals: (params?: WithdrawalRequestModel) =>
    axios.get<Pageable<WithdrawalModel>>(`${baseURL}`, { params }),
  getWithdrawalSummary: () =>
    axios.get<WithdrawalSummary>(`${baseURL}/:getSummary`),
  getWithdrawalById: (id: string) =>
    axios.get<WithdrawalDetailsModel>(`${baseURL}/${id}`),
  approveWithdrawal: (params: WithdrawalUpdateModel) =>
    axios.put<WithdrawalDetailsModel>(
      `${baseURL}/${params.id}:approve`,
      params
    ),
  getComplianceDetail: (id: string) =>
    axios.get<TransactionComplianceDetailsModel>(
      `${baseURL}/${id}:getComplianceDetail`
    ),
  submitPayoutWithdrawal: (params: WithdrawalSubmitPayoutModel) =>
    axios.put<WithdrawalDetailsModel>(`${baseURL}/${params.id}:submit`, params),
  confirmPaidWithdrawal: (params: WithdrawalUpdateModel) =>
    axios.put<WithdrawalDetailsModel>(
      `${baseURL}/${params.id}:confirmPaid`,
      params
    ),
  rejectWithdrawal: (params: WithdrawalUpdateModel) =>
    axios.put(`${baseURL}/${params.id}:reject`, params),
  cancelWithdrawal: (params: WithdrawalUpdateModel) =>
    axios.put(`${baseURL}/${params.id}:cancel`, params),
  createWithdrawal: (params: WithdrawalCreateModel) =>
    axios.post<WithdrawalDetailsModel>(baseURL, params),
  getWithdrawalHistories: (id: string) =>
    axios.get<Array<HistoryModel>>(`${baseURL}/${id}:activities`),
  createNotes: (params: NoteCreateModel) =>
    axios.post(`${baseURL}/attachment`, params),
  getNotes: (params: NotesRequestModel) =>
    axios.get<Pageable<NoteModel>>(`${baseURL}/attachment`, { params }),
  sendToBlackBox: (payload: WithdrawalUpdateModel) =>
    axios.put<WithdrawalDetailsModel>(
      `${baseURL}/${payload.id}:sendToBlackBox`,
      payload
    ),
  getSelectList: (params: WithdrawalSelectListRequestModel) =>
    axios.get<Pageable<string>>(`${baseURL}/:getValues`, {
      params: {
        pageIndex: 0,
        pageSize: 200,
        ...params,
      },
    }),
}

export default withdrawals
