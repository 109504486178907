import Svg, { SvgProps } from '@transwap/ui/Core/Icons/Svg'

const Checked = ({
  classes,
  fontSize,
  border,
  borderProps,
  color,
}: SvgProps) => {
  return (
    <Svg
      fontSize={fontSize}
      classes={classes}
      border={border}
      borderProps={borderProps}
      color={color}
      viewBox="0 0 32 32"
      height="23"
      width="32"
    >
      <circle
        cx="15.9997"
        cy="15.9997"
        r="12.0003"
        transform="rotate(180 15.9997 15.9997)"
        fill="currentColor"
      />
      <path
        d="M16 21L16 16"
        stroke="white"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="16"
        cy="12.3346"
        r="1"
        transform="rotate(180 16 12.3346)"
        fill="white"
      />
    </Svg>
  )
}

export default Checked
