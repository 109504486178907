import { AccountType } from 'src/@types/models/clients'
import {
  BankModel,
  ClientBankModel,
  CountryModel,
  CurrenciesPartnersModel,
  CurrencyModel,
  LanguageModel,
  LicenseModel,
  IndustryModel,
  OccupationModel,
  ResidentialStatus,
  SourceOfWealth,
  LegalEntity,
  PartnerModel,
  AmountConfig,
  KeyValueModel,
  PartnerV2Model,
  CountryDetail,
  IbanNumberDetailsModel,
  RecipientRelationshipsModel,
  GlobalAccountCountryCurrencyPairModel,
  TransferRateRequestModel,
  TransferRateModel,
  RejectionErrorParams,
  RejectionErrorModel,
  CompanyLegalTypeModel,
  GetManagerRequestModels,
  ManagerModels,
  PairCurrencyQueryModel,
  PairCurrencyModel,
  OnboardingCurrencyModel,
  GetAccountUsageModel,
  OnboardingCountryModel,
} from 'src/@types/models/references'
import axios from 'src/api/axios'

const baseURL = '/references'
const urlV2 = axios.defaults.baseURL?.replace('v1', 'v2')

const references = {
  getLicenses: () =>
    axios.get<Array<LicenseModel>>(`${baseURL}/countries/licenses`),

  getCurrenciesPartners: () =>
    axios.get<Array<CurrenciesPartnersModel>>(`${baseURL}/currenciesPartners`),
  getBanks: () => axios.get<Array<BankModel>>(`${baseURL}/banks`),
  getCurrencies: () => axios.get<Array<CurrencyModel>>(`${baseURL}/currencies`),
  getCountries: () => axios.get<Array<CountryModel>>(`${baseURL}/countries`),
  getCountriesLicenses: () =>
    axios.get<Array<CountryModel>>(`${baseURL}/countries/licenses`),
  getCountryDetails: (id?: string) =>
    axios.get<CountryDetail>(`${baseURL}/country/${id}`),
  getBankInfoByIbanNumber: (params: { ibanNumber: string }) =>
    axios.get<IbanNumberDetailsModel>(`${baseURL}/iban-validation`, { params }),
  getLanguages: () => axios.get<Array<LanguageModel>>(`${baseURL}/languages`),
  getClientBanks: () =>
    axios.get<Array<ClientBankModel>>(`${baseURL}/clientBanks`),
  getResidentialStatuses: () =>
    axios.get<Array<ResidentialStatus>>(`${baseURL}/residential-status`),
  getIndustries: () => axios.get<Array<IndustryModel>>(`${baseURL}/industries`),
  getOccupations: () =>
    axios.get<Array<OccupationModel>>(`${baseURL}/occupations`),
  getSourceOfWealths: () =>
    axios.get<Array<SourceOfWealth>>(`${baseURL}/source-of-wealth`),
  getLegalEntities: () =>
    axios.get<Array<LegalEntity>>(`${baseURL}/legal-entities`),
  getPartners: () => axios.get<Array<PartnerModel>>(`${baseURL}/partners`),
  getV2Partners: () =>
    axios.get<Array<PartnerV2Model>>(`${baseURL}/partners`, {
      baseURL: urlV2,
    }),
  getAmountConfigs: () =>
    Promise.resolve<Array<AmountConfig>>([
      {
        currency: 'SGD',
        minimumAmount: 20,
        maximumAmount: 10000000,
      },
      {
        currency: 'USD',
        minimumAmount: 15,
        maximumAmount: 10000000,
      },
      {
        currency: 'HKD',
        minimumAmount: 120,
        maximumAmount: 50000000,
      },
      {
        currency: 'IDR',
        minimumAmount: 200000,
        maximumAmount: 20000000000,
      },
    ]),
  getTransferPurposes: () =>
    axios.get<Array<KeyValueModel>>(`${baseURL}/keyValue?dto=TransferPurpose`),
  getFundSource: () =>
    axios.get<Array<KeyValueModel>>(`${baseURL}/keyValue?dto=FundSource`),
  getRecipientRelationships: () =>
    axios.get<Array<RecipientRelationshipsModel>>(
      `${baseURL}/recipient-relationships`
    ),
  getGlobalAccountCountryCurrencyPair: () =>
    axios.get<Array<GlobalAccountCountryCurrencyPairModel>>(
      `${baseURL}/global-account-country-currency-pair`
    ),
  getTransferRate: (params: TransferRateRequestModel) =>
    axios.get<TransferRateModel>(`${baseURL}/rates`, {
      params,
    }),
  getRejectionErrorCodes: (params: RejectionErrorParams) =>
    axios.get<Array<RejectionErrorModel>>(
      `${baseURL}/transactionRejectionCodes`,
      {
        params,
      }
    ),
  getCompanyLegalTypes: () =>
    axios.get<Array<CompanyLegalTypeModel>>(`${baseURL}/companyLegalTypes`),
  getManagers: (params: GetManagerRequestModels) =>
    axios.get<Array<ManagerModels>>(`${baseURL}/accountsManager`, { params }),
  getPairCurrencies: (params: PairCurrencyQueryModel) =>
    axios.get<PairCurrencyModel>(`${baseURL}/pairCurrencies`, { params }),
  getOnboardingCurrencies: () =>
    axios.get<Array<OnboardingCurrencyModel>>(
      `${baseURL}/onboardingCurrencies`
    ),
  getOnboardingCountries: (accountType: AccountType) =>
    axios.get<Array<OnboardingCountryModel>>(`${baseURL}/onboardingCountries`, {
      params: { accountType },
    }),
  getAccountUsage: (accountType: AccountType) =>
    axios.get<GetAccountUsageModel>(`${baseURL}/accountUsage`, {
      params: { accountType },
    }),
}

export default references
