import Svg, { SvgProps } from '@transwap/ui/Core/Icons/Svg'

const TimeFilled = ({
  classes,
  fontSize,
  border,
  borderProps,
  color,
}: SvgProps) => {
  return (
    <Svg
      fontSize={fontSize}
      classes={classes}
      border={border}
      borderProps={borderProps}
      color={color}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15 15"
    >
      <circle cx="7.16699" cy="7.16602" r="6.875" fill="#E5B302" />
      <path
        d="M7.16645 3.34766L7.16645 7.1671L9.07617 9.07682"
        stroke="white"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default TimeFilled
