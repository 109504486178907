import Svg, { SvgProps } from '@transwap/ui/Core/Icons/Svg'

const CancelCircle = ({
  classes,
  fontSize,
  border,
  borderProps,
  color,
}: SvgProps) => {
  return (
    <Svg
      viewBox="0 0 32 32"
      fontSize={fontSize}
      classes={classes}
      border={border}
      borderProps={borderProps}
      color={color}
    >
      <circle cx="16" cy="16" r="12" fill="#F54848" />
      <path
        d="M12 12L20 20M12 20L20 12"
        stroke="white"
        strokeWidth="2.13333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default CancelCircle
