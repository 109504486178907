import { User, UserManager } from 'oidc-client'
import React, { createContext, useContext, useEffect, useState } from 'react'
import authService from 'src/auth/authService'
import Organizations from 'src/constants/organizations'
import { ORGANIZATION_KEY } from 'src/constants/storageKeys'
import useProfile from 'src/hooks/useProfile'

export interface AuthContextData {
  signin: () => Promise<void>
  signinRedirectCallback: () => Promise<User>
  signout: () => Promise<void>
  signoutRedirectCallback: () => void
  signinSilentCallback: () => Promise<User | undefined>
  checkAuthentication: () => Promise<boolean | null>
  getUserManager: () => UserManager
  getAccessToken: () => Promise<string>
  getUser: () => User | undefined
  getExpiresIn: () => number | undefined
  clearStaleState: () => Promise<void>
  hasAuthenticated?: boolean
  setHasAuthenticated: (authenticated: boolean) => void
  isProfileLoading: boolean
}

const AuthContext = createContext<AuthContextData>({
  signin: () => Promise.resolve(),
  signinRedirectCallback: () => Promise.resolve({} as User),
  signout: () => Promise.resolve(),
  signoutRedirectCallback: () => {},
  signinSilentCallback: () => Promise.resolve({} as User),
  checkAuthentication: () => Promise.resolve(null),
  getUserManager: () => ({} as UserManager),
  getAccessToken: () => Promise.resolve(''),
  getUser: () => undefined,
  getExpiresIn: () => undefined,
  clearStaleState: () => Promise.resolve(),
  hasAuthenticated: undefined,
  setHasAuthenticated: () => {},
  isProfileLoading: false,
})

export interface AuthProviderProps {
  children: React.ReactNode
}

const transformOrgName = (value: string) => {
  switch (value) {
    case 'BIZWHALE':
      return Organizations.Definis
    case 'MEYZER':
      return Organizations.MeyzerSwap
    case 'TRANSWAP':
      return Organizations.TranSwap
    default:
      return ''
  }
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const { onRefetch, data: profile, isLoading: isProfileLoading } = useProfile()
  const [hasAuthenticated, setHasAuthenticated] = useState<boolean>()

  useEffect(() => {
    const handleAuthentication = async () => {
      const authenticated = await authService.checkAuthentication()
      setHasAuthenticated(authenticated || false)
      if (authenticated) {
        onRefetch()
      }
    }
    handleAuthentication()
  }, [onRefetch])

  useEffect(() => {
    if (profile?.organizationName) {
      sessionStorage.setItem(
        ORGANIZATION_KEY,
        transformOrgName(profile.organizationName)
      )
    }
  }, [profile])

  return (
    <AuthContext.Provider
      value={{
        ...authService,
        hasAuthenticated,
        setHasAuthenticated,
        isProfileLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuthContext = () => useContext(AuthContext)

export default AuthContext
