import BreadcrumbsProvider from 'src/contexts/BreadcrumbsContext'
import ConfirmDialogContext from 'src/contexts/ConfirmDialogContex'
import LoadingProvider from 'src/contexts/LoadingContext'
import TransSnackbarProvider from 'src/contexts/SnackbarContext'
import MasterData from 'src/pages/components/MasterData'
import RouteRenderer from 'src/utils/router/components/RouteRenderer'

const App = () => {
  return (
    <LoadingProvider>
      <BreadcrumbsProvider>
        <TransSnackbarProvider>
          <ConfirmDialogContext>
            <MasterData>
              <RouteRenderer />
            </MasterData>
          </ConfirmDialogContext>
        </TransSnackbarProvider>
      </BreadcrumbsProvider>
    </LoadingProvider>
  )
}

export default App
