import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  progress: {
    position: 'fixed',
    top: '50%',
    left: '50%',
  },
}))

export default useStyles
