import { Grid, makeStyles, Theme } from '@material-ui/core'
import { TransEvent } from '@transwap/ui'
import { Body, Dialog, H4 } from '@transwap/ui/Core/DataDisplay'
import { Button, TextField } from '@transwap/ui/Core/Input'
import { useCallback, useMemo } from 'react'
import { RejectionErrorModel } from 'src/@types'
import { ConfirmationData } from 'src/contexts/ConfirmDialogContex'
import useTransFormik from 'src/hooks/useTransFormik'
import SelectionReject from './SelectionReject'

export interface ConfirmFormModel {
  remark: string
  reason: string
  rejectionCode: string
}

export type RemarkState = {
  value?: string
  error?: boolean
}
interface ConfirmDialogProps extends Omit<ConfirmationData, 'onConfirm'> {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  onConfirm: (remark?: string, rejectionCode?: string) => void
  loading: boolean
}

const useStyles = makeStyles<Theme, Partial<ConfirmDialogProps>>({
  paper: ({ width }) => ({
    width: width || 'unset',
  }),
  button: {
    width: 'auto',
  },
})

const ConfirmDialog = ({
  isShowCloseButton,
  open,
  setOpen,
  title,
  description,
  onClose,
  onConfirm,
  closeLabel,
  confirmLabel,
  confirmButtonColor,
  helperText,
  require,
  isShowRemark,
  loading,
  onRenderTitle,
  width,
  isRejectTransaction = false,
  selectionRejectProps,
  classes,
  remarkLabel = 'Remark',
}: ConfirmDialogProps) => {
  const internalClasses = useStyles({ width })

  const onSubmit = (val: ConfirmFormModel) => {
    if (onConfirm) {
      if (isRejectTransaction) {
        onConfirm(
          val.reason === 'Other' ? val.remark : val.reason,
          val.rejectionCode
        )
      } else {
        onConfirm(val.remark)
      }
    }
  }

  const validate = (vals: ConfirmFormModel) => {
    let err = {}
    if (!vals.remark) {
      if (
        (isRejectTransaction && vals.reason === 'Other') ||
        (!isRejectTransaction && require)
      ) {
        err = {
          ...err,
          remark: helperText,
        }
      }
    }
    if (!vals.reason && isRejectTransaction) {
      err = {
        ...err,
        reason: 'Please select a reason',
      }
    }

    return err
  }

  const {
    submitForm,
    handleChange,
    setFieldError,
    setFieldValue,
    handleBlur,
    errors,
    touched,
    values,
  } = useTransFormik<ConfirmFormModel>({
    initialValues: {
      reason: '',
      remark: '',
      rejectionCode: '',
    },
    validate,
    onSubmit,
  })

  const handleChangeReason = (e: TransEvent, option: RejectionErrorModel) => {
    const { value } = e.target || {}

    setFieldValue('rejectionCode', value)
    setFieldValue('reason', option.message, true)

    if (value !== 'Other') {
      setFieldValue('remark', '', false)
      setFieldError('remark', '')
    }
  }

  const handleCloseClick = useCallback(() => {
    if (onClose) {
      onClose()
    }
  }, [onClose])

  const handleInternalCloseClick = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const isShowRemarkField = useMemo(() => {
    if (isRejectTransaction) {
      return values.reason === 'Other'
    }
    return isShowRemark
  }, [isRejectTransaction, values.reason, isShowRemark])

  return (
    <Dialog
      title={onRenderTitle ? onRenderTitle() : <H4>{title}</H4>}
      open={open}
      onClose={handleInternalCloseClick}
      classes={{
        paper: internalClasses.paper,
        ...classes,
      }}
      actions={
        <>
          {isShowCloseButton && (
            <Button
              className={internalClasses.button}
              color="black"
              backgroundColor="default"
              onClick={handleCloseClick}
            >
              {closeLabel}
            </Button>
          )}
          <Button
            loading={loading}
            backgroundColor={confirmButtonColor}
            type="submit"
            onClick={submitForm}
          >
            {confirmLabel}
          </Button>
        </>
      }
    >
      <form>
        <Grid container spacing={2}>
          {description && (
            <Grid item xs={12}>
              <Body type="S">{description}</Body>
            </Grid>
          )}
          {isRejectTransaction && (
            <Grid item xs={12}>
              <SelectionReject
                transactionType={selectionRejectProps?.transactionType}
                category={selectionRejectProps?.category}
                onChange={handleChangeReason}
                name="reason"
                error={!!errors?.reason && touched?.reason}
                onBlur={handleBlur}
                helperText={
                  !!errors?.reason && touched?.reason ? errors.reason : ''
                }
              />
            </Grid>
          )}
          {isShowRemarkField && (
            <Grid item xs={12}>
              <TextField
                name="remark"
                label={remarkLabel}
                error={!!errors?.remark && touched?.remark}
                onBlur={handleBlur}
                helperText={
                  errors?.remark && touched?.remark ? errors?.remark : ''
                }
                onChange={handleChange}
                multiline
                fullWidth
                inputProps={{ maxLength: 250 }}
                rows={3}
              />
            </Grid>
          )}
        </Grid>
      </form>
    </Dialog>
  )
}

ConfirmDialog.defaultProps = {
  description: '',
  helperText: 'Remark is required!',
  isShowRemark: true,
} as ConfirmDialogProps

export default ConfirmDialog
