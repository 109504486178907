import Svg, { SvgProps } from '@transwap/ui/Core/Icons/Svg'

const AmendmentForFeeCharge = ({
  classes,
  fontSize,
  border,
  borderProps,
  color,
}: SvgProps) => {
  return (
    <Svg
      fontSize={fontSize}
      classes={classes}
      border={border}
      borderProps={borderProps}
      color={color}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_35549_15855)">
        <path
          d="M6.27389 19.1372L6.43153 6.43175L19.1369 6.27411L30.5858 17.723C31.3668 18.504 31.3668 19.7703 30.5858 20.5514L20.5512 30.586C19.7701 31.3671 18.5038 31.3671 17.7227 30.586L6.27389 19.1372Z"
          stroke="#16171C"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.8147 22.2983L14.8716 21.2129"
          stroke="#16171C"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.1286 14.7866L22.1855 13.7012"
          stroke="#16171C"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.9604 18.2327C22.4265 17.3885 22.3377 16.2971 21.7215 15.4249C21.1053 14.5526 20.0696 14.0467 19.1162 14.1442C18.9997 14.1564 17.9193 14.2769 17.5279 15.1519C17.2807 15.7068 17.4156 16.2778 17.6169 17.0601C17.8299 17.9164 18.0327 18.0092 18.4413 19.2236C18.8503 20.4593 18.7807 20.8102 18.7405 20.9805C18.5374 21.6621 17.9367 21.9565 17.8208 22.0111C17.03 22.3717 16.1765 22.0012 15.8566 21.8782C15.718 21.827 14.5013 21.2704 14.007 19.9934C13.7324 19.2864 13.8093 18.6913 13.828 18.5002C13.8871 18.1599 13.9898 17.904 14.0828 17.7224"
          stroke="#16171C"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_35549_15855">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  )
}

export default AmendmentForFeeCharge
