import Svg, { SvgProps } from '@transwap/ui/Core/Icons/Svg'

const BulkUpload = ({
  classes,
  fontSize,
  border,
  borderProps,
  color,
}: SvgProps) => {
  return (
    <Svg
      fontSize={fontSize}
      classes={classes}
      border={border}
      borderProps={borderProps}
      color={color}
      viewBox="0 0 32 32"
      width={32}
      height={32}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.8 14C2.8 12.2327 4.23269 10.8 6 10.8H26C27.7673 10.8 29.2 12.2327 29.2 14V20V25V26C29.2 27.7673 27.7673 29.2 26 29.2H6C4.23269 29.2 2.8 27.7673 2.8 26V14Z"
        stroke="#16171C"
        strokeWidth="1.6"
        strokeLinejoin="round"
      />
      <path
        d="M15.9988 25.9994V24.4844"
        stroke="#16171C"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9988 15.515V14"
        stroke="#16171C"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 17.4043C18.745 16.4742 17.92 15.7542 16.87 15.5592C15.82 15.3642 14.725 15.7242 14.11 16.4592C14.035 16.5492 13.345 17.3893 13.675 18.2893C13.885 18.8593 14.38 19.1743 15.07 19.5943C15.82 20.0593 16.03 19.9843 17.17 20.5693C18.325 21.1693 18.52 21.4693 18.61 21.6193C18.94 22.2494 18.715 22.8794 18.67 22.9994C18.355 23.8094 17.485 24.1394 17.17 24.2744C17.035 24.3344 15.775 24.7844 14.53 24.2144C13.84 23.8994 13.48 23.4194 13.36 23.2694C13.165 22.9844 13.06 22.7294 13 22.5344"
        stroke="#16171C"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 3L10 3"
        stroke="#16171C"
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <path
        d="M26 7L6 7"
        stroke="#16171C"
        strokeWidth="1.6"
        strokeLinecap="round"
      />
    </Svg>
  )
}

export default BulkUpload
