import { CardStatus } from '@transwap/ui/App/Shared'
import { ConversionStatus } from 'src/@types'

const renderConversionStatus = (value?: ConversionStatus) => {
  let label: string = value || ''
  let status: CardStatus = 'draft'
  switch (value) {
    case 'Completed':
      status = 'active'
      break
    case 'Pending':
    case 'Processing':
      label = 'processing'
      break
    case 'Rejected':
      status = 'expired'
      break
    default:
      break
  }
  return {
    label,
    status,
  }
}

export default renderConversionStatus
